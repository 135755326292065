import React, { useEffect, useMemo, useRef } from "react";
import Highcharts from "highcharts";
import { renderToString } from "react-dom/server";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import { InvestmentChartDataItem } from "@api/v1/hooks/performance/useInvestmentsChartDataTotal";
import { getDateFormatter, toAmericanShort, toCurrency } from "@helpers";
import { PerformanceValuationTooltip } from "@components/Chart/Tooltips/PerformanceValuationTooltip";
import { WithLoadingSpinner } from "@components/LoadingSpinner/WithLoadingSpinner";
import commonHighchartsOptions from "@components/Chart/commonHighchartsOptions";
import { NoAccountsStub } from "@components/NoAccountsStub";

const options: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  chart: {
    type: "line",
    ...commonHighchartsOptions.chart,
  },
  title: {
    text: "",
  },
  colors: ["#13CC89", "#4D6BDD"],
  tooltip: {
    split: true,
    ...commonHighchartsOptions.tooltip,
    padding: 0,
    formatter: function () {
      return PerformanceValuationTooltip({
        formatter: (value) =>
          toCurrency(value as number, "EUR", { minimumFractionDigits: 2 }),
        points: this.points,
        x: this.x,
      });
    },
  },
  xAxis: {
    type: "datetime",
    tickLength: 0,
    lineColor: "#EEF2F6",
    crosshair: {
      color: "#EEF2F6",
    },
  },
  yAxis: {
    title: {
      text: "",
    },
    tickAmount: 6,
    labels: {
      formatter: (data) => {
        return renderToString(
          <div
            style={{
              fontFamily: "Averta,Arial",
              fontSize: "11px",
              fontWeight: 400,
              color: "#737E93",
            }}
          >
            {toAmericanShort(data.value as number)}
          </div>
        );
      },
    },
  },
  legend: {
    labelFormatter: function () {
      return renderToString(
        <div
          style={{
            fontFamily: "Averta,Arial",
            fontSize: "11px",
            fontWeight: 400,
            color: "#737E93",
          }}
        >
          {this.name}
        </div>
      );
    },
    useHTML: true,
    align: "left",
    symbolHeight: 6,
    symbolWidth: 6,
    symbolRadius: 4,
    symbolPadding: 8,
    squareSymbol: false,
    itemStyle: { lineHeight: "17px" },
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
        symbol: "circle",
        states: {
          hover: {
            radius: 4,
            lineWidth: 1,
            lineWidthPlus: 0,
          },
        },
      },
    },
    line: {
      legendSymbol: "rectangle",
      lineWidth: 2,
    },
    area: {
      legendSymbol: "rectangle",
      fillColor: {
        stops: [
          // @ts-ignore
          [0, Highcharts.color("#13CC89").setOpacity(0.1).get("rgba")],
          // @ts-ignore
          [1, Highcharts.color("#13CC89").setOpacity(0).get("rgba")],
        ],
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
      },
    },
  },
};

type Props = {
  chartData: {
    totalValues: InvestmentChartDataItem[];
    investedCapitalValues: InvestmentChartDataItem[];
  };
  intervalType: IntervalType;
  isLoading?: boolean;
  isFetched?: boolean;
};

export const DashboardValuationChart = ({
  chartData,
  intervalType,
  isLoading,
  isFetched,
}: Props) => {
  const { t } = useTranslation();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const chartOptions = useMemo(
    () => ({
      ...options,
      series: [
        {
          name: t("dashboardPage.dashboard.portfolio"),
          data: chartData.totalValues,
          type: "area",
          color: "#13CC89",
        },
        {
          name: t("dashboardPage.dashboard.investedCapital"),
          data: chartData.investedCapitalValues,
          color: "#4D6BDD",
          type: "line",
        },
      ],
      xAxis: {
        ...options.xAxis,
        labels: {
          overflow: "justify",
          formatter: (data: { value: string | number }) => {
            return renderToString(
              <div
                style={{
                  fontFamily: "Averta,Arial",
                  fontSize: "11px",
                  fontWeight: 400,
                  color: "#737E93",
                }}
              >
                {getDateFormatter(intervalType, data.value)}
              </div>
            );
          },
        },
      },
    }),
    [chartData, intervalType, t]
  );

  useEffect(() => {
    chartComponentRef.current?.chart?.reflow();
  }, [chartOptions]);

  return (
    <WithLoadingSpinner isLoading={Boolean(isLoading)} mb={6}>
      {isFetched && !isLoading && !chartData.totalValues.length ? (
        <NoAccountsStub height="300px" />
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          ref={chartComponentRef}
        />
      )}
    </WithLoadingSpinner>
  );
};
