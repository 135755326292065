import {
  AccountHoldersApi,
  AccountsApi,
  ApplicationPropertiesApi,
  AssetsUnderManagementApi,
  AuthorizationsApi,
  BankConnectionsApi,
  BanksApi,
  BillingCyclesApi,
  BookingsApi,
  CashFlowAnalyticsApi,
  CashbackIncidentsApi,
  CategoriesApi,
  ClassificationsApi,
  CommentsApi,
  CryptoCurrenciesApi,
  CurrenciesApi,
  DepotSynchronizationLogsApi,
  DiscountsApi,
  DividendMasterDataRecordsApi,
  DividendsApi,
  ExchangeRatesApi,
  FinApiClientConfigurationApi,
  FinancialGoalsApi,
  FundamentalsApi,
  FundsApi,
  HistoricQuotesApi,
  IndustriesApi,
  InflationRatesApi,
  InsuranceCategoriesApi,
  InsuranceProviderSpecificContractIdPatternsApi,
  InsuranceProvidersApi,
  InsurancesApi,
  InternalTransactionsApi,
  InvestmentsApi,
  MailingApi,
  MandatorConfigurationsApi,
  MandatorInvoicesApi,
  MandatorsApi,
  MasterDataApi,
  NaturalPersonsApi,
  NetfondsCustomersApi,
  NewsFeedsApi,
  OauthApi,
  PerformanceApi,
  PortfolioOperationsApi,
  PublicAccountsApi,
  QualityAssuranceApi,
  QuotesApi,
  RegionsApi,
  RemoteDepotsApi,
  ReservationsApi,
  RetirementCalculatorApi,
  RiskYieldMetricsApi,
  RolesApi,
  SecurityCommissionsApi,
  SharingPreferencesApi,
  SignupApi,
  SocialStatisticsApi,
  SplitsApi,
  StripeApi,
  SubscriptionAnalyticsApi,
  SubscriptionFormsApi,
  SubscriptionsApi,
  SymbolsApi,
  TagsApi,
  TaxationApi,
  TimeDependentPropertiesApi,
  TppCertificatesApi,
  TppCredentialsApi,
  TppWebFormsApi,
  TransactionCounterpartPopularityApi,
  TransactionsApi,
  UserProfilesApi,
  UserPropertiesApi,
  UsersApi,
  WatchlistEntriesApi,
  WatchlistsApi,
} from "@generated/apiv1";

import { withToken } from "@api/auth/withToken";
import {
  buildAnonymousConfiguration,
  buildConfiguration,
} from "@api/v1/buildConfiguration";

export const buildAccountHoldersApiWithToken = withToken(
  async (token: string) => new AccountHoldersApi(buildConfiguration(token))
);
export const buildAnonymousAccountHoldersApi = () =>
  new AccountHoldersApi(buildAnonymousConfiguration());
export const buildAccountHoldersApi = (token?: string) =>
  new AccountHoldersApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildAccountsApiWithToken = withToken(
  async (token: string) => new AccountsApi(buildConfiguration(token))
);
export const buildAnonymousAccountsApi = () =>
  new AccountsApi(buildAnonymousConfiguration());
export const buildAccountsApi = (token?: string) =>
  new AccountsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildApplicationPropertiesApiWithToken = withToken(
  async (token: string) =>
    new ApplicationPropertiesApi(buildConfiguration(token))
);
export const buildAnonymousApplicationPropertiesApi = () =>
  new ApplicationPropertiesApi(buildAnonymousConfiguration());
export const buildApplicationPropertiesApi = (token?: string) =>
  new ApplicationPropertiesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildAssetsUnderManagementApiWithToken = withToken(
  async (token: string) =>
    new AssetsUnderManagementApi(buildConfiguration(token))
);
export const buildAnonymousAssetsUnderManagementApi = () =>
  new AssetsUnderManagementApi(buildAnonymousConfiguration());
export const buildAssetsUnderManagementApi = (token?: string) =>
  new AssetsUnderManagementApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildAuthorizationsApiWithToken = withToken(
  async (token: string) => new AuthorizationsApi(buildConfiguration(token))
);
export const buildAnonymousAuthorizationsApi = () =>
  new AuthorizationsApi(buildAnonymousConfiguration());
export const buildAuthorizationsApi = (token?: string) =>
  new AuthorizationsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildBankConnectionsApiWithToken = withToken(
  async (token: string) => new BankConnectionsApi(buildConfiguration(token))
);
export const buildAnonymousBankConnectionsApi = () =>
  new BankConnectionsApi(buildAnonymousConfiguration());
export const buildBankConnectionsApi = (token?: string) =>
  new BankConnectionsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildBanksApiWithToken = withToken(
  async (token: string) => new BanksApi(buildConfiguration(token))
);
export const buildAnonymousBanksApi = () =>
  new BanksApi(buildAnonymousConfiguration());
export const buildBanksApi = (token?: string) =>
  new BanksApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildBillingCyclesApiWithToken = withToken(
  async (token: string) => new BillingCyclesApi(buildConfiguration(token))
);
export const buildAnonymousBillingCyclesApi = () =>
  new BillingCyclesApi(buildAnonymousConfiguration());
export const buildBillingCyclesApi = (token?: string) =>
  new BillingCyclesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildBookingsApiWithToken = withToken(
  async (token: string) => new BookingsApi(buildConfiguration(token))
);
export const buildAnonymousBookingsApi = () =>
  new BookingsApi(buildAnonymousConfiguration());
export const buildBookingsApi = (token?: string) =>
  new BookingsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildCashFlowAnalyticsApiWithToken = withToken(
  async (token: string) => new CashFlowAnalyticsApi(buildConfiguration(token))
);
export const buildAnonymousCashFlowAnalyticsApi = () =>
  new CashFlowAnalyticsApi(buildAnonymousConfiguration());
export const buildCashFlowAnalyticsApi = (token?: string) =>
  new CashFlowAnalyticsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildCashbackIncidentsApiWithToken = withToken(
  async (token: string) => new CashbackIncidentsApi(buildConfiguration(token))
);
export const buildAnonymousCashbackIncidentsApi = () =>
  new CashbackIncidentsApi(buildAnonymousConfiguration());
export const buildCashbackIncidentsApi = (token?: string) =>
  new CashbackIncidentsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildCategoriesApiWithToken = withToken(
  async (token: string) => new CategoriesApi(buildConfiguration(token))
);
export const buildAnonymousCategoriesApi = () =>
  new CategoriesApi(buildAnonymousConfiguration());
export const buildCategoriesApi = (token?: string) =>
  new CategoriesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildClassificationsApiWithToken = withToken(
  async (token: string) => new ClassificationsApi(buildConfiguration(token))
);
export const buildAnonymousClassificationsApi = () =>
  new ClassificationsApi(buildAnonymousConfiguration());
export const buildClassificationsApi = (token?: string) =>
  new ClassificationsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildCommentsApiWithToken = withToken(
  async (token: string) => new CommentsApi(buildConfiguration(token))
);
export const buildAnonymousCommentsApi = () =>
  new CommentsApi(buildAnonymousConfiguration());
export const buildCommentsApi = (token?: string) =>
  new CommentsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildCryptoCurrenciesApiWithToken = withToken(
  async (token: string) => new CryptoCurrenciesApi(buildConfiguration(token))
);
export const buildAnonymousCryptoCurrenciesApi = () =>
  new CryptoCurrenciesApi(buildAnonymousConfiguration());
export const buildCryptoCurrenciesApi = (token?: string) =>
  new CryptoCurrenciesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildCurrenciesApiWithToken = withToken(
  async (token: string) => new CurrenciesApi(buildConfiguration(token))
);
export const buildAnonymousCurrenciesApi = () =>
  new CurrenciesApi(buildAnonymousConfiguration());
export const buildCurrenciesApi = (token?: string) =>
  new CurrenciesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildDepotSynchronizationLogsApiWithToken = withToken(
  async (token: string) =>
    new DepotSynchronizationLogsApi(buildConfiguration(token))
);
export const buildAnonymousDepotSynchronizationLogsApi = () =>
  new DepotSynchronizationLogsApi(buildAnonymousConfiguration());
export const buildDepotSynchronizationLogsApi = (token?: string) =>
  new DepotSynchronizationLogsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildDiscountsApiWithToken = withToken(
  async (token: string) => new DiscountsApi(buildConfiguration(token))
);
export const buildAnonymousDiscountsApi = () =>
  new DiscountsApi(buildAnonymousConfiguration());
export const buildDiscountsApi = (token?: string) =>
  new DiscountsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildDividendMasterDataRecordsApiWithToken = withToken(
  async (token: string) =>
    new DividendMasterDataRecordsApi(buildConfiguration(token))
);
export const buildAnonymousDividendMasterDataRecordsApi = () =>
  new DividendMasterDataRecordsApi(buildAnonymousConfiguration());
export const buildDividendMasterDataRecordsApi = (token?: string) =>
  new DividendMasterDataRecordsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildDividendsApiWithToken = withToken(
  async (token: string) => new DividendsApi(buildConfiguration(token))
);
export const buildAnonymousDividendsApi = () =>
  new DividendsApi(buildAnonymousConfiguration());
export const buildDividendsApi = (token?: string) =>
  new DividendsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildExchangeRatesApiWithToken = withToken(
  async (token: string) => new ExchangeRatesApi(buildConfiguration(token))
);
export const buildAnonymousExchangeRatesApi = () =>
  new ExchangeRatesApi(buildAnonymousConfiguration());
export const buildExchangeRatesApi = (token?: string) =>
  new ExchangeRatesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildFinApiClientConfigurationApiWithToken = withToken(
  async (token: string) =>
    new FinApiClientConfigurationApi(buildConfiguration(token))
);
export const buildAnonymousFinApiClientConfigurationApi = () =>
  new FinApiClientConfigurationApi(buildAnonymousConfiguration());
export const buildFinApiClientConfigurationApi = (token?: string) =>
  new FinApiClientConfigurationApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildFinancialGoalsApiWithToken = withToken(
  async (token: string) => new FinancialGoalsApi(buildConfiguration(token))
);
export const buildAnonymousFinancialGoalsApi = () =>
  new FinancialGoalsApi(buildAnonymousConfiguration());
export const buildFinancialGoalsApi = (token?: string) =>
  new FinancialGoalsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildFundamentalsApiWithToken = withToken(
  async (token: string) => new FundamentalsApi(buildConfiguration(token))
);
export const buildAnonymousFundamentalsApi = () =>
  new FundamentalsApi(buildAnonymousConfiguration());
export const buildFundamentalsApi = (token?: string) =>
  new FundamentalsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildFundsApiWithToken = withToken(
  async (token: string) => new FundsApi(buildConfiguration(token))
);
export const buildAnonymousFundsApi = () =>
  new FundsApi(buildAnonymousConfiguration());
export const buildFundsApi = (token?: string) =>
  new FundsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildHistoricQuotesApiWithToken = withToken(
  async (token: string) => new HistoricQuotesApi(buildConfiguration(token))
);
export const buildAnonymousHistoricQuotesApi = () =>
  new HistoricQuotesApi(buildAnonymousConfiguration());
export const buildHistoricQuotesApi = (token?: string) =>
  new HistoricQuotesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildIndustriesApiWithToken = withToken(
  async (token: string) => new IndustriesApi(buildConfiguration(token))
);
export const buildAnonymousIndustriesApi = () =>
  new IndustriesApi(buildAnonymousConfiguration());
export const buildIndustriesApi = (token?: string) =>
  new IndustriesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildInflationRatesApiWithToken = withToken(
  async (token: string) => new InflationRatesApi(buildConfiguration(token))
);
export const buildAnonymousInflationRatesApi = () =>
  new InflationRatesApi(buildAnonymousConfiguration());
export const buildInflationRatesApi = (token?: string) =>
  new InflationRatesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildInsuranceCategoriesApiWithToken = withToken(
  async (token: string) => new InsuranceCategoriesApi(buildConfiguration(token))
);
export const buildAnonymousInsuranceCategoriesApi = () =>
  new InsuranceCategoriesApi(buildAnonymousConfiguration());
export const buildInsuranceCategoriesApi = (token?: string) =>
  new InsuranceCategoriesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildInsuranceProviderSpecificContractIdPatternsApiWithToken =
  withToken(
    async (token: string) =>
      new InsuranceProviderSpecificContractIdPatternsApi(
        buildConfiguration(token)
      )
  );
export const buildAnonymousInsuranceProviderSpecificContractIdPatternsApi =
  () =>
    new InsuranceProviderSpecificContractIdPatternsApi(
      buildAnonymousConfiguration()
    );
export const buildInsuranceProviderSpecificContractIdPatternsApi = (
  token?: string
) =>
  new InsuranceProviderSpecificContractIdPatternsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildInsuranceProvidersApiWithToken = withToken(
  async (token: string) => new InsuranceProvidersApi(buildConfiguration(token))
);
export const buildAnonymousInsuranceProvidersApi = () =>
  new InsuranceProvidersApi(buildAnonymousConfiguration());
export const buildInsuranceProvidersApi = (token?: string) =>
  new InsuranceProvidersApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildInsurancesApiWithToken = withToken(
  async (token: string) => new InsurancesApi(buildConfiguration(token))
);
export const buildAnonymousInsurancesApi = () =>
  new InsurancesApi(buildAnonymousConfiguration());
export const buildInsurancesApi = (token?: string) =>
  new InsurancesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildInternalTransactionsApiWithToken = withToken(
  async (token: string) =>
    new InternalTransactionsApi(buildConfiguration(token))
);
export const buildAnonymousInternalTransactionsApi = () =>
  new InternalTransactionsApi(buildAnonymousConfiguration());
export const buildInternalTransactionsApi = (token?: string) =>
  new InternalTransactionsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildInvestmentsApiWithToken = withToken(
  async (token: string) => new InvestmentsApi(buildConfiguration(token))
);
export const buildAnonymousInvestmentsApi = () =>
  new InvestmentsApi(buildAnonymousConfiguration());
export const buildInvestmentsApi = (token?: string) =>
  new InvestmentsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildMailingApiWithToken = withToken(
  async (token: string) => new MailingApi(buildConfiguration(token))
);
export const buildAnonymousMailingApi = () =>
  new MailingApi(buildAnonymousConfiguration());
export const buildMailingApi = (token?: string) =>
  new MailingApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildMandatorConfigurationsApiWithToken = withToken(
  async (token: string) =>
    new MandatorConfigurationsApi(buildConfiguration(token))
);
export const buildAnonymousMandatorConfigurationsApi = () =>
  new MandatorConfigurationsApi(buildAnonymousConfiguration());
export const buildMandatorConfigurationsApi = (token?: string) =>
  new MandatorConfigurationsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildMandatorInvoicesApiWithToken = withToken(
  async (token: string) => new MandatorInvoicesApi(buildConfiguration(token))
);
export const buildAnonymousMandatorInvoicesApi = () =>
  new MandatorInvoicesApi(buildAnonymousConfiguration());
export const buildMandatorInvoicesApi = (token?: string) =>
  new MandatorInvoicesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildMandatorsApiWithToken = withToken(
  async (token: string) => new MandatorsApi(buildConfiguration(token))
);
export const buildAnonymousMandatorsApi = () =>
  new MandatorsApi(buildAnonymousConfiguration());
export const buildMandatorsApi = (token?: string) =>
  new MandatorsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildMasterDataApiWithToken = withToken(
  async (token: string) => new MasterDataApi(buildConfiguration(token))
);
export const buildAnonymousMasterDataApi = () =>
  new MasterDataApi(buildAnonymousConfiguration());
export const buildMasterDataApi = (token?: string) =>
  new MasterDataApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildNaturalPersonsApiWithToken = withToken(
  async (token: string) => new NaturalPersonsApi(buildConfiguration(token))
);
export const buildAnonymousNaturalPersonsApi = () =>
  new NaturalPersonsApi(buildAnonymousConfiguration());
export const buildNaturalPersonsApi = (token?: string) =>
  new NaturalPersonsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildNetfondsCustomersApiWithToken = withToken(
  async (token: string) => new NetfondsCustomersApi(buildConfiguration(token))
);
export const buildAnonymousNetfondsCustomersApi = () =>
  new NetfondsCustomersApi(buildAnonymousConfiguration());
export const buildNetfondsCustomersApi = (token?: string) =>
  new NetfondsCustomersApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildNewsFeedsApiWithToken = withToken(
  async (token: string) => new NewsFeedsApi(buildConfiguration(token))
);
export const buildAnonymousNewsFeedsApi = () =>
  new NewsFeedsApi(buildAnonymousConfiguration());
export const buildNewsFeedsApi = (token?: string) =>
  new NewsFeedsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildOauthApiWithToken = withToken(
  async (token: string) => new OauthApi(buildConfiguration(token))
);
export const buildAnonymousOauthApi = () =>
  new OauthApi(buildAnonymousConfiguration());
export const buildOauthApi = (token?: string) =>
  new OauthApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildPerformanceApiWithToken = withToken(
  async (token: string) => new PerformanceApi(buildConfiguration(token))
);
export const buildAnonymousPerformanceApi = () =>
  new PerformanceApi(buildAnonymousConfiguration());
export const buildPerformanceApi = (token?: string) =>
  new PerformanceApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildPortfolioOperationsApiWithToken = withToken(
  async (token: string) => new PortfolioOperationsApi(buildConfiguration(token))
);
export const buildAnonymousPortfolioOperationsApi = () =>
  new PortfolioOperationsApi(buildAnonymousConfiguration());
export const buildPortfolioOperationsApi = (token?: string) =>
  new PortfolioOperationsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildPublicAccountsApiWithToken = withToken(
  async (token: string) => new PublicAccountsApi(buildConfiguration(token))
);
export const buildAnonymousPublicAccountsApi = () =>
  new PublicAccountsApi(buildAnonymousConfiguration());
export const buildPublicAccountsApi = (token?: string) =>
  new PublicAccountsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildQualityAssuranceApiWithToken = withToken(
  async (token: string) => new QualityAssuranceApi(buildConfiguration(token))
);
export const buildAnonymousQualityAssuranceApi = () =>
  new QualityAssuranceApi(buildAnonymousConfiguration());
export const buildQualityAssuranceApi = (token?: string) =>
  new QualityAssuranceApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildQuotesApiWithToken = withToken(
  async (token: string) => new QuotesApi(buildConfiguration(token))
);
export const buildAnonymousQuotesApi = () =>
  new QuotesApi(buildAnonymousConfiguration());
export const buildQuotesApi = (token?: string) =>
  new QuotesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildRegionsApiWithToken = withToken(
  async (token: string) => new RegionsApi(buildConfiguration(token))
);
export const buildAnonymousRegionsApi = () =>
  new RegionsApi(buildAnonymousConfiguration());
export const buildRegionsApi = (token?: string) =>
  new RegionsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildRemoteDepotsApiWithToken = withToken(
  async (token: string) => new RemoteDepotsApi(buildConfiguration(token))
);
export const buildAnonymousRemoteDepotsApi = () =>
  new RemoteDepotsApi(buildAnonymousConfiguration());
export const buildRemoteDepotsApi = (token?: string) =>
  new RemoteDepotsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildReservationsApiWithToken = withToken(
  async (token: string) => new ReservationsApi(buildConfiguration(token))
);
export const buildAnonymousReservationsApi = () =>
  new ReservationsApi(buildAnonymousConfiguration());
export const buildReservationsApi = (token?: string) =>
  new ReservationsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildRetirementCalculatorApiWithToken = withToken(
  async (token: string) =>
    new RetirementCalculatorApi(buildConfiguration(token))
);
export const buildAnonymousRetirementCalculatorApi = () =>
  new RetirementCalculatorApi(buildAnonymousConfiguration());
export const buildRetirementCalculatorApi = (token?: string) =>
  new RetirementCalculatorApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildRiskYieldMetricsApiWithToken = withToken(
  async (token: string) => new RiskYieldMetricsApi(buildConfiguration(token))
);
export const buildAnonymousRiskYieldMetricsApi = () =>
  new RiskYieldMetricsApi(buildAnonymousConfiguration());
export const buildRiskYieldMetricsApi = (token?: string) =>
  new RiskYieldMetricsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildRolesApiWithToken = withToken(
  async (token: string) => new RolesApi(buildConfiguration(token))
);
export const buildAnonymousRolesApi = () =>
  new RolesApi(buildAnonymousConfiguration());
export const buildRolesApi = (token?: string) =>
  new RolesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildSecurityCommissionsApiWithToken = withToken(
  async (token: string) => new SecurityCommissionsApi(buildConfiguration(token))
);
export const buildAnonymousSecurityCommissionsApi = () =>
  new SecurityCommissionsApi(buildAnonymousConfiguration());
export const buildSecurityCommissionsApi = (token?: string) =>
  new SecurityCommissionsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildSharingPreferencesApiWithToken = withToken(
  async (token: string) => new SharingPreferencesApi(buildConfiguration(token))
);
export const buildAnonymousSharingPreferencesApi = () =>
  new SharingPreferencesApi(buildAnonymousConfiguration());
export const buildSharingPreferencesApi = (token?: string) =>
  new SharingPreferencesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildSignupApiWithToken = withToken(
  async (token: string) => new SignupApi(buildConfiguration(token))
);
export const buildAnonymousSignupApi = () =>
  new SignupApi(buildAnonymousConfiguration());
export const buildSignupApi = (token?: string) =>
  new SignupApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildSocialStatisticsApiWithToken = withToken(
  async (token: string) => new SocialStatisticsApi(buildConfiguration(token))
);
export const buildAnonymousSocialStatisticsApi = () =>
  new SocialStatisticsApi(buildAnonymousConfiguration());
export const buildSocialStatisticsApi = (token?: string) =>
  new SocialStatisticsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildSplitsApiWithToken = withToken(
  async (token: string) => new SplitsApi(buildConfiguration(token))
);
export const buildAnonymousSplitsApi = () =>
  new SplitsApi(buildAnonymousConfiguration());
export const buildSplitsApi = (token?: string) =>
  new SplitsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildStripeApiWithToken = withToken(
  async (token: string) => new StripeApi(buildConfiguration(token))
);
export const buildAnonymousStripeApi = () =>
  new StripeApi(buildAnonymousConfiguration());
export const buildStripeApi = (token?: string) =>
  new StripeApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildSubscriptionAnalyticsApiWithToken = withToken(
  async (token: string) =>
    new SubscriptionAnalyticsApi(buildConfiguration(token))
);
export const buildAnonymousSubscriptionAnalyticsApi = () =>
  new SubscriptionAnalyticsApi(buildAnonymousConfiguration());
export const buildSubscriptionAnalyticsApi = (token?: string) =>
  new SubscriptionAnalyticsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildSubscriptionFormsApiWithToken = withToken(
  async (token: string) => new SubscriptionFormsApi(buildConfiguration(token))
);
export const buildAnonymousSubscriptionFormsApi = () =>
  new SubscriptionFormsApi(buildAnonymousConfiguration());
export const buildSubscriptionFormsApi = (token?: string) =>
  new SubscriptionFormsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildSubscriptionsApiWithToken = withToken(
  async (token: string) => new SubscriptionsApi(buildConfiguration(token))
);
export const buildAnonymousSubscriptionsApi = () =>
  new SubscriptionsApi(buildAnonymousConfiguration());
export const buildSubscriptionsApi = (token?: string) =>
  new SubscriptionsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildSymbolsApiWithToken = withToken(
  async (token: string) => new SymbolsApi(buildConfiguration(token))
);
export const buildAnonymousSymbolsApi = () =>
  new SymbolsApi(buildAnonymousConfiguration());
export const buildSymbolsApi = (token?: string) =>
  new SymbolsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildTagsApiWithToken = withToken(
  async (token: string) => new TagsApi(buildConfiguration(token))
);
export const buildAnonymousTagsApi = () =>
  new TagsApi(buildAnonymousConfiguration());
export const buildTagsApi = (token?: string) =>
  new TagsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildTaxationApiWithToken = withToken(
  async (token: string) => new TaxationApi(buildConfiguration(token))
);
export const buildAnonymousTaxationApi = () =>
  new TaxationApi(buildAnonymousConfiguration());
export const buildTaxationApi = (token?: string) =>
  new TaxationApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildTimeDependentPropertiesApiWithToken = withToken(
  async (token: string) =>
    new TimeDependentPropertiesApi(buildConfiguration(token))
);
export const buildAnonymousTimeDependentPropertiesApi = () =>
  new TimeDependentPropertiesApi(buildAnonymousConfiguration());
export const buildTimeDependentPropertiesApi = (token?: string) =>
  new TimeDependentPropertiesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildTppCertificatesApiWithToken = withToken(
  async (token: string) => new TppCertificatesApi(buildConfiguration(token))
);
export const buildAnonymousTppCertificatesApi = () =>
  new TppCertificatesApi(buildAnonymousConfiguration());
export const buildTppCertificatesApi = (token?: string) =>
  new TppCertificatesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildTppCredentialsApiWithToken = withToken(
  async (token: string) => new TppCredentialsApi(buildConfiguration(token))
);
export const buildAnonymousTppCredentialsApi = () =>
  new TppCredentialsApi(buildAnonymousConfiguration());
export const buildTppCredentialsApi = (token?: string) =>
  new TppCredentialsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildTppWebFormsApiWithToken = withToken(
  async (token: string) => new TppWebFormsApi(buildConfiguration(token))
);
export const buildAnonymousTppWebFormsApi = () =>
  new TppWebFormsApi(buildAnonymousConfiguration());
export const buildTppWebFormsApi = (token?: string) =>
  new TppWebFormsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildTransactionCounterpartPopularityApiWithToken = withToken(
  async (token: string) =>
    new TransactionCounterpartPopularityApi(buildConfiguration(token))
);
export const buildAnonymousTransactionCounterpartPopularityApi = () =>
  new TransactionCounterpartPopularityApi(buildAnonymousConfiguration());
export const buildTransactionCounterpartPopularityApi = (token?: string) =>
  new TransactionCounterpartPopularityApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildTransactionsApiWithToken = withToken(
  async (token: string) => new TransactionsApi(buildConfiguration(token))
);
export const buildAnonymousTransactionsApi = () =>
  new TransactionsApi(buildAnonymousConfiguration());
export const buildTransactionsApi = (token?: string) =>
  new TransactionsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildUserProfilesApiWithToken = withToken(
  async (token: string) => new UserProfilesApi(buildConfiguration(token))
);
export const buildAnonymousUserProfilesApi = () =>
  new UserProfilesApi(buildAnonymousConfiguration());
export const buildUserProfilesApi = (token?: string) =>
  new UserProfilesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildUserPropertiesApiWithToken = withToken(
  async (token: string) => new UserPropertiesApi(buildConfiguration(token))
);
export const buildAnonymousUserPropertiesApi = () =>
  new UserPropertiesApi(buildAnonymousConfiguration());
export const buildUserPropertiesApi = (token?: string) =>
  new UserPropertiesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildUsersApiWithToken = withToken(
  async (token: string) => new UsersApi(buildConfiguration(token))
);
export const buildAnonymousUsersApi = () =>
  new UsersApi(buildAnonymousConfiguration());
export const buildUsersApi = (token?: string) =>
  new UsersApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildWatchlistEntriesApiWithToken = withToken(
  async (token: string) => new WatchlistEntriesApi(buildConfiguration(token))
);
export const buildAnonymousWatchlistEntriesApi = () =>
  new WatchlistEntriesApi(buildAnonymousConfiguration());
export const buildWatchlistEntriesApi = (token?: string) =>
  new WatchlistEntriesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildWatchlistsApiWithToken = withToken(
  async (token: string) => new WatchlistsApi(buildConfiguration(token))
);
export const buildAnonymousWatchlistsApi = () =>
  new WatchlistsApi(buildAnonymousConfiguration());
export const buildWatchlistsApi = (token?: string) =>
  new WatchlistsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );
