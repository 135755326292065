import React from "react";
import { toPercent } from "@helpers";
import { ReactComponent as UpIcon } from "@icons/positive.svg";
import { ReactComponent as DownIcon } from "@icons/negative.svg";

type Props = {
  value: number;
};

export const GainLossChartPercents = (props: Props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "2px 8px 2px 8px",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "22px",
        letterSpacing: "0.01em",
        borderRadius: "6px",
        gap: "4px",
        backgroundColor: props.value >= 0 ? "#E7FAF3" : "#FFF1ED",
        color: props.value >= 0 ? "#13CC89" : "#e56043",
      }}
    >
      <span
        style={{
          fontSize: "12px",
          lineHeight: "18px",
        }}
      >
        {toPercent(props.value).replace(/\s/, "")}
      </span>
      {props.value < 0 ? (
        <DownIcon width="10px" height="8px" />
      ) : (
        <UpIcon width="10px" height="8px" />
      )}
    </div>
  );
};
