import React from "react";
import { useTranslation } from "react-i18next";
import { toCurrency, BUY } from "@helpers";
import { DividendChartData } from "@features/dividendPlanner/OverViewTab/DividendsChart/useDividendsChartData";
import assetLogo from "@components/Logo/images/fallback-asset-logo-finanzfluss.svg";
import enlarge from "@icons/open_in_full.svg";
import finanzflussLogo from "@components/Logo/images/finanzfluss-compact-logo.svg";

const textProps = {
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: 600,
  fontFamily: "Averta",
};

type Props = {
  point: {
    year: number;
    y: number;
    index: number;
    dividends: DividendChartData["dividends"];
  };
  isUserPro: boolean;
  images: Record<string, string | null | undefined>;
};

export const DividendsTooltip = ({ point, images, isUserPro }: Props) => {
  const { t } = useTranslation();
  const nonPredictedDividends = point.dividends.filter(
    (dividend) => !dividend.isPrediction
  );

  if (!nonPredictedDividends.length && !isUserPro) {
    return (
      <a
        href={BUY}
        target="_blank"
        rel="noreferrer"
        style={{
          textDecoration: "none",
          color: "white",
        }}
      >
        <div
          style={{
            background:
              "linear-gradient(102.88deg, #283874 22.38%, #4D6BDD 73.25%)",
            padding: "16px",
            borderRadius: "8px",
            color: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <div style={{ width: "13.5px", height: "13.5px" }}>
              <img
                src={finanzflussLogo}
                style={{ objectFit: "contain" }}
                alt="Finanzfluss logo"
                width="100%"
                height="100%"
              />
            </div>
            <div
              style={{
                fontFamily: "Averta,Arial",
                color: "white",
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              PLUS
            </div>
          </div>
          <div
            style={{
              display: "inline-block",
              fontFamily: "Averta,Arial",
              color: "white",
              lineHeight: "22px",
              fontSize: "14px",
              fontWeight: "400",
              width: "200px",
              whiteSpace: "pre-wrap",
            }}
          >
            {t("plus.text")}
          </div>
        </div>
      </a>
    );
  }

  return (
    <div
      style={{
        fontFamily: "Averta,Arial",
        boxShadow: "0px 8px 16px -2px #1B212C1F",
        padding: "16px",
        borderRadius: "8px",
        backgroundColor: "white",
      }}
    >
      <div style={{ maxHeight: "160px", width: "220px" }}>
        {(isUserPro ? point.dividends : nonPredictedDividends)
          .slice(0, 3)
          .map((dividend) => (
            <div
              style={{
                display: "flex",
                gap: "36px",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
              key={`${dividend.investmentReference?.id}`}
            >
              <div
                style={{
                  display: "flex",
                  gap: "4px",
                  marginBottom: "2px",
                  alignItems: "center",
                  width: "100%",
                  minWidth: 0,
                }}
              >
                <div
                  style={{
                    borderRadius: "3px",
                    border: "1px solid #E8EAED",
                    flexShrink: 0,
                    width: 20,
                    height: 20,
                  }}
                >
                  <img
                    src={
                      images[dividend.investmentReference?.isin!] || assetLogo
                    }
                    style={{ objectFit: "contain" }}
                    alt={dividend.investmentReference?.standardisedName}
                    width="100%"
                    height="100%"
                  />
                </div>

                <div
                  style={{
                    ...textProps,
                    width: "100%",
                    display: "inline-block",
                    lineHeight: "18px",
                    fontWeight: 400,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {dividend.investmentReference?.standardisedName}
                </div>
              </div>
              <div
                style={{
                  ...textProps,
                  fontWeight: 600,
                  filter:
                    dividend.isPrediction && !isUserPro ? "blur(6px)" : "none",
                }}
              >
                {toCurrency(dividend.grossAmount, "EUR")}
              </div>
            </div>
          ))}
        <button
          className="see-dividends-button"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "transparent",
            color: "#4D6BDD",
            gap: "4px",
            width: "100%",
            height: "32px",
            fontFamily: "Averta,Arial",
            fontSize: "16px",
            lineHeight: "24px",
            padding: "4px",
            borderRadius: "6px",
            border: "none",
            outline: "none",
            fontWeight: 500,
            cursor: "pointer",
          }}
        >
          <img src={enlarge} width={16} height={16} alt="Enlarge" />
          <span>
            {t("dividends.overviewPage.mainSection.chartTooltip.showAll")}
          </span>
        </button>
      </div>
    </div>
  );
};
