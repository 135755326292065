import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { alpha, Box, useMediaQuery, useTheme } from "@mui/material";
import { renderToString } from "react-dom/server";
import patternFillInit from "highcharts/modules/pattern-fill";
import { format } from "date-fns";
import { toAmericanShort } from "@helpers";
import { WithLoadingSpinner } from "@components/LoadingSpinner/WithLoadingSpinner";
import commonHighchartsOptions from "@components/Chart/commonHighchartsOptions";
import { useDividendsChartData } from "@features/dividendPlanner/OverViewTab/DividendsChart/useDividendsChartData";
import { NoDataStub } from "@components";
import { useDividendsContext } from "@features/dividendPlanner/DividendsProvider";
import { DividendsTooltip } from "./DividendsTooltip";

patternFillInit(Highcharts);

// @ts-ignore
const options: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  chart: {
    type: "column",
    height: 282,
    spacing: [40, 24, 24, 24],
    borderRadius: 16,
    scrollablePlotArea: {
      minWidth: 1000,
      opacity: 1,
    },
  },

  title: {
    text: "",
  },
  colors: [alpha("#4D6BDD", 0.3)],
  tooltip: {
    ...commonHighchartsOptions.tooltip,
    hideDelay: 800,
    style: {
      pointerEvents: "auto",
    },
  },
  xAxis: {
    type: "category",
    lineColor: "#EEF2F6",
    labels: {
      style: {
        color: "#737E93",
        fontFamily: "Averta,Arial",
        fontWeight: "400",
        fontSize: "10px",
      },
      useHTML: true,
      formatter: (data) => {
        return renderToString(
          <div
            style={{
              textAlign: "center",
            }}
          >
            <div
              style={{
                fontFamily: "Averta,Arial",
                fontSize: "14px",
                fontWeight: "600",
                color: "#15284B",
              }}
            >
              {Array.isArray(data.value)
                ? format(new Date(2021, data.value[0]), "MMM").replace(".", "")
                : format(new Date(data.value as number, 0), "yyyy")}
            </div>

            {Array.isArray(data.value) && (
              <div>
                <div
                  style={{
                    fontFamily: "Averta,Arial",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "500",
                    color: "#8A93A5",
                  }}
                >
                  '{format(new Date(data.value[1], 0), "yy")}
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    title: {
      text: "",
    },
  },
  yAxis: {
    margin: 0,
    tickAmount: 5,
    title: {
      text: "",
    },
    gridLineColor: "#EEF2F6",
    labels: {
      useHTML: true,
      formatter: (data) => {
        return renderToString(
          <div
            style={{
              fontFamily: "Averta,Arial",
              fontSize: "14px",
              fontWeight: "500",
              color: "#15284B",
            }}
          >
            {toAmericanShort(data.value as number, 0, 2)}€
          </div>
        );
      },
    },
  },
  legend: {
    enabled: false,
    width: "80%",
    verticalAlign: "top",
    align: "left",
    itemStyle: {
      fontSize: "10px",
      fontFamily: "var(--ff-font-family-base)",
      fontWeight: "600",
    },
  },
  exporting: {
    enabled: false,
  },
  plotOptions: {
    column: {
      dataLabels: {
        enabled: false,
        style: {
          fontFamily: "var(--ff-font-family-base)",
          fontWeight: "400",
          textOutline: "0",
          fontSize: "16px",
        },
      },
      stacking: "normal",
      stickyTracking: true,
      pointStart: 1,
      pointWidth: 13,
      minPointLength: 3,
    },
  },
  navigation: {
    buttonOptions: {
      y: 12,
    },
  },
};

type Props = {
  onTooltipClick: (date: Date) => void;
  selectedYear?: number;
};

export const DividendsChart = React.memo(
  ({ onTooltipClick, selectedYear }: Props) => {
    const theme = useTheme();
    const {
      isLoading,
      images,
      isUserPro,
      isUserDataFetched,
      areImagesFetched,
    } = useDividendsContext();

    const isLess800 = useMediaQuery(theme.breakpoints.down(800));
    const chartData = useDividendsChartData({
      currentYear: selectedYear,
    });

    return (
      <WithLoadingSpinner
        isLoading={isLoading || !isUserDataFetched || !areImagesFetched}
        mb={9}
        sx={{
          overflowX: "auto",
          overflowY: "hidden",
          width: isLess800 ? "calc(100% + 48px)" : "100%",
          flexShrink: 0,
          borderRadius: isLess800 ? 0 : `${theme.shape.borderRadius * 2.5}px`,
          border: isLess800
            ? "none"
            : `1px solid ${theme.palette.border.secondary}`,
          margin: isLess800 ? theme.spacing(0, -6) : 0,
          "& .highcharts-scrolling": {
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          },
          "& .highcharts-scrolling::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            "& .see-dividends-button": {
              "&:hover": {
                background: "rgba(77, 107, 221, 0.04) !important",
                transition: "background 0.3s",
              },
            },
          }}
        >
          {!isLoading && areImagesFetched && !chartData.length ? (
            <NoDataStub height="282px" />
          ) : (
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                ...options,
                chart: {
                  ...options.chart,
                  events: {
                    render: function () {
                      const chart = this as unknown as Highcharts.Chart;
                      const renderer = chart.renderer;
                      if (isUserPro || !isUserDataFetched) {
                        return;
                      }

                      renderer.definition({
                        tagName: "filter",
                        // @ts-ignore
                        id: "blur",
                        children: [
                          {
                            tagName: "feGaussianBlur",
                            attributes: {
                              stdDeviation: 2,
                            },
                          },
                        ],
                      });

                      const column = chart.series[0].data.filter(
                        (el: any) => el.isPredicted
                      );
                      column.forEach((el: any) => {
                        el.graphic?.element.setAttribute(
                          "filter",
                          "url(#blur)"
                        );
                      });
                    },
                  },
                },
                tooltip: {
                  ...options.tooltip,
                  formatter: function () {
                    // @ts-ignore
                    if (!this.point.dividends?.length) return null;

                    // this is done so that elements would be rendered first. renderToString() does not register handlers
                    setTimeout(() => {
                      document
                        .querySelector(".see-dividends-button")
                        ?.addEventListener("click", () => {
                          onTooltipClick(
                            // @ts-ignore
                            new Date(this.point.year, this.point.month || 0)
                          );
                        });
                    }, 0);

                    return renderToString(
                      <DividendsTooltip
                        // @ts-ignore
                        point={this.point}
                        images={areImagesFetched ? images : {}}
                        isUserPro={isUserPro}
                      />
                    );
                  },
                },
                series: [
                  {
                    name: "Dividende",
                    data: chartData,
                  },
                ],
              }}
            />
          )}
        </Box>
      </WithLoadingSpinner>
    );
  }
);
