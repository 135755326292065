import React, { ReactNode } from "react";
import {
  Box,
  BoxProps,
  Theme,
  Typography,
  TypographyProps,
  useMediaQuery,
} from "@mui/material";
import { ReactComponent as InfoIcon } from "@icons/info.svg";
import { Tooltip, TooltipProps } from "@components";

interface Props extends TypographyProps {
  children: ReactNode;
  tooltipTitle?: string;
  tooltipText?: ReactNode;
  tooltipSmall?: boolean;
  openOnClick?: boolean;
  iconColor?: string;
  hideIcon?: boolean;
  icon?: ReactNode;
  iconSize?: number;
  iconProps?: BoxProps;
  component?: React.ElementType;
  tooltipProps?: Omit<TooltipProps, "children" | "text">;
}

export const TypographyWithInfoIcon = ({
  children,
  tooltipTitle,
  tooltipText,
  tooltipProps,
  tooltipSmall,
  openOnClick,
  hideIcon,
  icon,
  iconColor,
  iconSize = 20,
  iconProps,
  ...rest
}: Props) => {
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const Icon = icon ? icon : <InfoIcon width={iconSize} height={iconSize} />;

  if (!tooltipTitle && !tooltipText) {
    return (
      <Typography component="span" {...rest}>
        {children}
      </Typography>
    );
  }

  return (
    <Box className="typography-info">
      {!hideIcon && (
        <Typography component="span" {...rest}>
          {children}
        </Typography>
      )}
      <Tooltip
        title={tooltipTitle}
        text={tooltipText}
        isSmall={tooltipSmall}
        placement="top"
        openOnClick={isLessSm || openOnClick}
        {...tooltipProps}
      >
        {hideIcon ? (
          <Typography component="span" {...rest}>
            {children}
          </Typography>
        ) : (
          <Box
            className="info-icon"
            display="inline-block"
            color={iconColor || "#A1A9B7"}
            sx={{
              verticalAlign: "middle",
              lineHeight: "17px",
              ...iconProps,
            }}
            ml={1.5}
          >
            {Icon}
          </Box>
        )}
      </Tooltip>
    </Box>
  );
};
