/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Properties to import a new or update an existing bank connection via the TPP web form flow.
 * @export
 * @interface CreateTppWebFormV2Params
 */
export interface CreateTppWebFormV2Params {
    /**
     * Slug of the bank in case we want to import a new bank connection; optional if bankConnectionId                        is given.
     * @type {string}
     * @memberof CreateTppWebFormV2Params
     */
    'bankSlug'?: string;
    /**
     * Who provides the multibanking API?
     * @type {string}
     * @memberof CreateTppWebFormV2Params
     */
    'multibankingApiProvider': CreateTppWebFormV2ParamsMultibankingApiProviderEnum;
    /**
     * ID of the bank connection in case we want to update an existing bank connection. The parameter                        bankId 
     * @type {number}
     * @memberof CreateTppWebFormV2Params
     */
    'bankConnectionId'?: number;
    /**
     * The bank connection interface to update; only relevant for banking API provider FIN_API
     * @type {string}
     * @memberof CreateTppWebFormV2Params
     */
    'bankConnectionInterfaceType'?: CreateTppWebFormV2ParamsBankConnectionInterfaceTypeEnum;
    /**
     * A custom redirect URL, if not given the mandators default redirect URL will be used
     * @type {string}
     * @memberof CreateTppWebFormV2Params
     */
    'redirectUrl'?: string;
}

export const CreateTppWebFormV2ParamsMultibankingApiProviderEnum = {
    FIN_API: 'FIN_API',
    WEALTH_API: 'WEALTH_API'
} as const;

export type CreateTppWebFormV2ParamsMultibankingApiProviderEnum = typeof CreateTppWebFormV2ParamsMultibankingApiProviderEnum[keyof typeof CreateTppWebFormV2ParamsMultibankingApiProviderEnum];
export const CreateTppWebFormV2ParamsBankConnectionInterfaceTypeEnum = {
    XS2_A: 'XS2A',
    FINTS_SERVER: 'FINTS_SERVER',
    WEB_SCRAPER: 'WEB_SCRAPER'
} as const;

export type CreateTppWebFormV2ParamsBankConnectionInterfaceTypeEnum = typeof CreateTppWebFormV2ParamsBankConnectionInterfaceTypeEnum[keyof typeof CreateTppWebFormV2ParamsBankConnectionInterfaceTypeEnum];


