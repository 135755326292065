/* tslint:disable */
/* eslint-disable */
/**
 * Finanzfluss API
 * An API for the <a href=\"https://www.finanzfluss.de/\">Finanzfluss</a> backend. Work in progress.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@finanzfluss.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { OtpProvisioningData } from '../model';
// @ts-ignore
import type { OtpRecoveryCodes } from '../model';
/**
 * OtpApi - axios parameter creator
 * @export
 */
export const OtpApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deprecated, use deleteOtp instead.  Remove the configured OTP device from the account of the logged in user.  The user will receive an email confirming the change.
         * @summary Disable OTP MFA
         * @param {string} otpCode A valid OTP code generated by the current MFA app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (otpCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'otpCode' is not null or undefined
            assertParamExists('_delete', 'otpCode', otpCode)
            const localVarPath = `/user/api/v1/otp/deprecatedDelete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (otpCode !== undefined) {
                localVarQueryParameter['otpCode'] = otpCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  Change the label of the configured OTP app for the user.
         * @summary Change the label of the configured OTP app for the user
         * @param {string} otpLabel The new label for the OTP device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeOtpLabel: async (otpLabel: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'otpLabel' is not null or undefined
            assertParamExists('changeOtpLabel', 'otpLabel', otpLabel)
            const localVarPath = `/user/api/v1/otp/changeOtpLabel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (otpLabel !== undefined) {
                localVarQueryParameter['otpLabel'] = otpLabel;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove the configured OTP device from the account of the logged in user.  The user will receive an email confirming the change.
         * @summary Disable OTP MFA
         * @param {string} otpCode A valid OTP code generated by the current MFA app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOtp: async (otpCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'otpCode' is not null or undefined
            assertParamExists('deleteOtp', 'otpCode', otpCode)
            const localVarPath = `/user/api/v1/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (otpCode !== undefined) {
                localVarQueryParameter['otpCode'] = otpCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  Initiate the setup of a new OTP device for the user.  The result is a QR code that the user can scan with their OTP app.
         * @summary Initiate the setup of a new OTP device for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateOtpSetup: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/api/v1/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  Register a new OTP MFA app by providing a valid OTP key and a label.  The user will receive an email confirming the change.  Returns a list of recovery codes that the user needs to save.
         * @summary Setup a new OTP device for the user
         * @param {string} otpCode A valid OTP code generated by the new MFA app
         * @param {string} otpLabel The label for the new OTP device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        otpSetup: async (otpCode: string, otpLabel: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'otpCode' is not null or undefined
            assertParamExists('otpSetup', 'otpCode', otpCode)
            // verify required parameter 'otpLabel' is not null or undefined
            assertParamExists('otpSetup', 'otpLabel', otpLabel)
            const localVarPath = `/user/api/v1/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (otpCode !== undefined) {
                localVarQueryParameter['otpCode'] = otpCode;
            }

            if (otpLabel !== undefined) {
                localVarQueryParameter['otpLabel'] = otpLabel;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OtpApi - functional programming interface
 * @export
 */
export const OtpApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OtpApiAxiosParamCreator(configuration)
    return {
        /**
         * Deprecated, use deleteOtp instead.  Remove the configured OTP device from the account of the logged in user.  The user will receive an email confirming the change.
         * @summary Disable OTP MFA
         * @param {string} otpCode A valid OTP code generated by the current MFA app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(otpCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(otpCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OtpApi._delete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *  Change the label of the configured OTP app for the user.
         * @summary Change the label of the configured OTP app for the user
         * @param {string} otpLabel The new label for the OTP device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeOtpLabel(otpLabel: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeOtpLabel(otpLabel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OtpApi.changeOtpLabel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Remove the configured OTP device from the account of the logged in user.  The user will receive an email confirming the change.
         * @summary Disable OTP MFA
         * @param {string} otpCode A valid OTP code generated by the current MFA app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOtp(otpCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOtp(otpCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OtpApi.deleteOtp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *  Initiate the setup of a new OTP device for the user.  The result is a QR code that the user can scan with their OTP app.
         * @summary Initiate the setup of a new OTP device for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateOtpSetup(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OtpProvisioningData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateOtpSetup(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OtpApi.initiateOtpSetup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *  Register a new OTP MFA app by providing a valid OTP key and a label.  The user will receive an email confirming the change.  Returns a list of recovery codes that the user needs to save.
         * @summary Setup a new OTP device for the user
         * @param {string} otpCode A valid OTP code generated by the new MFA app
         * @param {string} otpLabel The label for the new OTP device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async otpSetup(otpCode: string, otpLabel: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OtpRecoveryCodes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.otpSetup(otpCode, otpLabel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OtpApi.otpSetup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OtpApi - factory interface
 * @export
 */
export const OtpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OtpApiFp(configuration)
    return {
        /**
         * Deprecated, use deleteOtp instead.  Remove the configured OTP device from the account of the logged in user.  The user will receive an email confirming the change.
         * @summary Disable OTP MFA
         * @param {OtpApiDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(requestParameters: OtpApiDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp._delete(requestParameters.otpCode, options).then((request) => request(axios, basePath));
        },
        /**
         *  Change the label of the configured OTP app for the user.
         * @summary Change the label of the configured OTP app for the user
         * @param {OtpApiChangeOtpLabelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeOtpLabel(requestParameters: OtpApiChangeOtpLabelRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.changeOtpLabel(requestParameters.otpLabel, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove the configured OTP device from the account of the logged in user.  The user will receive an email confirming the change.
         * @summary Disable OTP MFA
         * @param {OtpApiDeleteOtpRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOtp(requestParameters: OtpApiDeleteOtpRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteOtp(requestParameters.otpCode, options).then((request) => request(axios, basePath));
        },
        /**
         *  Initiate the setup of a new OTP device for the user.  The result is a QR code that the user can scan with their OTP app.
         * @summary Initiate the setup of a new OTP device for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateOtpSetup(options?: RawAxiosRequestConfig): AxiosPromise<OtpProvisioningData> {
            return localVarFp.initiateOtpSetup(options).then((request) => request(axios, basePath));
        },
        /**
         *  Register a new OTP MFA app by providing a valid OTP key and a label.  The user will receive an email confirming the change.  Returns a list of recovery codes that the user needs to save.
         * @summary Setup a new OTP device for the user
         * @param {OtpApiOtpSetupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        otpSetup(requestParameters: OtpApiOtpSetupRequest, options?: RawAxiosRequestConfig): AxiosPromise<OtpRecoveryCodes> {
            return localVarFp.otpSetup(requestParameters.otpCode, requestParameters.otpLabel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in OtpApi.
 * @export
 * @interface OtpApiDeleteRequest
 */
export interface OtpApiDeleteRequest {
    /**
     * A valid OTP code generated by the current MFA app
     * @type {string}
     * @memberof OtpApiDelete
     */
    readonly otpCode: string
}

/**
 * Request parameters for changeOtpLabel operation in OtpApi.
 * @export
 * @interface OtpApiChangeOtpLabelRequest
 */
export interface OtpApiChangeOtpLabelRequest {
    /**
     * The new label for the OTP device
     * @type {string}
     * @memberof OtpApiChangeOtpLabel
     */
    readonly otpLabel: string
}

/**
 * Request parameters for deleteOtp operation in OtpApi.
 * @export
 * @interface OtpApiDeleteOtpRequest
 */
export interface OtpApiDeleteOtpRequest {
    /**
     * A valid OTP code generated by the current MFA app
     * @type {string}
     * @memberof OtpApiDeleteOtp
     */
    readonly otpCode: string
}

/**
 * Request parameters for otpSetup operation in OtpApi.
 * @export
 * @interface OtpApiOtpSetupRequest
 */
export interface OtpApiOtpSetupRequest {
    /**
     * A valid OTP code generated by the new MFA app
     * @type {string}
     * @memberof OtpApiOtpSetup
     */
    readonly otpCode: string

    /**
     * The label for the new OTP device
     * @type {string}
     * @memberof OtpApiOtpSetup
     */
    readonly otpLabel: string
}

/**
 * OtpApi - object-oriented interface
 * @export
 * @class OtpApi
 * @extends {BaseAPI}
 */
export class OtpApi extends BaseAPI {
    /**
     * Deprecated, use deleteOtp instead.  Remove the configured OTP device from the account of the logged in user.  The user will receive an email confirming the change.
     * @summary Disable OTP MFA
     * @param {OtpApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtpApi
     */
    public _delete(requestParameters: OtpApiDeleteRequest, options?: RawAxiosRequestConfig) {
        return OtpApiFp(this.configuration)._delete(requestParameters.otpCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  Change the label of the configured OTP app for the user.
     * @summary Change the label of the configured OTP app for the user
     * @param {OtpApiChangeOtpLabelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtpApi
     */
    public changeOtpLabel(requestParameters: OtpApiChangeOtpLabelRequest, options?: RawAxiosRequestConfig) {
        return OtpApiFp(this.configuration).changeOtpLabel(requestParameters.otpLabel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove the configured OTP device from the account of the logged in user.  The user will receive an email confirming the change.
     * @summary Disable OTP MFA
     * @param {OtpApiDeleteOtpRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtpApi
     */
    public deleteOtp(requestParameters: OtpApiDeleteOtpRequest, options?: RawAxiosRequestConfig) {
        return OtpApiFp(this.configuration).deleteOtp(requestParameters.otpCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  Initiate the setup of a new OTP device for the user.  The result is a QR code that the user can scan with their OTP app.
     * @summary Initiate the setup of a new OTP device for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtpApi
     */
    public initiateOtpSetup(options?: RawAxiosRequestConfig) {
        return OtpApiFp(this.configuration).initiateOtpSetup(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  Register a new OTP MFA app by providing a valid OTP key and a label.  The user will receive an email confirming the change.  Returns a list of recovery codes that the user needs to save.
     * @summary Setup a new OTP device for the user
     * @param {OtpApiOtpSetupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtpApi
     */
    public otpSetup(requestParameters: OtpApiOtpSetupRequest, options?: RawAxiosRequestConfig) {
        return OtpApiFp(this.configuration).otpSetup(requestParameters.otpCode, requestParameters.otpLabel, options).then((request) => request(this.axios, this.basePath));
    }
}

