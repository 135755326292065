import React from "react";
import styled from "@emotion/styled";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toCurrency } from "@helpers";
import { GainLossChartPercents } from "@components/Chart/Tooltips/GainLossChartPercents";
import { GainLossTypography } from "@components";

const Caption = styled(Typography)`
  font-size: 14px;
  line-height: 1.38;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export interface Props {
  gainAndLoss: number;
  returnOnInvestment: number;
  returnOnInvestmentInPercent: number;
  realizedGains: number;
  performance: number;
  currency: string;
}

export const PerformanceOverview = ({
  gainAndLoss,
  returnOnInvestment,
  returnOnInvestmentInPercent,
  realizedGains,
  performance,
  currency,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Grid container columnSpacing={3} rowSpacing={5}>
      <Grid item xs={6} sm={3} md={3}>
        <Caption>{t("performanceOverview.gainAndLoss")}</Caption>
        <GainLossTypography
          value={gainAndLoss}
          showIndicator={false}
          variant="body3"
        >
          {toCurrency(gainAndLoss, currency)}
        </GainLossTypography>
      </Grid>
      <Grid item xs={6} sm={3} md={3}>
        <Caption>{t("performanceOverview.realizedGains")}</Caption>
        <GainLossTypography
          value={realizedGains}
          showIndicator={false}
          variant="body3"
        >
          {toCurrency(realizedGains, currency)}
        </GainLossTypography>
      </Grid>
      <Grid item xs={6} sm={3} md={3}>
        <Caption>{t("performanceOverview.returnOnInvestment")}</Caption>
        <Box display="flex" flexWrap="wrap" columnGap={4}>
          <GainLossTypography
            value={returnOnInvestment}
            showIndicator={false}
            variant="body3"
          >
            {toCurrency(returnOnInvestment, currency)}
          </GainLossTypography>

          <Box>
            <GainLossChartPercents value={returnOnInvestmentInPercent} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} sm={3} md={3}>
        <Caption>{t("performanceOverview.performance")}</Caption>
        <Box display="flex">
          <GainLossChartPercents value={performance} />
        </Box>
      </Grid>
    </Grid>
  );
};
