/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { DateValuePair } from './date-value-pair';
// May contain unused imports in some cases
// @ts-ignore
import { GainLoss } from './gain-loss';

/**
 * 
 * @export
 * @interface HistoricQuoteWithRiskYieldStatistics
 */
export interface HistoricQuoteWithRiskYieldStatistics {
    /**
     * The list of quotes; today is the last record
     * @type {Array<DateValuePair>}
     * @memberof HistoricQuoteWithRiskYieldStatistics
     */
    'historicQuotes': Array<DateValuePair>;
    /**
     * The annualized return on investment
     * @type {number}
     * @memberof HistoricQuoteWithRiskYieldStatistics
     */
    'annualizedReturn': number;
    /**
     * The annualized return class, ranging from 0 (lowest) to 2 (highest)
     * @type {number}
     * @memberof HistoricQuoteWithRiskYieldStatistics
     */
    'annualizedReturnClass': HistoricQuoteWithRiskYieldStatisticsAnnualizedReturnClassEnum;
    /**
     * The annualized return class in a more complex classification, ranging from 0.0 (lowest) to 6 (highest) in 0.01 interval steps (e.g. 1.04)
     * @type {number}
     * @memberof HistoricQuoteWithRiskYieldStatistics
     */
    'annualizedReturnClassComplex': number;
    /**
     * The annualized standard deviation
     * @type {number}
     * @memberof HistoricQuoteWithRiskYieldStatistics
     */
    'annualizedStandardDeviation': number;
    /**
     * The annualized risk class based on the standard deviation, ranging from 0 (lowest) to 2 (highest)
     * @type {number}
     * @memberof HistoricQuoteWithRiskYieldStatistics
     */
    'annualizedRiskClass': HistoricQuoteWithRiskYieldStatisticsAnnualizedRiskClassEnum;
    /**
     * The annualized risk class in a more complex classification, ranging from 0.0 (lowest) to 6 (highest) in 0.01 interval steps (e.g. 1.04)
     * @type {number}
     * @memberof HistoricQuoteWithRiskYieldStatistics
     */
    'annualizedRiskClassComplex': number;
    /**
     * 
     * @type {GainLoss}
     * @memberof HistoricQuoteWithRiskYieldStatistics
     */
    'changeToday': GainLoss;
    /**
     * 
     * @type {GainLoss}
     * @memberof HistoricQuoteWithRiskYieldStatistics
     */
    'changeYTD'?: GainLoss;
    /**
     * 
     * @type {GainLoss}
     * @memberof HistoricQuoteWithRiskYieldStatistics
     */
    'changeOneYear'?: GainLoss;
    /**
     * 
     * @type {GainLoss}
     * @memberof HistoricQuoteWithRiskYieldStatistics
     */
    'changeTotal': GainLoss;
}

export const HistoricQuoteWithRiskYieldStatisticsAnnualizedReturnClassEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type HistoricQuoteWithRiskYieldStatisticsAnnualizedReturnClassEnum = typeof HistoricQuoteWithRiskYieldStatisticsAnnualizedReturnClassEnum[keyof typeof HistoricQuoteWithRiskYieldStatisticsAnnualizedReturnClassEnum];
export const HistoricQuoteWithRiskYieldStatisticsAnnualizedRiskClassEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type HistoricQuoteWithRiskYieldStatisticsAnnualizedRiskClassEnum = typeof HistoricQuoteWithRiskYieldStatisticsAnnualizedRiskClassEnum[keyof typeof HistoricQuoteWithRiskYieldStatisticsAnnualizedRiskClassEnum];


