import React from "react";
import { Box, BoxProps, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as FinanzflussLogo } from "@components/Logo/images/finanzfluss-compact-logo.svg";
import { BUY } from "@helpers";

const Container = styled(Box)<{ variant: "default" | "large" }>(
  ({ theme, variant }) => ({
    width: variant === "large" ? "100%" : "fit-content",
    background: "linear-gradient(102.88deg, #283874 22.38%, #4D6BDD 73.25%)",
    padding: variant === "large" ? theme.spacing(4) : theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius * (variant === "large" ? 2 : 1),
  })
);

type Props = {
  variant?: "default" | "large";
  text?: string;
} & BoxProps;

export const PlusGradient = ({ variant = "default", text, ...rest }: Props) => {
  const { t } = useTranslation();
  return (
    <Container
      variant={variant}
      onClick={() => window.open(BUY)}
      {...rest}
      sx={{
        cursor: "pointer",
        ...rest.sx,
      }}
    >
      <Box display="flex" alignItems="center" gap={1}>
        <FinanzflussLogo
          height={variant === "large" ? 13.5 : 11.5}
          width={variant === "large" ? 13.5 : 11.5}
        />
        <Typography variant="body2" color="white" letterSpacing={-0.5}>
          PLUS
        </Typography>
      </Box>
      {variant === "large" && (
        <Typography color="white" variant="body2" fontWeight={400}>
          {text || t("plus.text")}
        </Typography>
      )}
    </Container>
  );
};
