import React from "react";
import { Box } from "@mui/material";
import LazyLoad from "react-lazyload";
import styled from "@emotion/styled";
import { HistoricalQuotesByInvestmentChart } from "@components/Chart/HistoricalQuotesChart";
import { LoadingPlaceHolder } from "@components";
import { ComparisonChart } from "@components/Chart/ComparisonChart";
import { FundamentalsBlock, Header } from "../FundamentalsBlock";
import { DividendsByInvestmentChart } from "../DividendsChart/DividendsByInvestmentChart";
import { DetailInformationByInvestment } from "../DetailInformation/DetailInformation";
import { GainLossStatisticsLoader } from "../../../investment/details/GainLossStatisticsLoader";
import { useFundamentalsSettings } from "../../hooks/useFundamentalsSettings";
import { useMetrics } from "./hooks/useMetrics";
import { useSelectedMetricsAndMenuItems } from "./hooks/useSelectedMetricsAndMenuItems";
export interface Props {
  investmentId: number;
  isInvestmentStock?: boolean;
  areDividendsPresent?: boolean;
}

const LazyLoadWrapperMaxWidth = styled.div`
  width: 100%;
  display: flex;
  flex-basis: 50%;
  height: 100%;

  .lazyload-wrapper {
    height: 100%;
    width: 100%;
  }
`;

export const SecurityFundamentalsByInvestmentId = ({
  investmentId,
  areDividendsPresent = false,
  isInvestmentStock = false,
}: Props) => {
  const { allMetric } = useMetrics();

  const { savedMetrics, showMetric, hideMetric, changeMetric, isLoading } =
    useFundamentalsSettings({
      defaultMetrics: ["priceToEarnings", "netIncome"],
    });

  const { menuItems, selectedMetrics } = useSelectedMetricsAndMenuItems(
    savedMetrics,
    allMetric
  );

  if (isLoading) {
    return (
      <Box pt={6}>
        <LoadingPlaceHolder />
      </Box>
    );
  }

  return (
    <FundamentalsBlock
      header={
        <Header
          menuItems={menuItems}
          onSelect={showMetric}
          selectedMetrics={savedMetrics}
        />
      }
    >
      <LazyLoadWrapperMaxWidth>
        <LazyLoad>
          <GainLossStatisticsLoader investmentId={investmentId} />
        </LazyLoad>
      </LazyLoadWrapperMaxWidth>

      {isInvestmentStock && (
        <LazyLoadWrapperMaxWidth>
          <LazyLoad>
            <DetailInformationByInvestment investmentId={investmentId} />
          </LazyLoad>
        </LazyLoadWrapperMaxWidth>
      )}

      {areDividendsPresent && (
        <LazyLoadWrapperMaxWidth>
          <LazyLoad>
            <DividendsByInvestmentChart investmentId={investmentId} />
          </LazyLoad>
        </LazyLoadWrapperMaxWidth>
      )}

      <LazyLoadWrapperMaxWidth>
        <LazyLoad>
          <HistoricalQuotesByInvestmentChart investmentId={investmentId} />
        </LazyLoad>
      </LazyLoadWrapperMaxWidth>

      {selectedMetrics.map((value) => (
        <LazyLoadWrapperMaxWidth key={value.mainMetricKey}>
          <LazyLoad>
            <ComparisonChart
              investmentId={investmentId}
              defaultValue={value}
              mainMetricKey={value.mainMetricKey}
              onHide={() => hideMetric(value.mainMetricKey)}
              onChange={(secondValue) =>
                changeMetric(value.mainMetricKey, secondValue)
              }
            />
          </LazyLoad>
        </LazyLoadWrapperMaxWidth>
      ))}
    </FundamentalsBlock>
  );
};
