import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const setBookingAndInvestmentValuesAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (event.type !== "BOOKING_INITIALIZED")
    throw new Error(`Invalid event type ${event.type}`);

  return {
    bookingValues: event.bookingValues,
    bookingValuesToRestore: event.bookingValues,
    investmentValues: event.investmentValues,
  };
};
