import React, { useEffect, useMemo, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { getDateFormatter, toPercent } from "@helpers";
import { ComparisonValue } from "@features/dashboard/types";
import { PerformanceValuationTooltip } from "@components/Chart/Tooltips/PerformanceValuationTooltip";
import { GainLossChartPercents } from "@components/Chart/Tooltips/GainLossChartPercents";
import { WithLoadingSpinner } from "@components/LoadingSpinner/WithLoadingSpinner";
import commonHighchartsOptions from "@components/Chart/commonHighchartsOptions";
import { NoAccountsStub } from "@components/NoAccountsStub";
import { InvestmentChartDataItem } from "@api/v1/hooks/performance/useInvestmentsChartDataTotal";
import { StockIndex } from "@constants";

const options: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  chart: {
    type: "line",
    ...commonHighchartsOptions.chart,
  },
  title: {
    text: "",
  },
  colors: ["#13CC89", "#0D8F60", "#e56043"],
  tooltip: {
    ...commonHighchartsOptions.tooltip,
    split: true,
    formatter: function () {
      return PerformanceValuationTooltip({
        formatter: (value) => (
          <GainLossChartPercents value={(value as number) / 100} />
        ),
        points: this.points,
        x: this.x,
      });
    },
  },
  xAxis: {
    type: "datetime",
    tickLength: 0,
    lineColor: "#EEF2F6",
    crosshair: {
      color: "#EEF2F6",
    },
  },
  yAxis: {
    title: {
      text: "",
    },
    tickAmount: 6,
    labels: {
      formatter: (data) => {
        return renderToString(
          <div
            style={{
              fontFamily: "Averta,Arial",
              fontSize: "11px",
              fontWeight: 400,
              color: "#737E93",
            }}
          >
            {toPercent((data.value as number) / 100, "auto", 0, 0)}
          </div>
        );
      },
    },
  },
  legend: {
    labelFormatter: function () {
      return renderToString(
        <div
          style={{
            fontFamily: "Averta,Arial",
            fontSize: "11px",
            fontWeight: 400,
            color: "#737E93",
          }}
        >
          {this.name}
        </div>
      );
    },
    useHTML: true,
    align: "left",
    symbolHeight: 6,
    symbolWidth: 6,
    symbolRadius: 4,
    symbolPadding: 8,
    squareSymbol: false,
    itemStyle: { lineHeight: "17px" },
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
        symbol: "circle",
        states: {
          hover: {
            radius: 4,
            lineWidth: 1,
            lineWidthPlus: 0,
          },
        },
      },
    },
    line: {
      legendSymbol: "rectangle",
      lineWidth: 2,
    },
  },
};

type Props = {
  chartData: {
    totalValuesWithDividends: InvestmentChartDataItem[];
    totalValues: InvestmentChartDataItem[];
    comparisonValues: InvestmentChartDataItem[];
  };
  intervalType: IntervalType;
  comparedTicker?: ComparisonValue;
  setComparedTicker: (ticker?: ComparisonValue) => void;
  isLoading?: boolean;
  isFetched?: boolean;
  withDividends: boolean;
  onDividendsChange: (value: boolean) => void;
  indexes: StockIndex[];
};

export const DashboardPerformanceChart = ({
  chartData,
  comparedTicker,
  intervalType,
  isLoading,
  isFetched,
  withDividends,
}: Props) => {
  const { t } = useTranslation();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const chartOptions = useMemo(
    () => ({
      ...options,
      series: [
        {
          name: t("dashboardPage.dashboard.portfolio"),
          data: chartData.totalValues,
          color: "#13CC89",
        },
        ...(withDividends
          ? [
              {
                name: t("dashboardPage.dashboard.withDividends"),
                data: chartData.totalValuesWithDividends,
                color: "#0D8F60",
              },
            ]
          : []),

        ...(comparedTicker
          ? [
              {
                name: comparedTicker.label,
                data: chartData.comparisonValues,
                color: "#e56043",
              },
            ]
          : []),
      ],
      xAxis: {
        ...options.xAxis,
        labels: {
          overflow: "justify",
          formatter: (data: { value: string | number }) => {
            return renderToString(
              <div
                style={{
                  fontFamily: "Averta,Arial",
                  fontSize: "11px",
                  fontWeight: 400,
                  color: "#737E93",
                }}
              >
                {getDateFormatter(intervalType, data.value)}
              </div>
            );
          },
        },
      },
    }),
    [chartData, withDividends, comparedTicker, intervalType, t]
  );

  useEffect(() => {
    chartComponentRef.current?.chart?.reflow();
  }, [chartOptions]);

  return (
    <WithLoadingSpinner isLoading={Boolean(isLoading)} mb={6}>
      {isFetched && !isLoading && !chartData.totalValues.length ? (
        <NoAccountsStub height="300px" />
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          ref={chartComponentRef}
        />
      )}
    </WithLoadingSpinner>
  );
};
