import { Box, InputLabel, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TransactionWizardContext } from "@features/transactionWizard/modal/stateMachine/types/transactionWizardContext";
import {
  AdditionalOption,
  InvestmentSelect,
} from "@components/common/forms/InvestmentSelect";
import { DepotSelect } from "@components";
import {
  investmentToInvestmentValues,
  investmentToSymbol,
  investmentTypeToAccountType,
  investmentTypeToFilterSubcategory,
  investmentValuesToAccountSubcategory,
} from "@features/transactionWizard/modal/stateMachine/utils/utils";
import { useListAndSearchAllAccounts } from "@generated/apiv1/hooks";
import {
  Investment,
  InvestmentTypeEnum,
  QuoteV2,
  SymbolV2,
} from "@generated/apiv1";
import { InvestmentValues } from "@features/transactionWizard/modal/stateMachine/types/investmentValues";
import { sellTypes } from "@features/transactionWizard/modal/steps/constants";
import { useMyself, useToken } from "@api";
import { PlusButton } from "@components/Plus/PlusButton";
import { StepContainer } from "@features/transactionWizard/modal/components/StepContainer";
import { InvestmentTypeSelect } from "@components/common/forms/InvestmentTypeSelect";
import {
  CategoryType,
  useSubcategory,
  AccountSubcategoryEnum,
  InvestmentSubcategoryEnum,
  useSubcategories,
} from "@providers/SubcategoriesProvider";

const isNextDisabled = (
  isInvestmentRequired: boolean,
  {
    depotId,
    investment,
  }: {
    depotId: number | undefined;
    investment?: InvestmentValues | AdditionalOption | null;
  }
) => {
  if (isInvestmentRequired) {
    if (!depotId) return true;
    return !investment?.id;
  }

  return !depotId;
};

export interface Props {
  handleNext: (
    depotId: number,
    investmentValues: InvestmentValues,
    symbol?: SymbolV2 & { quote?: QuoteV2 },
    comment?: string
  ) => void;
  context: TransactionWizardContext;
  isManualTransaction?: boolean;
  isLoading?: boolean;
  isInvestmentRequired?: boolean;
  isExchangeOrCarveOut?: boolean;
}

export const NameAndDepotStep = ({
  handleNext,
  context,
  isLoading: isStepLoading = false,
  isManualTransaction = false,
  isExchangeOrCarveOut = false,
  isInvestmentRequired = false,
}: Props) => {
  const { t } = useTranslation();
  const { data: token } = useToken();

  const isSelling = sellTypes.includes(context.bookingValues.type || "buy");
  const shouldShowTypeSelect = isManualTransaction && !isSelling;
  const [symbol, setSymbol] = useState<
    (SymbolV2 & { quote?: QuoteV2 }) | undefined
  >(context.symbol);
  const [investmentType, setInvestmentType] = useState<InvestmentTypeEnum>(
    context.investmentValues.type! ||
      (shouldShowTypeSelect ? "11_stock" : undefined)
  );

  const [investment, setInvestment] = useState<
    InvestmentValues | AdditionalOption | null
  >(context.investmentValues || null);
  const [depotId, setDepotId] = useState<number | undefined>(
    context.defaultDepotId || context.investmentValues.depotId
  );
  const [comment, setComment] = useState<string>(
    context.bookingValues.comment || ""
  );
  const realEstateInvestmentSubCategory = useSubcategory({
    name: InvestmentSubcategoryEnum.REAL_ESTATE,
    categoryType: CategoryType.INVESTMENT,
  });

  const { data: myself } = useMyself();
  const { subcategories } = useSubcategories({
    categoryType: CategoryType.ACCOUNT,
  });
  const type = investmentTypeToAccountType(investmentType);
  const subcategory = investmentValuesToAccountSubcategory(
    context.investmentValues,
    isManualTransaction
  );
  const depotIdFromParams = context.defaultDepotId;
  const investmentIdFromParams = context.defaultInvestmentId;
  const shouldDisableInvestmentSelection =
    Boolean(investmentIdFromParams) || !depotId;
  const shouldDisableDepotSelection =
    Boolean(depotIdFromParams) || Boolean(investmentIdFromParams);
  const areNonListedSelected = context.investmentValues.type === "71_massets";

  const shouldShowInvestmentSelect =
    isExchangeOrCarveOut || isManualTransaction || areNonListedSelected;

  const shouldShowComment = !(isExchangeOrCarveOut && isManualTransaction);
  const numberOfLots =
    investment?.id === "new"
      ? 0
      : (investment as InvestmentValues)?.numberOfLots || 0;

  useEffect(() => {
    if (investmentIdFromParams) {
      setInvestment({ id: investmentIdFromParams });
    }
  }, [investmentIdFromParams]);

  const onNextDisabled = isNextDisabled(
    isInvestmentRequired ||
      isExchangeOrCarveOut ||
      (isManualTransaction && isSelling),
    {
      depotId,
      investment,
    }
  );
  const notAvailableTypes: InvestmentTypeEnum[] = [
    "71_massets",
    "91_managed",
    "41_cash",
  ];
  const filterSubcategory = investmentTypeToFilterSubcategory(
    investmentType,
    isManualTransaction
  );
  const subcategoryId = subcategories?.find(
    (subcategory) => subcategory.name === filterSubcategory
  )?.id;

  const { data, isLoading } = useListAndSearchAllAccounts({
    type: Boolean(depotIdFromParams) ? undefined : [type],
    subCategoryId:
      subcategoryId && !depotIdFromParams ? [subcategoryId] : undefined,
    id: Boolean(depotIdFromParams) ? [Number(depotId)] : undefined,
  });

  const selectableDepots = useMemo(
    () =>
      data?.accounts?.map((depot) => ({
        name: depot.name,
        value: depot.id,
        image: depot.iconUrl,
      })),
    [data]
  );

  const onDepotSelect = (depotId: number | null) => {
    setInvestment(null);
    setDepotId(depotId ?? undefined);
  };

  const onInvestmentSelect = async (
    investment: Investment | AdditionalOption | null
  ) => {
    setInvestment(
      investment?.id === "new"
        ? investment
        : investmentToInvestmentValues(investment as Investment, depotId)
    );

    if (investment?.id !== "new") {
      const symbol = await investmentToSymbol(
        token?.access_token,
        investment as Investment
      );
      setSymbol(symbol ?? undefined);
    }
  };

  const onNext = () => {
    if (onNextDisabled || !depotId) return;
    const actualComment = myself?.isUserPro ? comment : undefined;

    if (investment && investment?.id !== "new") {
      handleNext(
        depotId,
        { ...(investment as InvestmentValues), availableLots: numberOfLots },
        symbol,
        actualComment
      );
      return;
    }

    return handleNext(
      depotId,
      {
        availableLots: numberOfLots,
        name: (investment as AdditionalOption)?.standardisedName,
        subCategoryId:
          subcategory === AccountSubcategoryEnum.REAL_ESTATE
            ? realEstateInvestmentSubCategory?.id
            : undefined,
        ...(isManualTransaction ? { type: investmentType } : {}),
      },
      symbol,
      actualComment
    );
  };

  return (
    <StepContainer
      onNextDisabled={onNextDisabled}
      handleNext={onNext}
      isLoading={isStepLoading}
    >
      <Box display="flex" flexDirection="column" gap={6}>
        {shouldShowTypeSelect && (
          <InvestmentTypeSelect
            value={
              notAvailableTypes.includes(investmentType)
                ? "11_stock"
                : investmentType
            }
            onChange={setInvestmentType}
            MenuProps={{
              disablePortal: true,
            }}
            notAvailableTypes={notAvailableTypes}
          />
        )}

        <DepotSelect
          onDepotChange={onDepotSelect}
          selectableDepots={selectableDepots}
          isLoading={isLoading}
          defaultSubcategory={subcategory}
          type={type}
          selectedDepotId={depotId}
          disabled={shouldDisableDepotSelection}
          allowNewDepot={!isSelling}
          MenuProps={{
            disablePortal: true,
          }}
        />

        {shouldShowInvestmentSelect && (
          <Box>
            <InvestmentSelect
              accountId={depotId}
              value={investment}
              label={
                isSelling && context.bookingValues.type
                  ? t(
                      `transactionWizardModal.depots.investmentSell_${context.bookingValues.type}`
                    )
                  : t("transactionWizardModal.depots.investmentSelect")
              }
              allowAddingNew={!isSelling && !isExchangeOrCarveOut}
              onChange={onInvestmentSelect}
              disabled={shouldDisableInvestmentSelection}
              type={investmentType}
              slotProps={{
                popper: {
                  sx: {
                    zIndex: 4001,
                  },
                },
              }}
            />
          </Box>
        )}
        {shouldShowComment && (
          <TextField
            autoComplete="off"
            label={
              <Box display="flex" gap={3}>
                <InputLabel sx={{ marginBottom: 0 }}>
                  {t("transactionWizardModal.depots.comment")}
                </InputLabel>
                {!myself?.isUserPro && <PlusButton />}
              </Box>
            }
            data-testid="comment"
            fullWidth={true}
            rows={3}
            multiline
            disabled={!myself?.isUserPro}
            onChange={(event) => {
              setComment(event.target.value);
            }}
            type="text"
            value={comment}
            inputProps={{
              style: { textAlign: "left" },
              "data-testid": "comment-input",
            }}
          />
        )}
      </Box>
    </StepContainer>
  );
};
