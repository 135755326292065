import { InvokeCallback, Sender } from "xstate";
import { parseISO } from "date-fns";
import { Booking, InvestmentTypeEnum } from "@api";
import { round } from "@helpers";
import { getBookingWithToken } from "@api/v1/methods/bookings";
import { getInvestmentWithToken } from "@api/v1/methods/investments";
import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";
import { InvestmentValues } from "../types/investmentValues";
import { BookingValues } from "../types/bookingValues";
import { Amounts, getAmounts } from "../../hooks/useUpdateBookingValues";

export const loadExistingBooking =
  (
    context: TransactionWizardContext,
    event: TransactionWizardEvent
  ): InvokeCallback<TransactionWizardEvent, TransactionWizardEvent> =>
  (callback) => {
    if (event.type !== "INIT_FROM_BOOKING")
      throw new Error(`Invalid event type ${event.type}`);

    loadBooking(event.id, callback);
  };

const getEntryQuote = (
  booking: Booking,
  investmentType?: InvestmentTypeEnum
) => {
  if (booking.entryQuote == null) return undefined;
  if (investmentType === "91_managed") {
    return booking.numberOfLots
      ? Math.abs(booking.entryQuote * booking.numberOfLots)
      : undefined;
  }
  return Math.abs(booking.entryQuote);
};

const loadBooking = async (
  id: number,
  callback: Sender<TransactionWizardEvent>
) => {
  try {
    const booking = await getBookingWithToken(id);
    const investment = await getInvestmentWithToken(booking.investment.id);

    if (booking.type === "split" || booking.type === "reverse_split") {
      window.location.replace(`/investments/${booking.investment.id}`);
      return;
    }

    const investmentValues: InvestmentValues = {
      id: investment.id,
      depotId: investment.account.id,
      name: investment.name,
      isin: investment.isin,
      wkn: investment.wkn,
      type: investment.type,
      tickerSymbol: investment.tickerSymbol,
      quoteProvider: investment.quote?.quoteProvider,
      quoteCurrency: investment.quote?.currency,
      investmentCurrency: investment.currency,
    };

    const bookingValues: BookingValues = {
      id: booking.id,
      type: booking.type,
      date: parseISO(booking.valueDate),
      numberOfLots: Math.abs(booking.numberOfLots),
      entryQuote: getEntryQuote(booking, investmentValues.type),
      securityPrice: booking.securityPrice
        ? Math.abs(booking.securityPrice)
        : undefined,
      taxAmount: booking.taxAmount ? -booking.taxAmount : 0,
      commission: booking.commission ? -booking.commission : 0,
      exchangeRate: round(1.0 / (booking.exchangeRate || 1), 6),
      taxAndCommissionInEur: false,
      comment: booking.comment,
    };

    const amounts: Amounts = getAmounts(
      bookingValues.type!,
      bookingValues.numberOfLots!,
      bookingValues.entryQuote || 0,
      bookingValues.exchangeRate || 1,
      bookingValues.commission || 0,
      bookingValues.taxAmount || 0,
      investmentValues.type === "31_bond",
      false
    );

    const withAmount: BookingValues = {
      ...bookingValues,
      amount: round(amounts.amount, 2),
      amountAC: round(amounts.amountAC, 2),
    };

    callback({
      type: "BOOKING_INITIALIZED",
      investmentValues: investmentValues,
      bookingValues: withAmount,
      bookingValuesToRestore: withAmount,
    });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};
