import React from "react";
import { Box } from "@mui/material";
import LazyLoad from "react-lazyload";
import styled from "@emotion/styled";
import { LoadingPlaceHolder } from "@components";
import { HistoricalQuotesChart } from "@components/Chart/HistoricalQuotesChart";
import { ComparisonChart } from "@components/Chart/ComparisonChart";
import { GainLossStatisticsLoader } from "@features/watchlists/details/GainLossStatisticsLoader";
import { FundamentalsBlock, Header } from "../FundamentalsBlock";
import { DividendsChart } from "../DividendsChart/DividendsChart";
import { DetailInformation } from "../DetailInformation/DetailInformation";
import { useDividendsPerYear } from "../../hooks/useDividendsPerYear";
import { useFundamental } from "../../hooks/useFundamental";
import { useFundamentalsSettings } from "../../hooks/useFundamentalsSettings";
import { useMetrics } from "./hooks/useMetrics";
import { useSelectedMetricsAndMenuItems } from "./hooks/useSelectedMetricsAndMenuItems";

const LazyLoadWrapperMaxWidth = styled(Box)<{ maxWidth?: number }>`
  width: 100%;
  height: 100%;

  .lazyload-wrapper {
    height: 100%;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: none;
  }
`;

export const SecurityFundamentalsByIsin = ({
  watchlistEntryId,
  isin,
  quoteProvider,
  tickerSymbol,
}: {
  watchlistEntryId?: number;
  quoteProvider: string;
  tickerSymbol: string;
  isin: string;
}) => {
  const { data: dividends, isLoading: isDividendsLoading } =
    useDividendsPerYear(isin);
  const { data: security, isLoading: isSecurityLoading } = useFundamental(isin);

  const areDividendsPresent = dividends && dividends?.length > 0;
  const isSecurityPresent = !!security;

  const { allMetric } = useMetrics();

  const { savedMetrics, showMetric, hideMetric, changeMetric, isLoading } =
    useFundamentalsSettings({
      defaultMetrics: ["priceToEarnings", "netIncome"],
    });

  const { menuItems, selectedMetrics } = useSelectedMetricsAndMenuItems(
    savedMetrics,
    allMetric
  );

  if (isLoading || isDividendsLoading || isSecurityLoading) {
    return (
      <Box mt={6}>
        <LoadingPlaceHolder />
      </Box>
    );
  }

  return (
    <FundamentalsBlock
      header={
        <Header
          menuItems={menuItems}
          onSelect={showMetric}
          selectedMetrics={savedMetrics}
        />
      }
    >
      {watchlistEntryId && (
        <LazyLoadWrapperMaxWidth>
          <LazyLoad>
            <GainLossStatisticsLoader watchlistEntryId={watchlistEntryId} />
          </LazyLoad>
        </LazyLoadWrapperMaxWidth>
      )}
      {isSecurityPresent && (
        <LazyLoadWrapperMaxWidth>
          <LazyLoad>
            <DetailInformation
              isin={isin}
              quoteProvider={quoteProvider}
              tickerSymbol={tickerSymbol}
              currency={"EUR"}
            />
          </LazyLoad>
        </LazyLoadWrapperMaxWidth>
      )}
      {areDividendsPresent ? (
        <LazyLoadWrapperMaxWidth>
          <LazyLoad>
            <DividendsChart isin={isin} currency={"EUR"} />
          </LazyLoad>
        </LazyLoadWrapperMaxWidth>
      ) : null}
      <LazyLoadWrapperMaxWidth>
        <LazyLoad>
          <HistoricalQuotesChart
            quoteProvider={quoteProvider}
            tickerSymbol={tickerSymbol}
          />
        </LazyLoad>
      </LazyLoadWrapperMaxWidth>
      {selectedMetrics.map((value) => {
        return (
          <LazyLoadWrapperMaxWidth key={value.mainMetricKey}>
            <LazyLoad>
              <ComparisonChart
                isin={isin}
                quoteProvider={quoteProvider}
                tickerSymbol={tickerSymbol}
                defaultValue={value}
                mainMetricKey={value.mainMetricKey}
                onHide={() => hideMetric(value.mainMetricKey)}
                onChange={(secondValue) =>
                  changeMetric(value.mainMetricKey, secondValue)
                }
              />
            </LazyLoad>
          </LazyLoadWrapperMaxWidth>
        );
      })}
    </FundamentalsBlock>
  );
};
