export const CACHE_KEYS = {
  ACCOUNTHOLDERS_ACCOUNT_HOLDER_PAYOUT_STATISTICS:
    "ACCOUNTHOLDERS_ACCOUNT_HOLDER_PAYOUT_STATISTICS",
  ACCOUNTHOLDERS_ACCOUNT_HOLDERS_ID: "ACCOUNTHOLDERS_ACCOUNT_HOLDERS_ID",
  ACCOUNTHOLDERS_CREATE_ACCOUNT_HOLDER: "ACCOUNTHOLDERS_CREATE_ACCOUNT_HOLDER",
  ACCOUNTHOLDERS_DELETE_ACCOUNT_HOLDER: "ACCOUNTHOLDERS_DELETE_ACCOUNT_HOLDER",
  ACCOUNTHOLDERS_LIST_AND_SEARCH_ALL_ACCOUNT_HOLDERS:
    "ACCOUNTHOLDERS_LIST_AND_SEARCH_ALL_ACCOUNT_HOLDERS",
  ACCOUNTHOLDERS_MAY_UPDATE_NAME: "ACCOUNTHOLDERS_MAY_UPDATE_NAME",
  ACCOUNTHOLDERS_UPDATE_ACCOUNT_HOLDER: "ACCOUNTHOLDERS_UPDATE_ACCOUNT_HOLDER",
  ACCOUNTHOLDERS_UPDATE_CUSTOM_FEE_AGREEMENT:
    "ACCOUNTHOLDERS_UPDATE_CUSTOM_FEE_AGREEMENT",
  ACCOUNTHOLDERS_UPDATE_INVESTMENT_EXPERIENCE:
    "ACCOUNTHOLDERS_UPDATE_INVESTMENT_EXPERIENCE",
  ACCOUNTHOLDERS_UPDATE_REFERENCE_ACCOUNT:
    "ACCOUNTHOLDERS_UPDATE_REFERENCE_ACCOUNT",
  ACCOUNTS_ACCOUNT_TRANSFER: "ACCOUNTS_ACCOUNT_TRANSFER",
  ACCOUNTS_ACCOUNTS_ID: "ACCOUNTS_ACCOUNTS_ID",
  ACCOUNTS_BALANCES: "ACCOUNTS_BALANCES",
  ACCOUNTS_CASH_FLOWS: "ACCOUNTS_CASH_FLOWS",
  ACCOUNTS_CATEGORIZE: "ACCOUNTS_CATEGORIZE",
  ACCOUNTS_CHANGE_CONTRA_ACCOUNT: "ACCOUNTS_CHANGE_CONTRA_ACCOUNT",
  ACCOUNTS_CREATE_ACCOUNT: "ACCOUNTS_CREATE_ACCOUNT",
  ACCOUNTS_CREATE_OR_UPDATE_SELECT_GROUP:
    "ACCOUNTS_CREATE_OR_UPDATE_SELECT_GROUP",
  ACCOUNTS_DELETE_ACCOUNT: "ACCOUNTS_DELETE_ACCOUNT",
  ACCOUNTS_DELETE_SELECT_GROUP: "ACCOUNTS_DELETE_SELECT_GROUP",
  ACCOUNTS_FUND_COMMISSIONS: "ACCOUNTS_FUND_COMMISSIONS",
  ACCOUNTS_HISTORIC_VALUATIONS: "ACCOUNTS_HISTORIC_VALUATIONS",
  ACCOUNTS_ID_CATEGORIZE: "ACCOUNTS_ID_CATEGORIZE",
  ACCOUNTS_IMPORT_REMOTE_ACCOUNT: "ACCOUNTS_IMPORT_REMOTE_ACCOUNT",
  ACCOUNTS_LINK_TO_REMOTE_ACCOUNT: "ACCOUNTS_LINK_TO_REMOTE_ACCOUNT",
  ACCOUNTS_LIST_AND_SEARCH_ALL_ACCOUNTS:
    "ACCOUNTS_LIST_AND_SEARCH_ALL_ACCOUNTS",
  ACCOUNTS_LIST_AND_SEARCH_ALL_ACCOUNTS_TPP_WEB_FORM:
    "ACCOUNTS_LIST_AND_SEARCH_ALL_ACCOUNTS_TPP_WEB_FORM",
  ACCOUNTS_LIST_AND_SEARCH_ALL_REMOTE_ACCOUNTS:
    "ACCOUNTS_LIST_AND_SEARCH_ALL_REMOTE_ACCOUNTS",
  ACCOUNTS_MANUAL_SYNC_REQUIRED: "ACCOUNTS_MANUAL_SYNC_REQUIRED",
  ACCOUNTS_PURGE_ACCOUNT: "ACCOUNTS_PURGE_ACCOUNT",
  ACCOUNTS_REMOTE_STATUS: "ACCOUNTS_REMOTE_STATUS",
  ACCOUNTS_SELECT_GROUPS_ID: "ACCOUNTS_SELECT_GROUPS_ID",
  ACCOUNTS_SELECTABLE_ACCOUNTS: "ACCOUNTS_SELECTABLE_ACCOUNTS",
  ACCOUNTS_UNLINK_REMOTE_ACCOUNT: "ACCOUNTS_UNLINK_REMOTE_ACCOUNT",
  ACCOUNTS_UPDATE_ACCOUNT: "ACCOUNTS_UPDATE_ACCOUNT",
  ACCOUNTS_UPDATE_REMOTE_STATUS: "ACCOUNTS_UPDATE_REMOTE_STATUS",
  ACCOUNTS_VALUATIONS: "ACCOUNTS_VALUATIONS",
  APPLICATIONPROPERTIES_CREATE_APPLICATION_PROPERTY:
    "APPLICATIONPROPERTIES_CREATE_APPLICATION_PROPERTY",
  APPLICATIONPROPERTIES_DELETE_APPLICATION_PROPERTY:
    "APPLICATIONPROPERTIES_DELETE_APPLICATION_PROPERTY",
  APPLICATIONPROPERTIES_GET_APPLICATION_PROPERTY:
    "APPLICATIONPROPERTIES_GET_APPLICATION_PROPERTY",
  APPLICATIONPROPERTIES_LIST_AND_SEARCH_ALL_APPLICATION_PROPERTIES:
    "APPLICATIONPROPERTIES_LIST_AND_SEARCH_ALL_APPLICATION_PROPERTIES",
  APPLICATIONPROPERTIES_UPDATE_APPLICATION_PROPERTY:
    "APPLICATIONPROPERTIES_UPDATE_APPLICATION_PROPERTY",
  ASSETSUNDERMANAGEMENT_ASSETS_UNDER_MANAGEMENT:
    "ASSETSUNDERMANAGEMENT_ASSETS_UNDER_MANAGEMENT",
  AUTHORIZATIONS_GET_AUTHORIZATION: "AUTHORIZATIONS_GET_AUTHORIZATION",
  BANKCONNECTIONS_ADD_BANK_CONNECTION: "BANKCONNECTIONS_ADD_BANK_CONNECTION",
  BANKCONNECTIONS_ADD_BANK_CONNECTION_V2:
    "BANKCONNECTIONS_ADD_BANK_CONNECTION_V2",
  BANKCONNECTIONS_BANK_CONNECTION_UPDATE_PROCESS_V2:
    "BANKCONNECTIONS_BANK_CONNECTION_UPDATE_PROCESS_V2",
  BANKCONNECTIONS_BANK_CONNECTIONS_CASHBACK:
    "BANKCONNECTIONS_BANK_CONNECTIONS_CASHBACK",
  BANKCONNECTIONS_BANK_CONNECTIONS_CASHBACK_V2:
    "BANKCONNECTIONS_BANK_CONNECTIONS_CASHBACK_V2",
  BANKCONNECTIONS_CONNECT_INTERFACE: "BANKCONNECTIONS_CONNECT_INTERFACE",
  BANKCONNECTIONS_CONNECT_INTERFACE_V2: "BANKCONNECTIONS_CONNECT_INTERFACE_V2",
  BANKCONNECTIONS_DELETE_BANK_CONNECTION:
    "BANKCONNECTIONS_DELETE_BANK_CONNECTION",
  BANKCONNECTIONS_DELETE_BANK_CONNECTION_V2:
    "BANKCONNECTIONS_DELETE_BANK_CONNECTION_V2",
  BANKCONNECTIONS_DELETE_BANK_CONNECTIONS_CASHBACK:
    "BANKCONNECTIONS_DELETE_BANK_CONNECTIONS_CASHBACK",
  BANKCONNECTIONS_DELETE_BANK_CONNECTIONS_CASHBACK_V2:
    "BANKCONNECTIONS_DELETE_BANK_CONNECTIONS_CASHBACK_V2",
  BANKCONNECTIONS_DELETE_BANKING_API_CONNECTION:
    "BANKCONNECTIONS_DELETE_BANKING_API_CONNECTION",
  BANKCONNECTIONS_DELETE_BANKING_API_CONNECTION_ID:
    "BANKCONNECTIONS_DELETE_BANKING_API_CONNECTION_ID",
  BANKCONNECTIONS_DELETE_BANKING_API_CONNECTION_ID_V2:
    "BANKCONNECTIONS_DELETE_BANKING_API_CONNECTION_ID_V2",
  BANKCONNECTIONS_DELETE_BANKING_API_CONNECTION_V2:
    "BANKCONNECTIONS_DELETE_BANKING_API_CONNECTION_V2",
  BANKCONNECTIONS_DELETE_STORED_SECRETS:
    "BANKCONNECTIONS_DELETE_STORED_SECRETS",
  BANKCONNECTIONS_DELETE_STORED_SECRETS_V2:
    "BANKCONNECTIONS_DELETE_STORED_SECRETS_V2",
  BANKCONNECTIONS_EDIT_BANK_CONNECTION: "BANKCONNECTIONS_EDIT_BANK_CONNECTION",
  BANKCONNECTIONS_EDIT_BANK_CONNECTION_FROM_TPP_WEB_FORM:
    "BANKCONNECTIONS_EDIT_BANK_CONNECTION_FROM_TPP_WEB_FORM",
  BANKCONNECTIONS_EDIT_BANK_CONNECTION_FROM_TPP_WEB_FORM_V2:
    "BANKCONNECTIONS_EDIT_BANK_CONNECTION_FROM_TPP_WEB_FORM_V2",
  BANKCONNECTIONS_EDIT_BANK_CONNECTION_V2:
    "BANKCONNECTIONS_EDIT_BANK_CONNECTION_V2",
  BANKCONNECTIONS_GET_BANK_CONNECTION: "BANKCONNECTIONS_GET_BANK_CONNECTION",
  BANKCONNECTIONS_GET_BANK_CONNECTION_V2:
    "BANKCONNECTIONS_GET_BANK_CONNECTION_V2",
  BANKCONNECTIONS_GET_USER_BANK_CONNECTION_EVENTS:
    "BANKCONNECTIONS_GET_USER_BANK_CONNECTION_EVENTS",
  BANKCONNECTIONS_ID_ADD_OR_UPDATE_BANK_CONNECTION_RESULT:
    "BANKCONNECTIONS_ID_ADD_OR_UPDATE_BANK_CONNECTION_RESULT",
  BANKCONNECTIONS_ID_ADD_OR_UPDATE_BANK_CONNECTION_RESULT_V2:
    "BANKCONNECTIONS_ID_ADD_OR_UPDATE_BANK_CONNECTION_RESULT_V2",
  BANKCONNECTIONS_LIST_AND_SEARCH_ALL_BANK_CONNECTIONS:
    "BANKCONNECTIONS_LIST_AND_SEARCH_ALL_BANK_CONNECTIONS",
  BANKCONNECTIONS_LIST_AND_SEARCH_ALL_BANK_CONNECTIONS_V2:
    "BANKCONNECTIONS_LIST_AND_SEARCH_ALL_BANK_CONNECTIONS_V2",
  BANKCONNECTIONS_UPDATE_BANK_CONNECTION:
    "BANKCONNECTIONS_UPDATE_BANK_CONNECTION",
  BANKCONNECTIONS_UPDATE_BANK_CONNECTION_V2:
    "BANKCONNECTIONS_UPDATE_BANK_CONNECTION_V2",
  BANKCONNECTIONS_WEB_FORM_FLOW_ADD_OR_UPDATE_BANK_CONNECTION:
    "BANKCONNECTIONS_WEB_FORM_FLOW_ADD_OR_UPDATE_BANK_CONNECTION",
  BANKCONNECTIONS_WEB_FORM_FLOW_ADD_OR_UPDATE_BANK_CONNECTION_V2:
    "BANKCONNECTIONS_WEB_FORM_FLOW_ADD_OR_UPDATE_BANK_CONNECTION_V2",
  BANKCONNECTIONS_WEB_FORM_FLOW_BANK_CONNECTION_UPDATE_PROCESS_V2:
    "BANKCONNECTIONS_WEB_FORM_FLOW_BANK_CONNECTION_UPDATE_PROCESS_V2",
  BANKCONNECTIONS_WEB_FORM_FLOW_GET_IMPORT_OR_UPDATE_BANK_CONNECTION_RESULT:
    "BANKCONNECTIONS_WEB_FORM_FLOW_GET_IMPORT_OR_UPDATE_BANK_CONNECTION_RESULT",
  BANKCONNECTIONS_WEB_FORM_FLOW_GET_IMPORT_OR_UPDATE_BANK_CONNECTION_RESULT_V2:
    "BANKCONNECTIONS_WEB_FORM_FLOW_GET_IMPORT_OR_UPDATE_BANK_CONNECTION_RESULT_V2",
  BANKS_BANK_ICON: "BANKS_BANK_ICON",
  BANKS_BANKS: "BANKS_BANKS",
  BANKS_BANKS_ID: "BANKS_BANKS_ID",
  BANKS_CATALOGUE: "BANKS_CATALOGUE",
  BANKS_CREATE_INTERFACE_SELECTION_OPTION:
    "BANKS_CREATE_INTERFACE_SELECTION_OPTION",
  BANKS_DELETE_BANK_INTERFACE_SELECTION_OPTION:
    "BANKS_DELETE_BANK_INTERFACE_SELECTION_OPTION",
  BANKS_INTERFACE_SELECTION_OPTION: "BANKS_INTERFACE_SELECTION_OPTION",
  BANKS_INTERFACE_SELECTION_OPTIONS: "BANKS_INTERFACE_SELECTION_OPTIONS",
  BANKS_UPDATE_INTERFACE_SELECTION_OPTION:
    "BANKS_UPDATE_INTERFACE_SELECTION_OPTION",
  BILLINGCYCLES_BILLING_CYCLE_PAYOUT_STATISTICS:
    "BILLINGCYCLES_BILLING_CYCLE_PAYOUT_STATISTICS",
  BILLINGCYCLES_BILLING_CYCLES_ID: "BILLINGCYCLES_BILLING_CYCLES_ID",
  BILLINGCYCLES_BILLING_CYCLES_ID_INVOICES:
    "BILLINGCYCLES_BILLING_CYCLES_ID_INVOICES",
  BILLINGCYCLES_CREATE_BILLING_CYCLE: "BILLINGCYCLES_CREATE_BILLING_CYCLE",
  BILLINGCYCLES_DELETE_BILLING_CYCLE: "BILLINGCYCLES_DELETE_BILLING_CYCLE",
  BILLINGCYCLES_GET_INVOICES: "BILLINGCYCLES_GET_INVOICES",
  BILLINGCYCLES_GET_TAXATION_ESTIMATE: "BILLINGCYCLES_GET_TAXATION_ESTIMATE",
  BILLINGCYCLES_LIST_AND_SEARCH_ALL_BILLING_CYCLES:
    "BILLINGCYCLES_LIST_AND_SEARCH_ALL_BILLING_CYCLES",
  BILLINGCYCLES_UPDATE_TAXATION_CATEGORY:
    "BILLINGCYCLES_UPDATE_TAXATION_CATEGORY",
  BOOKINGS_CARVE_OUT: "BOOKINGS_CARVE_OUT",
  BOOKINGS_CHECK_CONSISTENCY_STATUS: "BOOKINGS_CHECK_CONSISTENCY_STATUS",
  BOOKINGS_CHECK_CONSISTENCY_STATUS_ID: "BOOKINGS_CHECK_CONSISTENCY_STATUS_ID",
  BOOKINGS_CONSOLIDATE_NEGATIVE_NOL: "BOOKINGS_CONSOLIDATE_NEGATIVE_NOL",
  BOOKINGS_CREATE_BOOKINGS: "BOOKINGS_CREATE_BOOKINGS",
  BOOKINGS_CREATE_MANAGED_DEPOT_BOOKING:
    "BOOKINGS_CREATE_MANAGED_DEPOT_BOOKING",
  BOOKINGS_DELETE_BOOKING: "BOOKINGS_DELETE_BOOKING",
  BOOKINGS_DELETE_BOOKING_BY_EXTERNAL_ID:
    "BOOKINGS_DELETE_BOOKING_BY_EXTERNAL_ID",
  BOOKINGS_DELETE_SPLIT_EXECUTION: "BOOKINGS_DELETE_SPLIT_EXECUTION",
  BOOKINGS_ESTIMATE_IMPLICIT_NUMBER_OF_LOTS:
    "BOOKINGS_ESTIMATE_IMPLICIT_NUMBER_OF_LOTS",
  BOOKINGS_LIST_AND_SEARCH_ALL_BOOKINGS:
    "BOOKINGS_LIST_AND_SEARCH_ALL_BOOKINGS",
  BOOKINGS_PORTFOLIO_POSITIONS_ID: "BOOKINGS_PORTFOLIO_POSITIONS_ID",
  BOOKINGS_PUBLIC_LIST_AND_SEARCH_ALL_BOOKINGS:
    "BOOKINGS_PUBLIC_LIST_AND_SEARCH_ALL_BOOKINGS",
  BOOKINGS_PUBLIC_PORTFOLIO_POSITIONS_ID:
    "BOOKINGS_PUBLIC_PORTFOLIO_POSITIONS_ID",
  BOOKINGS_REMOTE_CHANGES: "BOOKINGS_REMOTE_CHANGES",
  BOOKINGS_SPLIT: "BOOKINGS_SPLIT",
  BOOKINGS_SWITCH_INVESTMENT: "BOOKINGS_SWITCH_INVESTMENT",
  BOOKINGS_UPDATE_MANAGED_DEPOT_BOOKING:
    "BOOKINGS_UPDATE_MANAGED_DEPOT_BOOKING",
  BOOKINGS_UPDATE_TRANSACTION: "BOOKINGS_UPDATE_TRANSACTION",
  BOOKINGS_VALIATE_CARVE_OUT: "BOOKINGS_VALIATE_CARVE_OUT",
  BOOKINGS_VALIDATE_MANAGED_DEPOT_BOOKING:
    "BOOKINGS_VALIDATE_MANAGED_DEPOT_BOOKING",
  BOOKINGS_VALIDATE_SWITCH_INVESTMENT: "BOOKINGS_VALIDATE_SWITCH_INVESTMENT",
  CASHFLOWANALYTICS_CASH_FLOW_ANALYTICS:
    "CASHFLOWANALYTICS_CASH_FLOW_ANALYTICS",
  CASHFLOWANALYTICS_PREDICT_CASH_FLOWS: "CASHFLOWANALYTICS_PREDICT_CASH_FLOWS",
  CASHBACKINCIDENTS_CREATE_CASHBACK_INCIDENT:
    "CASHBACKINCIDENTS_CREATE_CASHBACK_INCIDENT",
  CASHBACKINCIDENTS_GET_CASHBACK_INCIDENT:
    "CASHBACKINCIDENTS_GET_CASHBACK_INCIDENT",
  CASHBACKINCIDENTS_LIST_AND_SEARCH_ALL_CASHBACK_INCIDENTS:
    "CASHBACKINCIDENTS_LIST_AND_SEARCH_ALL_CASHBACK_INCIDENTS",
  CASHBACKINCIDENTS_UPDATE_CASHBACK_INCIDENT:
    "CASHBACKINCIDENTS_UPDATE_CASHBACK_INCIDENT",
  CATEGORIES_CATEGORIES_ID: "CATEGORIES_CATEGORIES_ID",
  CATEGORIES_CREATE_TRANSACTION_CATEGORY:
    "CATEGORIES_CREATE_TRANSACTION_CATEGORY",
  CATEGORIES_DELETE_CATEGORY: "CATEGORIES_DELETE_CATEGORY",
  CATEGORIES_LIST_AND_SEARCH_ALL_CATEGORIES:
    "CATEGORIES_LIST_AND_SEARCH_ALL_CATEGORIES",
  CLASSIFICATIONS_LIST_CURRENCY_CLASSIFICATIONS_FOR_ALL_INVESTMENTS:
    "CLASSIFICATIONS_LIST_CURRENCY_CLASSIFICATIONS_FOR_ALL_INVESTMENTS",
  CLASSIFICATIONS_LIST_INDUSTRY_CLASSIFICATIONS_FOR_ALL_INVESTMENTS:
    "CLASSIFICATIONS_LIST_INDUSTRY_CLASSIFICATIONS_FOR_ALL_INVESTMENTS",
  CLASSIFICATIONS_LIST_REGION_CLASSIFICATIONS_FOR_ALL_INVESTMENTS:
    "CLASSIFICATIONS_LIST_REGION_CLASSIFICATIONS_FOR_ALL_INVESTMENTS",
  CLASSIFICATIONS_LIST_TYPE_CLASSIFICATIONS_FOR_ALL_INVESTMENTS:
    "CLASSIFICATIONS_LIST_TYPE_CLASSIFICATIONS_FOR_ALL_INVESTMENTS",
  CLASSIFICATIONS_PUBLIC_LIST_CURRENCY_CLASSIFICATIONS_FOR_ALL_INVESTMENTS:
    "CLASSIFICATIONS_PUBLIC_LIST_CURRENCY_CLASSIFICATIONS_FOR_ALL_INVESTMENTS",
  CLASSIFICATIONS_PUBLIC_LIST_INDUSTRY_CLASSIFICATIONS_FOR_ALL_INVESTMENTS:
    "CLASSIFICATIONS_PUBLIC_LIST_INDUSTRY_CLASSIFICATIONS_FOR_ALL_INVESTMENTS",
  CLASSIFICATIONS_PUBLIC_LIST_REGION_CLASSIFICATIONS_FOR_ALL_INVESTMENTS:
    "CLASSIFICATIONS_PUBLIC_LIST_REGION_CLASSIFICATIONS_FOR_ALL_INVESTMENTS",
  CLASSIFICATIONS_PUBLIC_LIST_TYPE_CLASSIFICATIONS_FOR_ALL_INVESTMENTS:
    "CLASSIFICATIONS_PUBLIC_LIST_TYPE_CLASSIFICATIONS_FOR_ALL_INVESTMENTS",
  COMMENTS_CREATE_COMMENT: "COMMENTS_CREATE_COMMENT",
  COMMENTS_LIST_AND_SEARCH_ALL_COMMENTS:
    "COMMENTS_LIST_AND_SEARCH_ALL_COMMENTS",
  COMMENTS_SHOW_COMMENT: "COMMENTS_SHOW_COMMENT",
  CRYPTOCURRENCIES_ADD_CRYPTO_CURRENCY: "CRYPTOCURRENCIES_ADD_CRYPTO_CURRENCY",
  CRYPTOCURRENCIES_CRYPTO_CURRENCIES_REFRESH:
    "CRYPTOCURRENCIES_CRYPTO_CURRENCIES_REFRESH",
  CURRENCIES_V1_GET_CURRENCY: "CURRENCIES_V1_GET_CURRENCY",
  CURRENCIES_V1_LIST_CURRENCIES: "CURRENCIES_V1_LIST_CURRENCIES",
  DEPOTSYNCHRONIZATIONLOGS_FIND_AND_SEARCH_DEPOT_SYNCHRONIZATION_LOGS:
    "DEPOTSYNCHRONIZATIONLOGS_FIND_AND_SEARCH_DEPOT_SYNCHRONIZATION_LOGS",
  DEPOTSYNCHRONIZATIONLOGS_ROLLBACK_DEPOT_SYNCHRONIZATION_LOG:
    "DEPOTSYNCHRONIZATIONLOGS_ROLLBACK_DEPOT_SYNCHRONIZATION_LOG",
  DEPOTSYNCHRONIZATIONLOGS_SHOW_DEPOT_SYNCHRONIZATION_LOG:
    "DEPOTSYNCHRONIZATIONLOGS_SHOW_DEPOT_SYNCHRONIZATION_LOG",
  DISCOUNTS_CREATE_DISCOUNT_POSITION: "DISCOUNTS_CREATE_DISCOUNT_POSITION",
  DISCOUNTS_LIST_AND_SEARCH_ALL_DISCOUNTS:
    "DISCOUNTS_LIST_AND_SEARCH_ALL_DISCOUNTS",
  DISCOUNTS_RECOMMENDATION_CODE: "DISCOUNTS_RECOMMENDATION_CODE",
  DIVIDENDMASTERDATARECORDS_CLONE_DIVIDENDS:
    "DIVIDENDMASTERDATARECORDS_CLONE_DIVIDENDS",
  DIVIDENDMASTERDATARECORDS_CREATE_DIVIDEND:
    "DIVIDENDMASTERDATARECORDS_CREATE_DIVIDEND",
  DIVIDENDMASTERDATARECORDS_DELETE_DIVIDEND_ID:
    "DIVIDENDMASTERDATARECORDS_DELETE_DIVIDEND_ID",
  DIVIDENDMASTERDATARECORDS_GET_DIVIDEND_ID:
    "DIVIDENDMASTERDATARECORDS_GET_DIVIDEND_ID",
  DIVIDENDMASTERDATARECORDS_LIST_AND_SEARCH_ALL_DIVIDEND_MASTER_DATA_RECORDS:
    "DIVIDENDMASTERDATARECORDS_LIST_AND_SEARCH_ALL_DIVIDEND_MASTER_DATA_RECORDS",
  DIVIDENDMASTERDATARECORDS_REFRESH_DIVIDEND_CATALOGUE:
    "DIVIDENDMASTERDATARECORDS_REFRESH_DIVIDEND_CATALOGUE",
  DIVIDENDMASTERDATARECORDS_REFRESH_DIVIDEND_PREDICTIONS:
    "DIVIDENDMASTERDATARECORDS_REFRESH_DIVIDEND_PREDICTIONS",
  DIVIDENDMASTERDATARECORDS_UPDATE_DIVIDEND_ID:
    "DIVIDENDMASTERDATARECORDS_UPDATE_DIVIDEND_ID",
  DIVIDENDS_DIVIDEND_ALLOCATION: "DIVIDENDS_DIVIDEND_ALLOCATION",
  DIVIDENDS_DIVIDEND_CALENDAR: "DIVIDENDS_DIVIDEND_CALENDAR",
  DIVIDENDS_DIVIDEND_HISTORY: "DIVIDENDS_DIVIDEND_HISTORY",
  DIVIDENDS_DIVIDEND_PREDICTION: "DIVIDENDS_DIVIDEND_PREDICTION",
  DIVIDENDS_DIVIDEND_TIMELINE: "DIVIDENDS_DIVIDEND_TIMELINE",
  DIVIDENDS_DIVIDEND_YIELD: "DIVIDENDS_DIVIDEND_YIELD",
  DIVIDENDS_GET_DIVIDENDS: "DIVIDENDS_GET_DIVIDENDS",
  DIVIDENDS_GET_DIVIDENDS_PER_YEAR: "DIVIDENDS_GET_DIVIDENDS_PER_YEAR",
  DIVIDENDS_LIST_AND_SEARCH_ALL_DIVIDENDS:
    "DIVIDENDS_LIST_AND_SEARCH_ALL_DIVIDENDS",
  DIVIDENDS_PUBLIC_DIVIDEND_ALLOCATION: "DIVIDENDS_PUBLIC_DIVIDEND_ALLOCATION",
  DIVIDENDS_PUBLIC_DIVIDEND_CALENDAR: "DIVIDENDS_PUBLIC_DIVIDEND_CALENDAR",
  DIVIDENDS_PUBLIC_DIVIDEND_HISTORY: "DIVIDENDS_PUBLIC_DIVIDEND_HISTORY",
  DIVIDENDS_PUBLIC_DIVIDEND_PREDICTION: "DIVIDENDS_PUBLIC_DIVIDEND_PREDICTION",
  DIVIDENDS_PUBLIC_DIVIDEND_TIMELINE: "DIVIDENDS_PUBLIC_DIVIDEND_TIMELINE",
  DIVIDENDS_PUBLIC_DIVIDEND_YIELD: "DIVIDENDS_PUBLIC_DIVIDEND_YIELD",
  EXCHANGERATES_EXCHANGE_RATES: "EXCHANGERATES_EXCHANGE_RATES",
  FINCLIENTCONFIGURATIONAPI_GET_FIN_API_CLIENT_CONFIGURATION:
    "FINCLIENTCONFIGURATIONAPI_GET_FIN_API_CLIENT_CONFIGURATION",
  FINCLIENTCONFIGURATIONAPI_UPDATE_FIN_API_CLIENT_CONFIGURATION:
    "FINCLIENTCONFIGURATIONAPI_UPDATE_FIN_API_CLIENT_CONFIGURATION",
  FINANCIALGOALS_CREATE_FINANCIAL_GOAL: "FINANCIALGOALS_CREATE_FINANCIAL_GOAL",
  FINANCIALGOALS_FINANCIAL_GOALS_ID: "FINANCIALGOALS_FINANCIAL_GOALS_ID",
  FINANCIALGOALS_LIST_AND_SEARCH_ALL_FINANCIAL_GOALS:
    "FINANCIALGOALS_LIST_AND_SEARCH_ALL_FINANCIAL_GOALS",
  FUNDAMENTALS_FUNDAMENTALS_FULL_REFRESH:
    "FUNDAMENTALS_FUNDAMENTALS_FULL_REFRESH",
  FUNDAMENTALS_FUNDAMENTALS_ID: "FUNDAMENTALS_FUNDAMENTALS_ID",
  FUNDAMENTALS_FUNDAMENTALS_ID_FINANCIALS:
    "FUNDAMENTALS_FUNDAMENTALS_ID_FINANCIALS",
  FUNDAMENTALS_FUNDAMENTALS_ID_MARKET_CAPITALIZATION:
    "FUNDAMENTALS_FUNDAMENTALS_ID_MARKET_CAPITALIZATION",
  FUNDAMENTALS_FUNDAMENTALS_ID_REFRESH: "FUNDAMENTALS_FUNDAMENTALS_ID_REFRESH",
  FUNDAMENTALS_FUNDAMENTALS_ID_STATISTICS:
    "FUNDAMENTALS_FUNDAMENTALS_ID_STATISTICS",
  FUNDS_LIST_AND_SEARCH_ALL_FUNDS: "FUNDS_LIST_AND_SEARCH_ALL_FUNDS",
  FUNDS_MOST_POPULAR: "FUNDS_MOST_POPULAR",
  FUNDS_RUN_FWW_IMPORT1: "FUNDS_RUN_FWW_IMPORT1",
  FUNDS_SHOW_FUND: "FUNDS_SHOW_FUND",
  FUNDS_SHOW_FUND_BY_ISIN: "FUNDS_SHOW_FUND_BY_ISIN",
  HISTORICQUOTES_ADD_OR_UPDATE_HISTORIC_QUOTES:
    "HISTORICQUOTES_ADD_OR_UPDATE_HISTORIC_QUOTES",
  HISTORICQUOTES_ADD_OR_UPDATE_PRODUCT_QUOTES:
    "HISTORICQUOTES_ADD_OR_UPDATE_PRODUCT_QUOTES",
  HISTORICQUOTES_HISTORIC_QUOTES: "HISTORICQUOTES_HISTORIC_QUOTES",
  HISTORICQUOTES_INTRADAY: "HISTORICQUOTES_INTRADAY",
  HISTORICQUOTES_LOOKUP: "HISTORICQUOTES_LOOKUP",
  HISTORICQUOTES_REFRESH_HISTORIC_QUOTE:
    "HISTORICQUOTES_REFRESH_HISTORIC_QUOTE",
  HISTORICQUOTES_REFRESH_HISTORIC_QUOTES_BY_ISIN:
    "HISTORICQUOTES_REFRESH_HISTORIC_QUOTES_BY_ISIN",
  HISTORICQUOTES_SHOW_HISTORIC_QUOTE: "HISTORICQUOTES_SHOW_HISTORIC_QUOTE",
  INDUSTRIES_INDUSTRIES_ID: "INDUSTRIES_INDUSTRIES_ID",
  INDUSTRIES_LIST_AND_SEARCH_ALL_INDUSTRIES:
    "INDUSTRIES_LIST_AND_SEARCH_ALL_INDUSTRIES",
  INFLATIONRATES_GET_INFLATION_RATES: "INFLATIONRATES_GET_INFLATION_RATES",
  INSURANCECATEGORIES_INSURANCE_CATEGORIES_ID:
    "INSURANCECATEGORIES_INSURANCE_CATEGORIES_ID",
  INSURANCECATEGORIES_LIST_AND_SEARCH_ALL_INSURANCE_CATEGORIES:
    "INSURANCECATEGORIES_LIST_AND_SEARCH_ALL_INSURANCE_CATEGORIES",
  INSURANCEPROVIDERSPECIFICCONTRACTIDPATTERNS_CREATE_INSURANCE_PROVIDER_SPECIFIC_CONTRACT_ID_PATTERN:
    "INSURANCEPROVIDERSPECIFICCONTRACTIDPATTERNS_CREATE_INSURANCE_PROVIDER_SPECIFIC_CONTRACT_ID_PATTERN",
  INSURANCEPROVIDERSPECIFICCONTRACTIDPATTERNS_DELETE_INSURANCE_PROVIDER_SPECIFIC_CONTRACT_ID_PATTERN:
    "INSURANCEPROVIDERSPECIFICCONTRACTIDPATTERNS_DELETE_INSURANCE_PROVIDER_SPECIFIC_CONTRACT_ID_PATTERN",
  INSURANCEPROVIDERS_CREATE_INSURANCE_PROVIDER:
    "INSURANCEPROVIDERS_CREATE_INSURANCE_PROVIDER",
  INSURANCEPROVIDERS_DELETE_INSURANCE_PROVIDER:
    "INSURANCEPROVIDERS_DELETE_INSURANCE_PROVIDER",
  INSURANCES_BATCH_UPDATE_INSURANCES: "INSURANCES_BATCH_UPDATE_INSURANCES",
  INSURANCES_CREATE_INSURANCE: "INSURANCES_CREATE_INSURANCE",
  INSURANCES_DELETE_INSURANCE: "INSURANCES_DELETE_INSURANCE",
  INSURANCES_INSURANCES_ID: "INSURANCES_INSURANCES_ID",
  INSURANCES_LIST_AND_SEARCH_ALL_INSURANCES:
    "INSURANCES_LIST_AND_SEARCH_ALL_INSURANCES",
  INSURANCES_MERGE_INSURANCES: "INSURANCES_MERGE_INSURANCES",
  INSURANCES_UPDATE_INSURANCE: "INSURANCES_UPDATE_INSURANCE",
  INTERNALTRANSACTIONS_GET_BATCH_TRANSFER_XML:
    "INTERNALTRANSACTIONS_GET_BATCH_TRANSFER_XML",
  INTERNALTRANSACTIONS_INTERNAL_TRANSACTIONS_ID:
    "INTERNALTRANSACTIONS_INTERNAL_TRANSACTIONS_ID",
  INTERNALTRANSACTIONS_LIST_AND_SEARCH_ALL_INTERNAL_TRANSACTIONS:
    "INTERNALTRANSACTIONS_LIST_AND_SEARCH_ALL_INTERNAL_TRANSACTIONS",
  INTERNALTRANSACTIONS_MARK_BATCH_TRANSFER_AS_EXECUTED:
    "INTERNALTRANSACTIONS_MARK_BATCH_TRANSFER_AS_EXECUTED",
  INTERNALTRANSACTIONS_UPDATE_INTERNAL_TRANSACTION:
    "INTERNALTRANSACTIONS_UPDATE_INTERNAL_TRANSACTION",
  INVESTMENTS_BATCH_INVESTMENT_PERFORMANCE:
    "INVESTMENTS_BATCH_INVESTMENT_PERFORMANCE",
  INVESTMENTS_BATCH_PUBLIC_INVESTMENT_PERFORMANCE:
    "INVESTMENTS_BATCH_PUBLIC_INVESTMENT_PERFORMANCE",
  INVESTMENTS_DELETE_INVESTMENT: "INVESTMENTS_DELETE_INVESTMENT",
  INVESTMENTS_EDIT_INVESTMENT: "INVESTMENTS_EDIT_INVESTMENT",
  INVESTMENTS_GAINERS_AND_LOSERS: "INVESTMENTS_GAINERS_AND_LOSERS",
  INVESTMENTS_GET_PRIVATE_NOTES: "INVESTMENTS_GET_PRIVATE_NOTES",
  INVESTMENTS_INVESTMENT_ID_TRANSFER: "INVESTMENTS_INVESTMENT_ID_TRANSFER",
  INVESTMENTS_INVESTMENT_TRANSFER: "INVESTMENTS_INVESTMENT_TRANSFER",
  INVESTMENTS_INVESTMENTS_ID: "INVESTMENTS_INVESTMENTS_ID",
  INVESTMENTS_LIST_AND_SEARCH_ALL_INVESTMENTS:
    "INVESTMENTS_LIST_AND_SEARCH_ALL_INVESTMENTS",
  INVESTMENTS_LIST_AND_SEARCH_ALL_PUBLIC_INVESTMENTS:
    "INVESTMENTS_LIST_AND_SEARCH_ALL_PUBLIC_INVESTMENTS",
  INVESTMENTS_PUBLIC_GAINERS_AND_LOSERS:
    "INVESTMENTS_PUBLIC_GAINERS_AND_LOSERS",
  INVESTMENTS_REGION_AND_INDUSTRY_CLASSIFICATION:
    "INVESTMENTS_REGION_AND_INDUSTRY_CLASSIFICATION",
  INVESTMENTS_SHOW_PUBLIC_INVESTMENT: "INVESTMENTS_SHOW_PUBLIC_INVESTMENT",
  INVESTMENTS_UPDATE_PRIVATE_NOTES: "INVESTMENTS_UPDATE_PRIVATE_NOTES",
  MAILING_CONTACT_US: "MAILING_CONTACT_US",
  MAILING_CONTACT_USER_ID: "MAILING_CONTACT_USER_ID",
  MAILING_RECOMMEND_CASHBACK: "MAILING_RECOMMEND_CASHBACK",
  MAILING_RECOMMEND_US: "MAILING_RECOMMEND_US",
  MANDATORCONFIGURATIONS_GET_MANDATOR_CONFIGURATION:
    "MANDATORCONFIGURATIONS_GET_MANDATOR_CONFIGURATION",
  MANDATORCONFIGURATIONS_UPDATE_MANDATOR_CONFIGURATION:
    "MANDATORCONFIGURATIONS_UPDATE_MANDATOR_CONFIGURATION",
  MANDATORINVOICES_MANDATORS_ID_INVOICING:
    "MANDATORINVOICES_MANDATORS_ID_INVOICING",
  MANDATORS_CREATE_MANDATOR: "MANDATORS_CREATE_MANDATOR",
  MANDATORS_LIST_MANDATORS: "MANDATORS_LIST_MANDATORS",
  MANDATORS_MANDATORS_ID: "MANDATORS_MANDATORS_ID",
  MANDATORS_UPDATE_MANDATOR: "MANDATORS_UPDATE_MANDATOR",
  MASTERDATA_AUTO_CURATE_FUND_CLASSIFICATION:
    "MASTERDATA_AUTO_CURATE_FUND_CLASSIFICATION",
  MASTERDATA_GET_MASTER_DATA: "MASTERDATA_GET_MASTER_DATA",
  MASTERDATA_MASTER_DATA_CATALOGUE: "MASTERDATA_MASTER_DATA_CATALOGUE",
  MASTERDATA_V2_GET_MASTER_DATA: "MASTERDATA_V2_GET_MASTER_DATA",
  NATURALPERSONS_UPDATE_MULTIPLE_NATURAL_PERSONS:
    "NATURALPERSONS_UPDATE_MULTIPLE_NATURAL_PERSONS",
  NATURALPERSONS_UPDATE_NATURAL_PERSON: "NATURALPERSONS_UPDATE_NATURAL_PERSON",
  NETFONDSCUSTOMERS_NETFONDS_CUSTOMERS: "NETFONDSCUSTOMERS_NETFONDS_CUSTOMERS",
  NEWSFEEDS_INVESTMENT_NEWS: "NEWSFEEDS_INVESTMENT_NEWS",
  NEWSFEEDS_NEWS_BY_ISIN: "NEWSFEEDS_NEWS_BY_ISIN",
  NEWSFEEDS_RENTABLO_BLOG_FEED: "NEWSFEEDS_RENTABLO_BLOG_FEED",
  OAUTH_ACCESS_TOKEN: "OAUTH_ACCESS_TOKEN",
  OAUTH_LOGIN: "OAUTH_LOGIN",
  OAUTH_REVOKE_TOKEN: "OAUTH_REVOKE_TOKEN",
  PERFORMANCE_ABSOLUTE_RETURN: "PERFORMANCE_ABSOLUTE_RETURN",
  PERFORMANCE_ABSOLUTE_RETURN_BY_POSITION:
    "PERFORMANCE_ABSOLUTE_RETURN_BY_POSITION",
  PERFORMANCE_ESTIMATE_TRADING_GAIN_FOR_NEW_TRANSACTION:
    "PERFORMANCE_ESTIMATE_TRADING_GAIN_FOR_NEW_TRANSACTION",
  PERFORMANCE_INVESTMENT_PERFORMANCE: "PERFORMANCE_INVESTMENT_PERFORMANCE",
  PERFORMANCE_PERFORMANCE_BY_DEPOTS: "PERFORMANCE_PERFORMANCE_BY_DEPOTS",
  PERFORMANCE_PERFORMANCE_HISTOGRAM: "PERFORMANCE_PERFORMANCE_HISTOGRAM",
  PERFORMANCE_PERFORMANCE_INTERVALS: "PERFORMANCE_PERFORMANCE_INTERVALS",
  PERFORMANCE_PERFORMANCE_TIME_POINTS: "PERFORMANCE_PERFORMANCE_TIME_POINTS",
  PERFORMANCE_PORTFOLIO: "PERFORMANCE_PORTFOLIO",
  PERFORMANCE_PORTFOLIO_PERFORMANCE: "PERFORMANCE_PORTFOLIO_PERFORMANCE",
  PERFORMANCE_PUBLIC_PORTFOLIO_PERFORMANCE:
    "PERFORMANCE_PUBLIC_PORTFOLIO_PERFORMANCE",
  PERFORMANCE_REALIZED_GAINS_PER_YEAR: "PERFORMANCE_REALIZED_GAINS_PER_YEAR",
  PERFORMANCE_RISK_YIELD_STATISTICS: "PERFORMANCE_RISK_YIELD_STATISTICS",
  PERFORMANCE_TRADING_GAIN_FOR_INVESTMENT:
    "PERFORMANCE_TRADING_GAIN_FOR_INVESTMENT",
  PORTFOLIOOPERATIONS_CLONE_PORTFOLIO: "PORTFOLIOOPERATIONS_CLONE_PORTFOLIO",
  PORTFOLIOOPERATIONS_MERGE_PORTFOLIOS: "PORTFOLIOOPERATIONS_MERGE_PORTFOLIOS",
  PUBLICACCOUNTS_LIST_PUBLIC_ACCOUNTS: "PUBLICACCOUNTS_LIST_PUBLIC_ACCOUNTS",
  QUALITYASSURANCE_CONFIGURE_MOCK_BANKING_API:
    "QUALITYASSURANCE_CONFIGURE_MOCK_BANKING_API",
  QUALITYASSURANCE_CONFIRM_USER_QA: "QUALITYASSURANCE_CONFIRM_USER_QA",
  QUALITYASSURANCE_CREATE_RANDOM_USER: "QUALITYASSURANCE_CREATE_RANDOM_USER",
  QUALITYASSURANCE_CREATE_RANDOM_VOUCHER:
    "QUALITYASSURANCE_CREATE_RANDOM_VOUCHER",
  QUALITYASSURANCE_DELETE_ACCOUNTS_FOR_MOCK_BANK_CONNECTION:
    "QUALITYASSURANCE_DELETE_ACCOUNTS_FOR_MOCK_BANK_CONNECTION",
  QUALITYASSURANCE_EMAIL_CHANGE_TOKEN: "QUALITYASSURANCE_EMAIL_CHANGE_TOKEN",
  QUOTES_GET_QUOTE: "QUOTES_GET_QUOTE",
  QUOTES_GET_QUOTES: "QUOTES_GET_QUOTES",
  QUOTES_GET_QUOTES_V2: "QUOTES_GET_QUOTES_V2",
  REGIONS_LIST_AND_SEARCH_ALL_REGIONS: "REGIONS_LIST_AND_SEARCH_ALL_REGIONS",
  REGIONS_REGIONS_ID: "REGIONS_REGIONS_ID",
  REMOTEDEPOTS_GET_REMOTE_SECURITIES: "REMOTEDEPOTS_GET_REMOTE_SECURITIES",
  REMOTEDEPOTS_IMPORT_DEPOT: "REMOTEDEPOTS_IMPORT_DEPOT",
  REMOTEDEPOTS_IMPORT_REMOTE_DEPOT: "REMOTEDEPOTS_IMPORT_REMOTE_DEPOT",
  REMOTEDEPOTS_IMPORT_REMOTE_DEPOT_VIA_TPP_WEB_FORM:
    "REMOTEDEPOTS_IMPORT_REMOTE_DEPOT_VIA_TPP_WEB_FORM",
  REMOTEDEPOTS_SAAS_UPDATE_REMOTE_DEPOT:
    "REMOTEDEPOTS_SAAS_UPDATE_REMOTE_DEPOT",
  REMOTEDEPOTS_UPDATE_REMOTE_DEPOT: "REMOTEDEPOTS_UPDATE_REMOTE_DEPOT",
  REMOTEDEPOTS_UPDATE_REMOTE_DEPOT_VIA_TPP_WEB_FORM:
    "REMOTEDEPOTS_UPDATE_REMOTE_DEPOT_VIA_TPP_WEB_FORM",
  RESERVATIONS_CREATE_RESERVATION: "RESERVATIONS_CREATE_RESERVATION",
  RETIREMENTCALCULATOR_PENSION_GAP: "RETIREMENTCALCULATOR_PENSION_GAP",
  RISKYIELDMETRICS_BATCH_RISK_YIELD_METRICS:
    "RISKYIELDMETRICS_BATCH_RISK_YIELD_METRICS",
  RISKYIELDMETRICS_CORRELATION_TO_REFERENCE:
    "RISKYIELDMETRICS_CORRELATION_TO_REFERENCE",
  RISKYIELDMETRICS_PORTFOLIO_BACKTRACKING:
    "RISKYIELDMETRICS_PORTFOLIO_BACKTRACKING",
  RISKYIELDMETRICS_PORTFOLIO_CORRELATION:
    "RISKYIELDMETRICS_PORTFOLIO_CORRELATION",
  RISKYIELDMETRICS_PORTFOLIO_RISK_YIELD:
    "RISKYIELDMETRICS_PORTFOLIO_RISK_YIELD",
  RISKYIELDMETRICS_PUBLIC_BATCH_RISK_YIELD_METRICS:
    "RISKYIELDMETRICS_PUBLIC_BATCH_RISK_YIELD_METRICS",
  ROLES_ASSIGN_ROLE: "ROLES_ASSIGN_ROLE",
  ROLES_CREATE_ROLE: "ROLES_CREATE_ROLE",
  ROLES_DELETE_ROLE: "ROLES_DELETE_ROLE",
  ROLES_DELETE_ROLE_ASSIGNMENT: "ROLES_DELETE_ROLE_ASSIGNMENT",
  ROLES_LIST_AND_SEARCH_ALL_ROLES: "ROLES_LIST_AND_SEARCH_ALL_ROLES",
  ROLES_ROLES_ID: "ROLES_ROLES_ID",
  SECURITYCOMMISSIONS_AUTO_ASSIGN_MISSING_PROPERTIES:
    "SECURITYCOMMISSIONS_AUTO_ASSIGN_MISSING_PROPERTIES",
  SECURITYCOMMISSIONS_DELETE_COMMISSIONS:
    "SECURITYCOMMISSIONS_DELETE_COMMISSIONS",
  SECURITYCOMMISSIONS_DELETE_COMMISSIONS_FOR_FILENAME:
    "SECURITYCOMMISSIONS_DELETE_COMMISSIONS_FOR_FILENAME",
  SECURITYCOMMISSIONS_GET_SECURITY_COMMISSIONS:
    "SECURITYCOMMISSIONS_GET_SECURITY_COMMISSIONS",
  SECURITYCOMMISSIONS_LIST_AND_SEARCH_ALL_SECURITY_COMMISSIONS:
    "SECURITYCOMMISSIONS_LIST_AND_SEARCH_ALL_SECURITY_COMMISSIONS",
  SECURITYCOMMISSIONS_SECURITY_COMMISSIONS_SECURITY_COMMISSIONS_PAYED_IN_FOLLOWING_YEAR:
    "SECURITYCOMMISSIONS_SECURITY_COMMISSIONS_SECURITY_COMMISSIONS_PAYED_IN_FOLLOWING_YEAR",
  SECURITYCOMMISSIONS_SECURITY_COMMISSIONS_TAXATION_STATISTICS:
    "SECURITYCOMMISSIONS_SECURITY_COMMISSIONS_TAXATION_STATISTICS",
  SECURITYCOMMISSIONS_VALIDATE_SECURITY_COMMISSIONS:
    "SECURITYCOMMISSIONS_VALIDATE_SECURITY_COMMISSIONS",
  SHARINGPREFERENCES_SHOW_SHARING_PREFERENCES:
    "SHARINGPREFERENCES_SHOW_SHARING_PREFERENCES",
  SHARINGPREFERENCES_SHOW_SHARING_PREFERENCES_BY_PUBLIC_ID:
    "SHARINGPREFERENCES_SHOW_SHARING_PREFERENCES_BY_PUBLIC_ID",
  SHARINGPREFERENCES_UPSERT_SHARING_PREFERENCES:
    "SHARINGPREFERENCES_UPSERT_SHARING_PREFERENCES",
  SIGNUP_CREATE_USER: "SIGNUP_CREATE_USER",
  SOCIALSTATISTICS_BUY_SELL_RATIO: "SOCIALSTATISTICS_BUY_SELL_RATIO",
  SOCIALSTATISTICS_MOST_WANTED: "SOCIALSTATISTICS_MOST_WANTED",
  SPLITS_GET_SPLITS: "SPLITS_GET_SPLITS",
  STRIPE_STRIPE_CHECKOUT_SESSION: "STRIPE_STRIPE_CHECKOUT_SESSION",
  STRIPE_STRIPE_CREATE_CHECKOUT_SESSION:
    "STRIPE_STRIPE_CREATE_CHECKOUT_SESSION",
  STRIPE_STRIPE_CREATE_CUSTOMER_PORTAL_SESSION:
    "STRIPE_STRIPE_CREATE_CUSTOMER_PORTAL_SESSION",
  STRIPE_STRIPE_SETUP: "STRIPE_STRIPE_SETUP",
  STRIPE_STRIPE_WEBHOOKS: "STRIPE_STRIPE_WEBHOOKS",
  SUBSCRIPTIONANALYTICS_SUBSCRIPTIONS_BY_MONTH:
    "SUBSCRIPTIONANALYTICS_SUBSCRIPTIONS_BY_MONTH",
  SUBSCRIPTIONFORMS_GENERATE_EMPTY_SUBSCRIPTION_FORM:
    "SUBSCRIPTIONFORMS_GENERATE_EMPTY_SUBSCRIPTION_FORM",
  SUBSCRIPTIONS_CANCEL_AFFILIATE_TRACKING:
    "SUBSCRIPTIONS_CANCEL_AFFILIATE_TRACKING",
  SUBSCRIPTIONS_CREATE_SUBSCRIPTION: "SUBSCRIPTIONS_CREATE_SUBSCRIPTION",
  SUBSCRIPTIONS_GET_SUBSCRIPTION: "SUBSCRIPTIONS_GET_SUBSCRIPTION",
  SUBSCRIPTIONS_LIST_AND_SEARCH_ALL_SUBSCRIPTIONS:
    "SUBSCRIPTIONS_LIST_AND_SEARCH_ALL_SUBSCRIPTIONS",
  SUBSCRIPTIONS_UPDATE_SUBSCRIPTION: "SUBSCRIPTIONS_UPDATE_SUBSCRIPTION",
  SUBSCRIPTIONS_VALIDATE_DISCOUNT_CODE: "SUBSCRIPTIONS_VALIDATE_DISCOUNT_CODE",
  SUBSCRIPTIONS_VALIDATE_PRIMARY_ACCOUNT_IDENTIFIER:
    "SUBSCRIPTIONS_VALIDATE_PRIMARY_ACCOUNT_IDENTIFIER",
  SYMBOLS_ASSIGN_PREFERRED_QUOTE: "SYMBOLS_ASSIGN_PREFERRED_QUOTE",
  SYMBOLS_FIND_BY_ISIN_OR_WKN: "SYMBOLS_FIND_BY_ISIN_OR_WKN",
  SYMBOLS_GET_BY_QUOTE_PROVIDER_AND_TICKER:
    "SYMBOLS_GET_BY_QUOTE_PROVIDER_AND_TICKER",
  SYMBOLS_SEARCH_SYMBOL: "SYMBOLS_SEARCH_SYMBOL",
  SYMBOLS_SEARCH_SYMBOL_V2: "SYMBOLS_SEARCH_SYMBOL_V2",
  TAGS_GET_TAG: "TAGS_GET_TAG",
  TAGS_LIST_AND_SEARCH_ALL_TAGS: "TAGS_LIST_AND_SEARCH_ALL_TAGS",
  TAXATION_ESTIMATE_TAX_FOR_INVESTMENT: "TAXATION_ESTIMATE_TAX_FOR_INVESTMENT",
  TIMEDEPENDENTPROPERTIES_CREATE_TIME_DEPENDENT_PROPERTY:
    "TIMEDEPENDENTPROPERTIES_CREATE_TIME_DEPENDENT_PROPERTY",
  TIMEDEPENDENTPROPERTIES_LIST_AND_SEARCH_ALL_TIME_DEPENDENT_PROPERTIES:
    "TIMEDEPENDENTPROPERTIES_LIST_AND_SEARCH_ALL_TIME_DEPENDENT_PROPERTIES",
  TIMEDEPENDENTPROPERTIES_TIME_DEPENDENT_PROPERTIES_ID:
    "TIMEDEPENDENTPROPERTIES_TIME_DEPENDENT_PROPERTIES_ID",
  TIMEDEPENDENTPROPERTIES_UPDATE_TIME_DEPENDENT_PROPERTY:
    "TIMEDEPENDENTPROPERTIES_UPDATE_TIME_DEPENDENT_PROPERTY",
  TPPCERTIFICATES_CREATE_TPP_CERTIFICATE:
    "TPPCERTIFICATES_CREATE_TPP_CERTIFICATE",
  TPPCERTIFICATES_DELETE_TPP_CERTIFICATE:
    "TPPCERTIFICATES_DELETE_TPP_CERTIFICATE",
  TPPCERTIFICATES_LIST_AND_SEARCH_ALL_TPP_CERTIFICATES:
    "TPPCERTIFICATES_LIST_AND_SEARCH_ALL_TPP_CERTIFICATES",
  TPPCERTIFICATES_TPP_CERTIFICATES_ID: "TPPCERTIFICATES_TPP_CERTIFICATES_ID",
  TPPCREDENTIALS_CREATE_TPP_CREDENTIAL: "TPPCREDENTIALS_CREATE_TPP_CREDENTIAL",
  TPPCREDENTIALS_DELETE_TPP_CREDENTIAL: "TPPCREDENTIALS_DELETE_TPP_CREDENTIAL",
  TPPCREDENTIALS_LIST_AND_SEARCH_ALL_TPP_AUTHENTICATION_GROUPS:
    "TPPCREDENTIALS_LIST_AND_SEARCH_ALL_TPP_AUTHENTICATION_GROUPS",
  TPPCREDENTIALS_LIST_AND_SEARCH_ALL_TPP_CREDENTIALS:
    "TPPCREDENTIALS_LIST_AND_SEARCH_ALL_TPP_CREDENTIALS",
  TPPCREDENTIALS_TPP_CREDENTIALS_ID: "TPPCREDENTIALS_TPP_CREDENTIALS_ID",
  TPPCREDENTIALS_UPDATE_TPP_CREDENTIAL: "TPPCREDENTIALS_UPDATE_TPP_CREDENTIAL",
  TPPWEBFORMS_CREATE_TPP_WEB_FORM: "TPPWEBFORMS_CREATE_TPP_WEB_FORM",
  TPPWEBFORMS_TPP_WEB_FORMS_ID: "TPPWEBFORMS_TPP_WEB_FORMS_ID",
  TPPWEBFORMS_V2_CREATE_TPP_WEB_FORM: "TPPWEBFORMS_V2_CREATE_TPP_WEB_FORM",
  TPPWEBFORMS_V2_TPP_WEB_FORMS_ID: "TPPWEBFORMS_V2_TPP_WEB_FORMS_ID",
  TPPWEBFORMS_V2_UPDATE_TPP_WEB_FORM: "TPPWEBFORMS_V2_UPDATE_TPP_WEB_FORM",
  TRANSACTIONCOUNTERPARTPOPULARITY_GET_TRANSACTION_COUNTERPART_POPULARITY:
    "TRANSACTIONCOUNTERPARTPOPULARITY_GET_TRANSACTION_COUNTERPART_POPULARITY",
  TRANSACTIONS_ASSIGN_CATEGORY: "TRANSACTIONS_ASSIGN_CATEGORY",
  TRANSACTIONS_BATCH_CREATE_CASH_TRANSACTIONS:
    "TRANSACTIONS_BATCH_CREATE_CASH_TRANSACTIONS",
  TRANSACTIONS_CREATE_CASH_TRANSACTION: "TRANSACTIONS_CREATE_CASH_TRANSACTION",
  TRANSACTIONS_DELETE_TRANSACTION: "TRANSACTIONS_DELETE_TRANSACTION",
  TRANSACTIONS_DELETE_TRANSACTION_BY_EXTERNAL_ID:
    "TRANSACTIONS_DELETE_TRANSACTION_BY_EXTERNAL_ID",
  TRANSACTIONS_ID_DETECT_ISIN_OR_WKN: "TRANSACTIONS_ID_DETECT_ISIN_OR_WKN",
  TRANSACTIONS_LIST_AND_SEARCH_ALL_TRANSACTION:
    "TRANSACTIONS_LIST_AND_SEARCH_ALL_TRANSACTION",
  TRANSACTIONS_MT940: "TRANSACTIONS_MT940",
  TRANSACTIONS_TRANSACTIONS_ID: "TRANSACTIONS_TRANSACTIONS_ID",
  TRANSACTIONS_UPDATE_CASH_TRANSACTION: "TRANSACTIONS_UPDATE_CASH_TRANSACTION",
  USERPROFILES_DELETE_USER_PROFILE_IMAGE:
    "USERPROFILES_DELETE_USER_PROFILE_IMAGE",
  USERPROFILES_GET_NEWSLETTER_SUBSCRIPTION:
    "USERPROFILES_GET_NEWSLETTER_SUBSCRIPTION",
  USERPROFILES_PROFILE_IMAGE: "USERPROFILES_PROFILE_IMAGE",
  USERPROFILES_SET_USER_PROFILE_IMAGE: "USERPROFILES_SET_USER_PROFILE_IMAGE",
  USERPROFILES_SUBSCRIBE_TO_NEWSLETTER: "USERPROFILES_SUBSCRIBE_TO_NEWSLETTER",
  USERPROFILES_UNSUBSCRIBE_FROM_NEWSLETTER:
    "USERPROFILES_UNSUBSCRIBE_FROM_NEWSLETTER",
  USERPROFILES_UPDATE_USER_PROFILE: "USERPROFILES_UPDATE_USER_PROFILE",
  USERPROFILES_USER_PROFILE: "USERPROFILES_USER_PROFILE",
  USERPROPERTIES_CREATE_OR_UPDATE_USER_PROPERTY:
    "USERPROPERTIES_CREATE_OR_UPDATE_USER_PROPERTY",
  USERPROPERTIES_DELETE_USER_PROPERTY: "USERPROPERTIES_DELETE_USER_PROPERTY",
  USERPROPERTIES_LIST_AND_SEARCH_ALL_USER_PROPERTIES:
    "USERPROPERTIES_LIST_AND_SEARCH_ALL_USER_PROPERTIES",
  USERPROPERTIES_SHOW_USER_PROPERTY: "USERPROPERTIES_SHOW_USER_PROPERTY",
  USERS_CHANGE_EMAIL: "USERS_CHANGE_EMAIL",
  USERS_CHANGE_PASSWORD: "USERS_CHANGE_PASSWORD",
  USERS_CONFIRM_USER: "USERS_CONFIRM_USER",
  USERS_DELETE_USER: "USERS_DELETE_USER",
  USERS_DELETE_USER_BY_USERNAME: "USERS_DELETE_USER_BY_USERNAME",
  USERS_INITIATE_EMAIL_CHANGE: "USERS_INITIATE_EMAIL_CHANGE",
  USERS_INITIATE_PASSWORD_RESET: "USERS_INITIATE_PASSWORD_RESET",
  USERS_LIST_AND_SEARCH_ALL_USERS: "USERS_LIST_AND_SEARCH_ALL_USERS",
  USERS_RESEND_REGISTRATION_CONFIRMATION:
    "USERS_RESEND_REGISTRATION_CONFIRMATION",
  USERS_RESET_PASSWORD: "USERS_RESET_PASSWORD",
  USERS_UPDATE_USER: "USERS_UPDATE_USER",
  USERS_USERS_ID: "USERS_USERS_ID",
  USERS_USERS_ID_IMPERSONATE: "USERS_USERS_ID_IMPERSONATE",
  USERS_USERS_MYSELF: "USERS_USERS_MYSELF",
  USERS_USERS_PREMIUM_SUBSCRIPTION: "USERS_USERS_PREMIUM_SUBSCRIPTION",
  WATCHLISTENTRIES_CREATE_WATCHLIST_ENTRY:
    "WATCHLISTENTRIES_CREATE_WATCHLIST_ENTRY",
  WATCHLISTENTRIES_DELETE_WATCHLIST_ENTRY:
    "WATCHLISTENTRIES_DELETE_WATCHLIST_ENTRY",
  WATCHLISTENTRIES_EDIT_WATCHLIST_ENTRY:
    "WATCHLISTENTRIES_EDIT_WATCHLIST_ENTRY",
  WATCHLISTENTRIES_GET_WATCHLIST_ENTRY: "WATCHLISTENTRIES_GET_WATCHLIST_ENTRY",
  WATCHLISTENTRIES_LIST_AND_SEARCH_ALL_WATCHLIST_ENTRIES:
    "WATCHLISTENTRIES_LIST_AND_SEARCH_ALL_WATCHLIST_ENTRIES",
  WATCHLISTENTRIES_PREVIEW: "WATCHLISTENTRIES_PREVIEW",
  WATCHLISTS_CREATE_WATCHLIST: "WATCHLISTS_CREATE_WATCHLIST",
  WATCHLISTS_DELETE_WATCHLIST: "WATCHLISTS_DELETE_WATCHLIST",
  WATCHLISTS_EDIT_WATCHLIST: "WATCHLISTS_EDIT_WATCHLIST",
  WATCHLISTS_GET_WATCHLIST: "WATCHLISTS_GET_WATCHLIST",
  WATCHLISTS_LIST_AND_SEARCH_ALL_WATCHLISTS:
    "WATCHLISTS_LIST_AND_SEARCH_ALL_WATCHLISTS",
};
