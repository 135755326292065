import { Box, IconButton, ThemeOptions } from "@mui/material";
import React from "react";
import { PaletteOptions } from "@mui/material/styles";
import { ReactComponent as CheckedIcon } from "@icons/checkbox-checked.svg";
import { ReactComponent as RadioCheckedIcon } from "@icons/radio-checked.svg";
import { baseLightColors } from "./baseTokens";
import { ExpandMore } from "./ExpandMore";

const defaultPalette: PaletteOptions & {
  outlined: {
    main: string;
    dark: string;
  };
} = {
  // this exists only for IconButtons, do not use it for anything else
  outlined: {
    main: baseLightColors.N200,
    dark: baseLightColors.N300,
  },
  tertiary: {
    main: baseLightColors.N200,
  },
  info: {
    main: baseLightColors.N200,
  },
  icon: {
    primary: baseLightColors.N200,
    secondary: baseLightColors.N300,
    tertiary: baseLightColors.N400,
    active: baseLightColors.M500,
    disabled: baseLightColors.N400,
    onColor: baseLightColors.N100,
    success: baseLightColors.G500,
    warning: baseLightColors.Y500,
    error: baseLightColors.R500,
  },
  border: {
    secondary: baseLightColors.N100,
    primary: baseLightColors.N200,
    active: baseLightColors.M400,
    error: baseLightColors.R400,
  },
  graphVisual: {},
  secondaryWarning: {
    main: "#7d4c20",
  },
  secondaryError: {
    main: "#e56043",
  },
};

// @ts-ignore
export const baseTheme: ThemeOptions = {
  spacing: 4,
  typography: {
    fontSize: 16, // this is the global default
    // "BiryaniLatin-Regular" is name used in main angular application.
    // It has to be here until we stop using embedded components
    fontFamily: [
      "Inter",
      "Biryani",
      "BiryaniLatin-Regular",
      '"Zilla Slab"',
    ].join(","),
    body1: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "0.01em",
    },
    body2: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
      letterSpacing: "-0.01em",
    },
    body3: {
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: 500,
      letterSpacing: "-0.01em",
      "@media (max-width:601px)": {
        fontSize: "16px",
      },
    },
    h1: {
      fontSize: "44px",
      fontWeight: 700,
      lineHeight: "54px",
      letterSpacing: "-0.02em",

      "@media (max-width:601px)": {
        fontSize: "34px",
        lineHeight: "42px",
      },
    },
    h2: {
      fontSize: "38px",
      fontWeight: 700,
      lineHeight: "48px",

      "@media (max-width:601px)": {
        fontSize: "28px",
        lineHeight: "34px",
      },
    },
    h3: {
      fontSize: "32px",
      fontWeight: 700,
      lineHeight: "40px",

      "@media (max-width:601px)": {
        fontSize: "26px",
        lineHeight: "34px",
      },
    },
    h4: {
      fontSize: "28px",
      fontWeight: 600,
      lineHeight: "36px",

      "@media (max-width:601px)": {
        fontSize: "24px",
        lineHeight: "34px",
      },
    },
    h5: {
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "34px",

      "@media (max-width:601px)": {
        fontSize: "20px",
        lineHeight: "28px",
      },
    },
    h6: {
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "28px",
    },
    subtitle1: {
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 500,
    },
    button: {
      fontWeight: 700,
    },
    overline: {
      fontSize: "10px",
      lineHeight: "16px",
      fontWeight: 500,
    },
    caption: {
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: 500,
    },
  },
  palette: defaultPalette,
  components: {
    MuiSkeleton: {
      variants: [
        {
          props: { variant: "rectangular" },
          style: ({ theme }) => ({
            borderRadius: theme.shape.borderRadius,
          }),
        },
      ],
    },
    MuiButton: {
      defaultProps: {
        color: "grey",
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(2.5, 4),
          textTransform: "none",
          fontWeight: 500,
          borderRadius: theme.shape.borderRadius,
          lineHeight: "24px",
        }),
        startIcon: ({ theme }) => ({
          marginRight: theme.spacing(2),
        }),
        endIcon: ({ theme }) => ({
          marginLeft: theme.spacing(2),
        }),
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            fontSize: "14px",
            lineHeight: "22px",
          },
        },
        {
          props: { color: "grey" },
          style: ({ theme }) => ({
            color: theme.palette.text.secondary,
          }),
        },
        {
          props: { variant: "contained" },
          style: ({ theme }) => ({
            padding: theme.spacing(2.5, 4),

            "&.Mui-disabled": {
              backgroundColor: theme.palette.background.neutral,
              color: theme.palette.grey.dark,
            },
          }),
        },
        {
          props: { variant: "contained", size: "small" },
          style: ({ theme }) => ({
            padding: theme.spacing(1.75, 3),
          }),
        },
        {
          props: { variant: "contained", color: "grey" },
          style: ({ theme }) => ({
            "&.Mui-disabled": {
              backgroundColor: theme.palette.background.subtle,
              color: theme.palette.grey.dark,
            },
          }),
        },
        {
          props: { variant: "outlined" },
          style: ({ theme }) => ({
            padding: theme.spacing(2.25, 3.75),
            backgroundColor: theme.palette.background.default,
            boxShadow: "none !important",

            "&:not(.Mui-disabled) .MuiButton-startIcon, &:not(.Mui-disabled) .MuiButton-endIcon":
              {
                color: theme.palette.text.secondary,
              },
            "&.Mui-disabled": {
              color: theme.palette.grey.dark,
              borderColor: theme.palette.border.secondary,
            },
          }),
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.background.selected,
            padding: theme.spacing(2.25, 4),
            borderColor: theme.palette.primary.lighter,

            "&:not(.Mui-disabled) .MuiButton-startIcon, &:not(.Mui-disabled) .MuiButton-endIcon":
              {
                color: theme.palette.primary.main,
              },

            "&.Mui-disabled": {
              backgroundColor: theme.palette.background.subtle,
              color: theme.palette.grey.dark,
              borderColor: theme.palette.border.secondary,
            },

            "&:hover": {
              backgroundColor: theme.palette.primary.lighter,
              borderColor: theme.palette.primary.lighter,
            },
          }),
        },
        {
          props: { variant: "outlined", color: "secondary" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.background.upgrade,
            padding: theme.spacing(2.25, 4),
            borderColor: theme.palette.secondary.lighter,

            "&:not(.Mui-disabled) .MuiButton-startIcon, &:not(.Mui-disabled) .MuiButton-endIcon":
              {
                color: theme.palette.secondary.main,
              },

            "&.Mui-disabled": {
              backgroundColor: theme.palette.background.subtle,
              color: theme.palette.grey.dark,
              borderColor: theme.palette.border.secondary,
            },

            "&:hover": {
              backgroundColor: theme.palette.secondary?.lighter,
              borderColor: theme.palette.secondary?.lighter,
            },
          }),
        },
        {
          props: { variant: "outlined", color: "tertiary" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.background.tertiary,
            padding: theme.spacing(2.25, 4),
            borderColor: theme.palette.tertiary.lighter,
            color: theme.palette.text.primary,

            "&:not(.Mui-disabled) .MuiButton-startIcon, &:not(.Mui-disabled) .MuiButton-endIcon":
              {
                color: theme.palette.text.primary,
              },

            "&.Mui-disabled": {
              backgroundColor: theme.palette.background.subtle,
              color: theme.palette.grey.dark,
              borderColor: theme.palette.border.secondary,
            },

            "&:hover": {
              backgroundColor: theme.palette.tertiary?.lighter,
              borderColor: theme.palette.tertiary?.lighter,
            },
          }),
        },
        {
          props: { variant: "contained", color: "secondaryWarning" },
          style: ({ theme }) => ({
            color: theme.palette.secondaryWarning.main,
            backgroundColor: theme.palette.graphVisual["05"].light,
            border: "none",

            "& svg": {
              color: theme.palette.secondaryWarning.main,
            },

            "&:hover": {
              border: "none",
            },
          }),
        },
        {
          props: { variant: "contained", color: "secondaryError" },
          style: ({ theme }) => ({
            color: theme.palette.secondaryError.main,
            backgroundColor: theme.palette.error.lighter,
            border: "none",

            "& svg": {
              color: theme.palette.secondaryError.main,
            },

            "&:hover": {
              border: "none",
            },
          }),
        },
        {
          props: { variant: "outlined", color: "grey" },
          style: ({ theme }) => ({
            color: theme.palette.text.primary,
            borderColor: theme.palette.border.secondary,

            "&:hover": {
              backgroundColor: theme.palette.background.subtle,
              borderColor: theme.palette.border.secondary,
            },
          }),
        },
        {
          props: { variant: "outlined", size: "small" },
          style: ({ theme }) => ({
            padding: theme.spacing(1.5, 2.75),
          }),
        },
        {
          props: { variant: "text" },
          style: ({ theme }) => ({
            padding: theme.spacing(2.5, 4),
          }),
        },
        {
          props: { variant: "text", color: "grey" },
          style: ({ theme }) => ({
            color: theme.palette.text.primary,

            "&:hover": {
              backgroundColor: theme.palette.background.subtle,
            },
          }),
        },
        {
          props: { variant: "text", size: "small" },
          style: ({ theme }) => ({
            padding: theme.spacing(1.75, 3),
          }),
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
          height: "44px",
          width: "44px",
          color: theme.palette.text.secondary,

          "& .MuiTouchRipple-ripple": {
            borderRadius: "inherit",
          },
          "& .MuiTouchRipple-child": {
            borderRadius: "inherit !important",
          },
          "&.Mui-disabled": {
            color: theme.palette.grey.dark,
          },
        }),
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            width: "36px",
            height: "36px",
          },
        },
        {
          props: { color: "grey" },
          style: ({ theme }) => ({
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.grey.main,

            "&:hover": {
              backgroundColor: theme.palette.grey.dark,
            },

            "&.Mui-disabled": {
              backgroundColor: theme.palette.background.subtle,
              color: theme.palette.grey.dark,
            },
          }),
        },
        {
          props: { color: "primary" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.background.primary,
            color: theme.palette.primary.contrastText,

            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },

            "&.Mui-disabled": {
              backgroundColor: theme.palette.background.neutral,
            },
          }),
        },
        {
          props: { color: "outlined" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.border.secondary}`,

            "&:hover": {
              backgroundColor: theme.palette.background.neutralLight,
              borderColor: theme.palette.border.secondary,
            },

            "&.Mui-disabled": {
              borderColor: theme.palette.border.secondary,
              color: theme.palette.grey.dark,
              boxShadow: "none",
            },
          }),
        },
        {
          props: { color: "secondary" },
          style: ({ theme }) => ({
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.background.selected,

            "&:hover": {
              backgroundColor: theme.palette.primary.lighter,
              borderColor: theme.palette.primary.lighter,
            },

            "&.Mui-disabled": {
              backgroundColor: theme.palette.background.subtle,
              borderColor: theme.palette.border.secondary,
              color: theme.palette.grey.dark,
            },
          }),
        },
      ],
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '& .MuiSlider-markLabel[data-index="0"]': {
            transform: "translateX(0px)",
          },
          '& .MuiSlider-markLabel[style="left: 100%;"]': {
            transform: "translateX(-100%)",
          },
          marginBottom: 0,
          marginTop: "12px",
        },
        rail: ({ theme }) => ({
          backgroundColor: theme.palette.background.neutral,
          border: `1px solid ${theme.palette.background.neutral}`,
          opacity: 1,
        }),
        track: ({ theme }) => ({
          backgroundColor: theme.palette.background.primary,
          transition: "none",
        }),
        mark: {
          display: "none",
        },
        markLabel: ({ theme }) => ({
          top: "-18px",
          color: theme.palette.text.secondary,
        }),
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          margin: "0 4px",
        },
        root: {
          position: "relative",
          overflow: "hidden",
          "&::after": {
            content: '""',
            display: "block",
            position: "absolute",
            right: 0,
            top: 0,
            width: "30px",
            height: "100%",
            background:
              "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%)",
          },
        },
        ol: {
          flexWrap: "nowrap",
          overflowX: "auto",
          paddingRight: "30px",
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",

          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
        li: {
          flexShrink: 0,
          whiteSpace: "nowrap",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxShadow: theme.shadows[1],
          border: `1px solid ${theme.palette.border.secondary}`,
          "& .PrivatePickersYear-yearButton, & .PrivatePickersMonth-root": {
            fontSize: "16px",
            margin: "4px 0",
          },
          "& .MuiPickersDay-root": {
            fontSize: "14px",
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
        },
        scroller: {
          height: "26px",
          overflowY: "hidden",
        },
        flexContainer: ({ theme }) => ({
          gap: theme.spacing(6),
          padding: 0,
          height: "100%",
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: `0 !important`,
          justifyContent: "flex-start",

          "&.Mui-selected": {
            color: theme.palette.primary.main,
            fontWeight: 500,
            "&:hover": {
              color: theme.palette.primary.main,
            },
          },
          textTransform: "none",
          fontSize: "16px",
          lineHeight: "24px",
          height: "100% !important",
          fontWeight: 400,
          color: theme.palette.text.tertiary,
          minWidth: "auto",
          "&:hover": {
            textDecoration: "none",
            color: theme.palette.text.secondary,
          },
        }),
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "p",
          subtitle2: "p",
          caption: "p",
          overline: "p",
          body1: "p",
          body2: "p",
          body3: "p",
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          padding: 0,
          marginLeft: 0,

          "& .Mui-checked": {
            backgroundColor: theme.palette.background.primary,
            ".MuiSwitch-track": {
              backgroundColor: theme.palette.background.primary,
            },
            ".MuiSwitch-thumb": {
              backgroundColor: theme.palette.background.default,
            },
          },
        }),
        track: ({ theme }) => ({
          backgroundColor: theme.palette.background.neutralDark,
          opacity: "1 !important",
        }),
        sizeSmall: ({ theme }) => ({
          height: "16px",
          width: "28px",
          borderRadius: "16px",

          "& .MuiSwitch-switchBase": {
            "&.Mui-checked": {
              transform: "translateX(12px)",
            },
            padding: theme.spacing(0.5),
          },

          "& .MuiSwitch-thumb": {
            height: "12px",
            width: "12px",
          },
        }),
        sizeMedium: ({ theme }) => ({
          height: "24px",
          width: "40px",
          borderRadius: "24px",

          "& .MuiSwitch-switchBase": {
            "&.Mui-checked": {
              transform: "translateX(16px)",
            },
            padding: theme.spacing(0.5),
          },

          "& .MuiSwitch-thumb": {
            height: "20px",
            width: "20px",
          },
        }),
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: ({ theme }) => ({
          border: `1px solid ${theme.palette.border.secondary}`,
          boxShadow: theme.shadows[2],
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          display: "inline-block",
          fontSize: "14px",
          lineHeight: "22px",
          marginBottom: theme.spacing(1),
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxShadow: "none",
          border: `2px solid ${theme.palette.border.primary}`,
          borderRadius: "16px",
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "14px",
          lineHeight: "22px",
        },
        root: {
          "& .MuiCheckbox-root": {
            paddingLeft: "11px",
            paddingRight: "12px",
          },
          "& .MuiSwitch-root": {
            marginRight: "12px",
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
        color: "primary",
        icon: <Box className="radioIcon" />,
        checkedIcon: (
          <Box
            className="radioIcon"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <RadioCheckedIcon width="18px" height="18px" />
          </Box>
        ),
      },
      styleOverrides: {
        root: ({ theme }) => ({
          "& .radioIcon": {
            width: "20px",
            height: "20px",
            boxSizing: "border-box",
            borderRadius: "50%",
            padding: "0",
            border: `2px solid ${theme.palette.border.primary}`,
            backgroundColor: theme.palette.background.default,
          },

          "&:hover .radioIcon": {
            borderColor: theme.palette.border.primary,
          },

          "&.Mui-checked .radioIcon": {
            backgroundColor: theme.palette.background.primary,
            borderColor: theme.palette.primary.main,
          },

          "&:hover.Mui-checked .radioIcon": {
            borderColor: theme.palette.primary.light,
          },

          "&.Mui-disabled .radioIcon": {
            borderColor: theme.palette.border.secondary,
          },

          "&.Mui-disabled.Mui-checked .radioIcon": {
            backgroundColor: theme.palette.primary.lighter,
            borderColor: theme.palette.primary.lighter,

            "& svg": {
              color: `${theme.palette.common.white} !important`,
            },
            "& svg path:first-of-type": {
              fill: `${theme.palette.common.white} !important`,
            },
          },
        }),
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: "primary",
        icon: <Box className="checkIcon" />,
        checkedIcon: (
          <Box className="checkIcon" display="flex" alignItems="center">
            <CheckedIcon width="18px" height="18px" />
          </Box>
        ),
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          "& .checkIcon": {
            width: "20px",
            height: "20px",
            boxSizing: "border-box",
            borderRadius: theme.shape.borderRadius,
            padding: "0",
            border: `2px solid ${theme.palette.border.primary}`,
            backgroundColor: theme.palette.background.default,
          },

          "&:hover .checkIcon": {
            borderColor: theme.palette.border.primary,
          },

          "&.Mui-checked .checkIcon": {
            backgroundColor: theme.palette.background.primary,
            borderColor: theme.palette.primary.main,
          },

          "&:hover.Mui-checked .checkIcon": {
            borderColor: theme.palette.primary.light,
          },

          "&.Mui-disabled .checkIcon": {
            borderColor: theme.palette.border.secondary,
          },

          "&.Mui-disabled.Mui-checked .checkIcon": {
            backgroundColor: theme.palette.primary.lighter,
            borderColor: theme.palette.primary.lighter,

            "& svg": {
              color: `${theme.palette.common.white} !important`,
            },
            "& svg path:first-of-type": {
              fill: `${theme.palette.common.white} !important`,
            },
          },
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.border.primary,
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: "14px",
          lineHeight: "22px",
          color: theme.palette.text.secondary,
          fontWeight: 500,
          minHeight: "auto",
        }),
      },
    },
    MuiChip: {
      defaultProps: {
        color: "secondary",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.border.primary,
          color: theme.palette.text.secondary,
        }),
        icon: {
          width: "16px",
          height: "16px",
        },
        sizeSmall: {
          height: "30px",
          borderRadius: "16px",

          "& .MuiChip-icon": {
            marginLeft: "8px",
          },
        },
        sizeMedium: {
          height: "36px",
          borderRadius: "30px",

          "& .MuiChip-icon": {
            marginLeft: "12px",
          },
        },
        label: {
          fontSize: "14px",
          lineHeight: "22px",
        },
        outlinedPrimary: ({ theme }) => ({
          backgroundColor: theme.palette.background.selected,
          color: theme.palette.text.primary,
          borderColor: theme.palette.primary.main,

          "&.MuiChip-clickable:hover": {
            backgroundColor: theme.palette.background.selected,
          },

          "& .MuiChip-icon": {
            color: theme.palette.primary.dark,
          },
        }),
        outlinedSecondary: ({ theme }) => ({
          "&.MuiChip-clickable:hover": {
            backgroundColor: theme.palette.background.subtle,
          },
        }),
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: {
            sx: (theme) => ({
              boxShadow: theme.shadows[2],
              backgroundColor: theme.palette.background.default,
            }),
          },
        },
        IconComponent: (props) => {
          return (
            <IconButton
              sx={{
                width: "48px",
                height: "48px",
                right: "4px !important",
                top: "0 !important",

                "& svg": {
                  width: "24px",
                  height: "24px",
                },
              }}
              {...props}
            >
              <ExpandMore disabled={props.className.includes("disabled")} />
            </IconButton>
          );
        },
      },
      styleOverrides: {
        // @ts-ignore actually works
        root: {
          "& .MuiBackdrop-root": {
            opacity: `0 !important`,
            cursor: "initial",
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <ExpandMore />,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          "& .Mui-disabled svg": {
            color: `${theme.palette.icon.disabled} !important`,
          },
        }),
        inputRoot: {
          padding: `0 !important`,
        },
        clearIndicator: ({ theme }) => ({
          color: theme.palette.text.secondary,
          "& svg": {
            width: "20px",
            height: "20px",
          },
        }),
        groupLabel: ({ theme }) => ({
          lineHeight: "30px",
          color: theme.palette.text.primary,
          fontSize: "14px",
        }),
        option: ({ theme }) => ({
          color: theme.palette.text.secondary,
          fontSize: "14px",
          lineHeight: "22px",
          fontWeight: 500,
          "&:hover": {
            backgroundColor: theme.palette.background.subtle,
          },
          minHeight: "auto",
        }),
        popupIndicator: ({ theme }) => ({
          color: theme.palette.icon.primary,
        }),
        endAdornment: {
          height: "28px",
          display: "flex",
          alignItems: "center",
          right: "3px !important",

          "& > button": {
            height: "48px",
            width: "48px",
          },
        },
        input: ({ theme }) => ({
          padding: `8px 64px 8px 12px !important`,

          "&::placeholder": {
            opacity: 1,
            fontSize: "14px",
            color: theme.palette.text.secondary,
          },
        }),
        paper: ({ theme }) => ({
          backgroundColor: theme.palette.background.paper,
          marginTop: "5px",
          marginBottom: "5px",
          boxShadow: theme.shadows[2],
          border: `1px solid ${theme.palette.border.secondary}`,
        }),
        loading: {
          fontSize: "14px",
        },
        noOptions: {
          fontSize: "14px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: "14px",
          lineHeight: "22px",
          color: theme.palette.text.primary,
          letterSpacing: "-0.01em",
          transform: "none",
          position: "relative",
          marginBottom: "4px",

          "&.Mui-focused": {
            color: theme.palette.text.primary,
          },

          "&.Mui-error": {
            color: theme.palette.text.primary,
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& fieldset": {
            top: 0,
            borderRadius: theme.shape.borderRadius,
            borderColor: theme.palette.border.primary,
          },
          "& legend": {
            display: "none",
          },
          "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.border.primary,
            },
          },
          "&:focus-within": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.main,
            },
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.background.subtle,

            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.border.secondary,
            },
          },
        }),
        input: ({ theme }) => ({
          padding: theme.spacing(2, 3),
          fontSize: "14px",
          lineHeight: "20px",
        }),
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.neutralDark,
          borderRadius: theme.shape.borderRadius,
          padding: theme.spacing(1),

          "& .MuiToggleButton-root": {
            textTransform: "none",
            border: "none",
            fontSize: "14px",
            lineHeight: "22px",
            fontWeight: 500,
            flex: 1,
            color: theme.palette.text.secondary,
            padding: theme.spacing(1, 4),
            borderRadius: "4px !important",

            "&.Mui-selected": {
              color: theme.palette.text.primary,
              boxShadow: theme.shadows[1],
              backgroundColor: theme.palette.common.white,
            },
            "&.Mui-selected:hover": {
              backgroundColor: theme.palette.common.white,
            },
            "&.Mui-disabled": {
              borderColor: "transparent",
            },
          },
        }),
      },
    },
    MuiTooltip: {
      defaultProps: {
        disableInteractive: true,
      },
      styleOverrides: {
        popper: {
          zIndex: 7000,
        },
        tooltipPlacementTop: {
          "&.MuiTooltip-tooltipArrow": {
            marginBottom: "10px !important",
          },
          marginBottom: "5px !important",
        },
        tooltipPlacementRight: {
          "&.MuiTooltip-tooltipArrow": {
            marginLeft: "12px !important",
          },
          marginLeft: "5px !important",
        },
        tooltipPlacementBottom: {
          "&.MuiTooltip-tooltipArrow": {
            marginTop: "12px !important",
          },
          marginTop: "4px !important",
        },
        tooltipPlacementLeft: {
          "&.MuiTooltip-tooltipArrow": {
            marginRight: "12px !important",
          },
          marginRight: "4px !important",
        },
        tooltip: ({ theme }) => ({
          backgroundColor: theme.palette.background.tooltip,
          [`.MuiTooltip-popper[data-popper-placement*="bottom"] &`]: {
            margin: "5px 0",
          },
          [`.MuiTooltip-popperArrow[data-popper-placement*="bottom"] &`]: {
            margin: "10px 0",
          },
          [`.MuiTooltip-popper[data-popper-placement*="top"] &`]: {
            margin: "5px 0",
          },
          [`.MuiTooltip-popperArrow[data-popper-placement*="top"] &`]: {
            margin: "10px 0",
          },
          [`.MuiTooltip-popper[data-popper-placement*="right"] &`]: {
            margin: "0 5px",
          },
          [`.MuiTooltip-popperArrow[data-popper-placement*="right"] &`]: {
            margin: "0 10px",
          },
          [`.MuiTooltip-popper[data-popper-placement*="left"] &`]: {
            margin: "0 5px",
          },
          [`.MuiTooltip-popperArrow[data-popper-placement*="left"] &`]: {
            margin: "0 10px",
          },
        }),
        arrow: ({ theme }) => ({
          color: theme.palette.background.tooltip,
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          textDecoration: "none",
          cursor: "pointer",

          "&:hover": {
            textDecoration: "none",
            color: theme.palette.primary.dark,
          },
        }),
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          ".MuiIconButton-root": {
            height: "36px",
            width: "36px",
            marginRight: "-9px",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: "auto",
          "&.MuiInputBase-adornedEnd": {
            paddingRight: "14px",
          },
          "&.Mui-disabled .MuiInputAdornment-root .MuiTypography-root": {
            color: theme.palette.text.disabled,
          },
        }),
        multiline: {
          padding: "0 !important",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "14px",
          lineHeight: 1.43,
          letterSpacing: "0.01em",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        InputLabelProps: { shrink: true },
      },
    },
  },
};
