import React from "react";
import { useStripe } from "@generated/user/hooks";
import { LoadingPlaceHolder } from "@components";
import { SettingsErrorBlock } from "../components/SettingsErrorBlock";
import { SubscriptionComparison } from "./components/SubscriptionComparison";
import SubscriptionView from "./components/SubscriptionView";

interface Props {
  showInOneColumn: boolean;
  isLoadingUserData: boolean;
  isPlusUser: boolean;
}

function SettingsSubscriptionsPage({
  showInOneColumn,
  isLoadingUserData,
  isPlusUser,
}: Props) {
  const {
    data: stripeData,
    isLoading: isLoadingStripeData,
    isError,
  } = useStripe({
    refetchOnWindowFocus: false,
  });

  if (isLoadingUserData || isLoadingStripeData) {
    return <LoadingPlaceHolder />;
  }

  if (isError || !stripeData) {
    return <SettingsErrorBlock />;
  }

  return isPlusUser ? (
    <SubscriptionView stripeData={stripeData} />
  ) : (
    <SubscriptionComparison
      stripeData={stripeData}
      showInOneColumn={showInOneColumn}
    />
  );
}

export default SettingsSubscriptionsPage;
