import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const setNameAndDepotAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (event.type !== "NAME_AND_DEPOT_SELECTED")
    throw new Error(`Invalid event type ${event.type}`);

  const depotId =
    event.type === "NAME_AND_DEPOT_SELECTED"
      ? event.depotId
      : context.investmentValues.depotId;

  return {
    investmentValues: {
      ...context.investmentValues,
      depotId,
      id: event.investmentValues?.id,
      name: event.investmentValues?.name || context.investmentValues.name,
      type: event.investmentValues?.type || context.investmentValues.type,
      isin: event.investmentValues?.isin || context.investmentValues.isin,
      subCategoryId:
        event.investmentValues?.subCategoryId ||
        context.investmentValues.subCategoryId,
    },
    bookingValues: {
      ...context.bookingValues,
      comment: event.comment,
    },
  };
};
