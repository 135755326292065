import React from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { toCurrency, toPercent } from "@helpers";
import { ChartTooltip } from "@components/Chart/Tooltips/ChartTooltip";

type Props = {
  point: {
    year: number;
    y: number;
    index: number;
    dividends: {
      year: number;
      month: number;
      amount: number;
      percent: number;
      name: string;
    }[];
  };
  mode: "short" | "long";
};

const textProps = {
  fontSize: "12px",
  lineHeight: "22px",
  fontWeight: 600,
  fontFamily: "Averta",
};

export const DividendsTooltip = ({ point, mode }: Props) => {
  const { t } = useTranslation();
  return (
    <ChartTooltip>
      <div
        style={{
          ...textProps,
          marginBottom: "8px",
        }}
      >
        {format(
          new Date(point.year, point.index),
          mode === "short" ? "yyyy" : "MMMM yyyy"
        )}
      </div>
      <div style={{ maxHeight: "250px", overflowY: "auto", width: "250px" }}>
        {point.dividends.map((dividend) => (
          <div
            key={`${dividend.year}${dividend.month}`}
            style={{ marginBottom: "8px" }}
          >
            <div
              style={{
                display: "flex",
                gap: "4px",
                marginBottom: "2px",
                alignItems: "center",
              }}
            >
              <div style={{ ...textProps }}>
                {toCurrency(dividend.amount, "EUR")}
              </div>
              <div
                style={{
                  ...textProps,
                  backgroundColor: "#EDF8FF",
                  borderRadius: "6px",
                  padding: "2px 8px",
                  color: "#7189E4",
                }}
              >
                {toPercent(dividend.percent / 100).replace(/\s/, "")}
              </div>
            </div>
            <span
              style={{
                ...textProps,
                width: "100%",
                display: "inline-block",
                lineHeight: "18px",
                fontWeight: 400,
                whiteSpace: "pre-wrap",
              }}
            >
              {dividend.name}
            </span>
          </div>
        ))}
      </div>
      <div
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: "#E5E5E5",
          marginTop: "8px",
          marginBottom: "8px",
        }}
      ></div>
      <div>
        <div style={{ ...textProps, marginBottom: "2px" }}>
          {toCurrency(point.y, "EUR")}
        </div>
        <div style={{ ...textProps, lineHeight: "18px", fontWeight: 400 }}>
          {t("dashboard.dividendsChart.total")} (
          {format(
            new Date(point.year, point.index),
            mode === "short" ? "yyyy" : "MMMM yyyy"
          )}
          )
        </div>
      </div>
    </ChartTooltip>
  );
};
