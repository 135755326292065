import {
  FormControl,
  Grid,
  Box,
  Typography,
  TextField,
  useMediaQuery,
  Theme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { getCurrencySymbol } from "@helpers/currencyCodeToSymbol";
import { useV1ListCurrencies } from "@generated/apiv1/hooks";
import { getExchangeRate } from "@api";
import { BookingTypeEnum, QuoteV2, SymbolV2 } from "@generated/apiv1";
import { ReactComponent as ErrorIcon } from "@icons/error.svg";
import { Banner } from "@components";
import { TransactionWizardContext } from "@features/transactionWizard/modal/stateMachine/types/transactionWizardContext";
import { InputFieldWithRevert } from "@features/transactionWizard/modal/components/NumberInputFieldWithRevert";
import { DatePickerWithQuoteLookup } from "@features/transactionWizard/components/DatePickerWithQuoteLookup";
import { BookingValues } from "@features/transactionWizard/modal/stateMachine/types/bookingValues";
import {
  MultiplyIcon,
  EqualsIcon,
} from "@features/transactionWizard/modal/components/Icons";
import { StepContainer } from "@features/transactionWizard/modal/components/StepContainer";
import { useHelpers } from "../hooks/useHelpers";
import { useLabelHelpers } from "../hooks/useLabelHelpers";
import { useGetBookingValues } from "../hooks/useUpdateBookingValues";
import { useProposePrice } from "../hooks/useProposePrice";

export interface Props {
  handleNext: (bookingValues: BookingValues, currency?: string) => void;
  context: TransactionWizardContext;
  isLoading: boolean;
}

export const PriceAndDateStep = ({ handleNext, isLoading, context }: Props) => {
  const { t } = useTranslation();
  const isLessMd = useMediaQuery((theme: Theme) => theme.breakpoints.down(750));
  const { bookingValues, symbol, investmentValues, validationError } = context;
  const {
    getAmounts,
    ouncesToGram,
    gramToOunces,
    validate,
    getNumberOfLotsForEntryQuote,
    getEntryQuoteForNumberOfLots,
  } = useGetBookingValues();
  const shouldProposeSecurityPrice =
    bookingValues.type !== BookingTypeEnum.RETAINED_DIVIDEND &&
    bookingValues.type !== BookingTypeEnum.DIVIDEND;

  const { data: currencies } = useV1ListCurrencies();

  const { nolLabel, nolDescription, priceLabel, entryQuoteDescription } =
    useLabelHelpers();
  const { toString, parseOrUndefined, hasTax, hasSecurityPrice } = useHelpers();

  const [values, setValues] = useState<BookingValues>(bookingValues);

  const [date, setDate] = useState<Date | undefined>(bookingValues.date);

  const [lockedField, setLockedField] = useState("amount");

  const [numberOfLots, setNumberOfLots] = useState<string>(
    toString(bookingValues.numberOfLots)
  );
  const [gramms, setGramms] = useState<string>(
    toString(ouncesToGram(bookingValues.numberOfLots || 0))
  );
  const [currency] = useState<string | undefined>(
    investmentValues.investmentCurrency || "EUR"
  );
  const [entryQuote, setEntryQuote] = useState<string>(
    shouldProposeSecurityPrice ? toString(bookingValues.entryQuote) : ""
  );
  const [securityPrice, setSecurityPrice] = useState<string>(
    toString(bookingValues.securityPrice)
  );
  const [entryQuoteTakeoverValue, setEntryQuoteTakeoverValue] = useState<
    number | undefined
  >(undefined);
  const [taxAmount, setTaxAmount] = useState<string>(
    toString(bookingValues.taxAmount)
  );
  const [commission, setCommission] = useState<string>(
    toString(bookingValues.commission)
  );
  const [amount, setAmount] = useState<string>(toString(bookingValues.amount));

  const priceInPercent = investmentValues.type === "31_bond";
  const isDividend = bookingValues.type === "dividend";

  const { proposeSecurityPriceChange } = useProposePrice(
    investmentValues.tickerSymbol || "",
    investmentValues.quoteProvider || "none",
    investmentValues.quoteCurrency || "EUR",
    "EUR",
    setEntryQuoteTakeoverValue
  );

  const handleDateChange = (date: Date) => {
    setDate(date);
    setValues({ ...values, date: date });
    if (shouldProposeSecurityPrice) {
      proposeSecurityPriceChange(date);
    }
  };

  const updateAmounts = useCallback(
    (partial: BookingValues) => {
      setValues((current: BookingValues) => {
        const newValues = { ...current, ...partial };

        if (!numberOfLots && !partial.numberOfLots) return newValues;
        if (entryQuote == null && partial.entryQuote == null) return newValues;
        if (!newValues.type) return newValues;

        const amounts = getAmounts(
          newValues.type,
          newValues.numberOfLots || 0,
          newValues.entryQuote || 0,
          newValues.commission || 0,
          newValues.taxAmount || 0,
          priceInPercent
        );

        if (lockedField === "amount") {
          newValues.amount = amounts.amount;
          newValues.totalAmount = amounts.totalAmount;

          setAmount(toString(amounts.amount, "", 6));
        }

        return newValues;
      });
    },
    [
      numberOfLots,
      entryQuote,
      priceInPercent,
      getAmounts,
      toString,
      lockedField,
    ]
  );

  const convertEntryQuoteToCurrency = useCallback(
    async (
      symbol: (SymbolV2 & { quote?: QuoteV2 }) | undefined,
      entryQuote?: number,
      currency: string = "EUR"
    ) => {
      if (numberOfLots && amount) return;
      let convertedEntryQuote = entryQuote;
      if (!shouldProposeSecurityPrice) return;

      if (!symbol?.quote?.last || !symbol?.quote?.currency) {
        convertedEntryQuote = entryQuote;
      } else if (currency === symbol.quote?.currency) {
        convertedEntryQuote = symbol.quote?.last;
      } else {
        try {
          const rate = await getExchangeRate({
            from: symbol.quote?.currency,
            to: "EUR",
            date: format(new Date(), "yyyy-MM-dd"),
          });

          convertedEntryQuote = symbol?.quote?.last * rate.rate;
        } catch (e) {
          convertedEntryQuote = symbol?.quote?.last;
        }
      }

      updateAmounts({
        entryQuote: convertedEntryQuote,
      });
      setEntryQuote(toString(convertedEntryQuote));
    },
    [toString, updateAmounts, numberOfLots, amount, shouldProposeSecurityPrice]
  );

  const handleNolChange = (value: string) => {
    const numberOfLots = parseOrUndefined(value);
    setNumberOfLots(toString(numberOfLots, value, 10));
    if (!numberOfLots || numberOfLots < 0) return;
    setGramms(toString(ouncesToGram(numberOfLots) || 0));

    const newEntryQuote = getEntryQuoteForNumberOfLots(
      parseOrUndefined(amount) || 0,
      numberOfLots || 0,
      priceInPercent
    );

    updateAmounts({
      numberOfLots: numberOfLots,
      entryQuote:
        lockedField === "entryQuote" ? newEntryQuote : values.entryQuote,
    });
    if (lockedField === "entryQuote") {
      setEntryQuote(toString(newEntryQuote));
    }
  };

  const handleEntryQuoteChange = (value: string) => {
    const entryQuote = parseOrUndefined(value);
    setEntryQuote(toString(entryQuote, value));
    if (entryQuote == null || entryQuote < 0) return;

    const newNumberOfLots = getNumberOfLotsForEntryQuote(
      parseOrUndefined(amount) || 0,
      entryQuote || 0
    );

    updateAmounts({
      entryQuote,
      numberOfLots:
        lockedField === "numberOfLots" ? newNumberOfLots : values.numberOfLots,
    });

    if (lockedField === "numberOfLots") {
      setNumberOfLots(toString(newNumberOfLots));
    }
  };

  const handleGrammsChange = (value: string) => {
    const gramms = parseOrUndefined(value);
    setGramms(toString(gramms, value));
    if (!gramms || gramms < 0) return;

    const newNumberOfLots = gramToOunces(gramms);

    setNumberOfLots(toString(newNumberOfLots));
    updateAmounts({ numberOfLots: newNumberOfLots });
  };

  const handleSecurityPriceChange = (value: string) => {
    const securityPrice = parseOrUndefined(value);
    setSecurityPrice(toString(securityPrice, value));
    if (securityPrice == null || securityPrice < 0) return;
    updateAmounts({ securityPrice });
  };

  const handleEntryQuoteTakeover = (value: string) => {
    setEntryQuoteTakeoverValue(undefined);
    handleEntryQuoteChange(value);
  };

  const handleCommissionChange = (value: string) => {
    const commission = parseOrUndefined(value);
    setCommission(toString(commission, value, 6));
    if (commission == null) return;
    updateAmounts({ commission });
  };

  const handleTaxAmountChange = (value: string) => {
    const taxAmount = parseOrUndefined(value);
    setTaxAmount(toString(taxAmount, value, 6));
    if (taxAmount == null) return;
    updateAmounts({ taxAmount });
  };

  const handleTotalAmountChange = (value: string) => {
    const amount = parseOrUndefined(value);
    setAmount(toString(amount, value));

    if (amount == null) return;
    if (!numberOfLots) return;
    if (entryQuote == null) return;

    const calculatedValue =
      lockedField === "entryQuote"
        ? getEntryQuoteForNumberOfLots(
            amount || 0,
            values.numberOfLots || 0,
            priceInPercent
          )
        : getNumberOfLotsForEntryQuote(amount || 0, values.entryQuote || 0);

    updateAmounts({
      amount,
      entryQuote:
        lockedField === "entryQuote" ? calculatedValue : values.entryQuote,
      numberOfLots:
        lockedField === "numberOfLots" ? calculatedValue : values.numberOfLots,
    });

    if (lockedField === "entryQuote") {
      setEntryQuote(toString(calculatedValue, "", 6));
    } else if (lockedField === "numberOfLots") {
      setNumberOfLots(toString(calculatedValue, "", 6));
      if (isMetals) {
        setGramms(toString(ouncesToGram(calculatedValue) || 0, "", 6));
      }
    }
  };

  const onLockClick = (field: string) => {
    if (lockedField === field) {
      return;
    }

    setLockedField(field);
  };

  const currencySymbol = getCurrencySymbol("EUR");
  const onNextDisabled = !validate(values);
  const isMetals = investmentValues.type === "61_pmetals";

  useEffect(() => {
    convertEntryQuoteToCurrency(symbol, parseOrUndefined(entryQuote), currency);
    const foundCurrency = currencies?.currencies?.find(
      (c) => c.code === currency
    );
    if (foundCurrency) {
      updateAmounts({ exchangeRate: foundCurrency.toEUR });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, currencies, symbol, parseOrUndefined]);

  return (
    <StepContainer
      isLoading={isLoading}
      handleNext={() => handleNext(values, currency)}
      onNextDisabled={onNextDisabled}
    >
      <Box display="flex" flexDirection="column" gap={6}>
        {!!validationError && (
          <Banner
            icon={<ErrorIcon />}
            type="error"
            data-testid="booking-validation-error"
            text={validationError}
          />
        )}

        <Grid container rowSpacing={6} columnSpacing={6}>
          <Grid item xs={12} sm={6}>
            <DatePickerWithQuoteLookup
              date={date ? date : new Date()}
              handleChange={handleDateChange}
              label={t("transactionWizardModal.priceAndDate.date")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                value={currency}
                id="currency"
                disabled
                label={t("transactionWizardModal.priceAndDate.currency")}
              />
            </FormControl>
          </Grid>

          {isMetals && (
            <Grid
              item
              xs={12}
              sm={
                hasSecurityPrice(bookingValues.type) ||
                !hasTax(bookingValues.type)
                  ? 6
                  : 12
              }
            >
              <InputFieldWithRevert
                fieldLabel={t(
                  "transactionWizardModal.priceAndDate.numberOfLotsGram"
                )}
                id="gramms"
                value={gramms}
                handleChange={handleGrammsChange}
                endAdornment="g"
              />
            </Grid>
          )}

          {hasSecurityPrice(bookingValues.type) && (
            <Grid xs={12} sm={6} item>
              <InputFieldWithRevert
                fieldLabel={t(
                  "transactionWizardModal.priceAndDate.securityPrice"
                )}
                id="security-price"
                value={securityPrice}
                handleChange={handleSecurityPriceChange}
                endAdornment={priceLabel(investmentValues.type, currencySymbol)}
              />
            </Grid>
          )}

          {!hasTax(bookingValues.type) && (
            <Grid
              item
              xs={12}
              sm={
                isMetals
                  ? hasSecurityPrice(bookingValues.type)
                    ? 12
                    : 6
                  : hasSecurityPrice(bookingValues.type)
                  ? 6
                  : 12
              }
            >
              <InputFieldWithRevert
                fieldLabel={t("transactionWizardModal.priceAndDate.commission")}
                id="commission"
                value={commission}
                handleChange={handleCommissionChange}
                endAdornment="€"
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            display="flex"
            alignItems={isLessMd ? "center" : "flex-end"}
            flexDirection={isLessMd ? "column" : "row"}
            gap={isLessMd ? 3 : 8}
          >
            <InputFieldWithRevert
              fieldLabel={
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1" color="textSecondary">
                    {nolDescription(investmentValues.type)}
                  </Typography>
                </Box>
              }
              id="number-of-lots"
              value={numberOfLots}
              handleChange={handleNolChange}
              endAdornment={nolLabel(investmentValues.type, currencySymbol)}
              maximumFractionDigits={10}
              disabled={isDividend || lockedField === "numberOfLots"}
              onLockClick={
                isDividend ? undefined : () => onLockClick("numberOfLots")
              }
            />

            <MultiplyIcon />

            <InputFieldWithRevert
              fieldLabel={entryQuoteDescription(bookingValues.type || "buy")}
              id="entry-quote"
              value={entryQuote}
              takeoverDate={
                values.date && isNaN(values.date.getTime())
                  ? undefined
                  : values.date
              }
              takeoverValue={entryQuoteTakeoverValue}
              handleTakeover={handleEntryQuoteTakeover}
              handleChange={handleEntryQuoteChange}
              endAdornment={priceLabel(investmentValues.type, currencySymbol)}
              disabled={lockedField === "entryQuote"}
              onLockClick={() => onLockClick("entryQuote")}
            />

            <EqualsIcon />

            <InputFieldWithRevert
              fieldLabel={t("transactionWizardModal.priceAndDate.totalAmount")}
              id="total-amount"
              value={amount}
              disabled={lockedField === "amount"}
              handleChange={handleTotalAmountChange}
              endAdornment={currencySymbol}
              onLockClick={() => onLockClick("amount")}
            />
          </Grid>

          {hasTax(bookingValues.type) && (
            <Grid item xs={12} sm={6}>
              <InputFieldWithRevert
                fieldLabel={t("transactionWizardModal.priceAndDate.commission")}
                id="commission"
                value={commission}
                handleChange={handleCommissionChange}
                endAdornment="€"
              />
            </Grid>
          )}

          {hasTax(bookingValues.type) && (
            <Grid item xs={12} sm={6}>
              <InputFieldWithRevert
                fieldLabel={t("transactionWizardModal.priceAndDate.taxAmount")}
                id="tax-amount"
                value={taxAmount != null ? taxAmount : ""}
                handleChange={handleTaxAmountChange}
                endAdornment="€"
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </StepContainer>
  );
};
