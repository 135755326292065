import React, { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { format } from "date-fns";
import {
  Box,
  styled,
  Typography,
  TableHead,
  TableBody,
  TableCell,
  Table,
  BoxProps,
  useMediaQuery,
  Theme,
  Divider,
  useTheme,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Badge,
  LoadingPlaceHolder,
  NoDataStub,
  SortableTableHeader,
  TableRow,
} from "@components";
import { BlurredTypography } from "@components/BlurredTypography";
import { toCurrency, toGermanNumberFormat } from "@helpers";
import { SecurityImage } from "@components/SecurityImage";
import { useDividendsContext } from "@features/dividendPlanner/DividendsProvider";
import { DividendStatus } from "@features/dividendPlanner/components/DividendStatus";
import { useDividendsForTable } from "@features/dividendPlanner/components/DividendsTable/useDividendsForTable";
import { FullViewDividendCell } from "@features/dividendPlanner/components/DividendsTable/FullViewDividendCell";
import { PlusGradient } from "@components/Plus/PlusGradient";
import { DividendFrequency } from "@features/dividendPlanner/components/DividendFrequency";
import { SumPanel } from "@features/dividendPlanner/CalendarTab/SumPanel";

const DividendsTableContainer = styled(Box)(() => ({
  "& .no-border": {
    "& td": {
      borderBottom: "none",
    },
  },
}));

const MonthTableRow = styled(TableRow)<{ shouldShowDividends: boolean }>(
  ({ theme, shouldShowDividends }) => ({
    "& td": {
      backgroundColor: theme.palette.background.subtle,
      ...(shouldShowDividends && {
        borderBottom: "none",
      }),
    },
  })
);

type Props = {
  date: Date;
  scrollableTarget?: string;
  scrollContainerHeight?: string;
  view: "calendar" | "table" | "modal";
  period: "year" | "month" | "day" | undefined;
  displaySum?: boolean;
  displayProBanner?: boolean;
  fullViewPadding?: number;
} & BoxProps;

export const DividendsTable = ({
  date,
  scrollableTarget = "layoutWithTabsBody",
  scrollContainerHeight,
  view,
  period,
  displaySum,
  displayProBanner,
  fullViewPadding,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullView = view === "modal" || view === "calendar";
  const isDailyView = period === "day";
  const isMonthlyView = period === "month";
  const isYearlyView = !period;
  const [displayedSlice, setDisplayedSlice] = useState(1);
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const isLess440 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(440)
  );

  const { images, investments, isLoading, isUserPro, isUserDataFetched } =
    useDividendsContext();
  const {
    dividends,
    allDividends,
    hasPredictions,
    // sort, sortField, sortDirection
  } = useDividendsForTable({
    date,
    period,
    displayedSlice,
  });

  const displayMoreDividends = () => {
    setDisplayedSlice((prev) => prev + 1);
  };

  const hasNoData = allDividends.every(
    (dividend) => Object.values(dividend.children || {}).length === 0
  );

  const totalSum = useMemo(
    () => allDividends.reduce((acc, year) => acc + (year.grossAmount || 0), 0),
    [allDividends]
  );
  const bannerIsVisible =
    isUserDataFetched && fullView && !isUserPro && hasPredictions;

  if (isLoading) {
    return (
      <Box mt={9} width="100%">
        <LoadingPlaceHolder />
      </Box>
    );
  }

  return (
    <DividendsTableContainer
      {...rest}
      sx={{
        ...rest.sx,
        height: fullView
          ? `calc(100% - ${bannerIsVisible ? "164px" : "64px"})`
          : "auto",
      }}
    >
      {view === "modal" && !isUserPro && hasPredictions && (
        <PlusGradient variant="large" mb={6} />
      )}
      {displaySum && (
        <SumPanel
          amount={totalSum || 0}
          hideAmount={allDividends.some((data) => data.isPrediction)}
        />
      )}
      <InfiniteScroll
        next={displayMoreDividends}
        dataLength={dividends.length}
        loader={null}
        height={scrollContainerHeight}
        hasMore={allDividends.length !== dividends.length}
        scrollableTarget={scrollableTarget}
      >
        <Table
          stickyHeader
          sx={{
            tableLayout: isLessSm ? "fixed" : "auto",
          }}
        >
          {!isLessSm && (
            <TableHead>
              <TableRow>
                <SortableTableHeader
                  text={t(
                    `dividends.table.${
                      isMonthlyView
                        ? "investment"
                        : isYearlyView
                        ? "year"
                        : "month"
                    }`
                  )}
                  width="30%"
                  // sortField={sortField}
                  // sortDirection={sortDirection}
                  // sortByName="name"
                  // onClick={sort}
                  // isSortable
                />
                <SortableTableHeader
                  text={t("dividends.table.status")}
                  width="10%"
                />
                <SortableTableHeader
                  text={t("dividends.table.exDate")}
                  align={view === "calendar" ? undefined : "right"}
                  tooltip={t("dividends.table.exDateTooltip")}
                  width="10%"
                  // sortField={sortField}
                  // sortDirection={sortDirection}
                  // sortByName="exDate"
                  // onClick={sort}
                  // isSortable
                />
                <SortableTableHeader
                  text={t("dividends.table.paymentDate")}
                  align={view === "calendar" ? undefined : "right"}
                  tooltip={t("dividends.table.paymentDateTooltip")}
                  width="10%"
                  // sortField={sortField}
                  // sortDirection={sortDirection}
                  // sortByName="paymentDate"
                  // onClick={sort}
                  // isSortable
                />
                {view === "calendar" ? (
                  <SortableTableHeader
                    text={t("dividends.table.frequency")}
                    width="10%"
                    // sortField={sortField}
                    // sortDirection={sortDirection}
                    // sortByName="numberOfLots"
                    // onClick={sort}
                    // isSortable
                  />
                ) : (
                  <>
                    <SortableTableHeader
                      text={t("dividends.table.numberOfLots")}
                      align="right"
                      width="10%"
                      // sortField={sortField}
                      // sortDirection={sortDirection}
                      // sortByName="numberOfLots"
                      // onClick={sort}
                      // isSortable
                    />
                    <SortableTableHeader
                      text={t("dividends.table.price")}
                      align="right"
                      width="10%"
                      // sortField={sortField}
                      // sortDirection={sortDirection}
                      // sortByName="grossAmountPerShare"
                      // onClick={sort}
                      // isSortable
                    />
                  </>
                )}

                <SortableTableHeader
                  text={
                    isDailyView
                      ? t("dividends.table.grossAmount")
                      : t("dividends.table.sum")
                  }
                  align="right"
                  width="10%"
                  // sortField={sortField}
                  // sortDirection={sortDirection}
                  // sortByName="grossAmount"
                  // onClick={sort}
                  // isSortable
                />
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {isLessSm && !fullView && (
              <TableRow
                style={{
                  visibility: "hidden",
                }}
              >
                <TableCell
                  sx={{
                    width: isLess440 ? "65%" : "50%",
                    padding: "0 !important",
                  }}
                />
                <TableCell
                  sx={{
                    padding: "0 !important",
                    width: isLess440 ? "5%" : "25%",
                  }}
                />
                <TableCell
                  sx={{
                    padding: "0 !important",
                    width: isLess440 ? "35%" : "25%",
                  }}
                />
              </TableRow>
            )}
            {!isLoading && hasNoData && (
              <TableRow>
                <TableCell colSpan={isLessSm ? 3 : view === "calendar" ? 6 : 7}>
                  <NoDataStub ml={3} />
                </TableCell>
              </TableRow>
            )}
            {dividends.map((item) => {
              const dividendsList = item.children;
              const shouldShowDividends = !(
                isYearlyView &&
                isUserDataFetched &&
                !isUserPro &&
                item.year === new Date().getFullYear() + 1
              );
              if (!dividendsList.length) return null;

              if (fullView && isLessSm && shouldShowDividends) {
                return dividendsList.map((dividend) => {
                  const investment = investments?.[dividend.investmentIds[0]];
                  return (
                    <React.Fragment
                      key={`${format(
                        new Date(dividend.paymentDate),
                        "dd.MM.yyyy"
                      )}-${dividend.investmentIds[0]}-${dividend.grossAmount}`}
                    >
                      <FullViewDividendCell
                        dividend={dividend}
                        investment={investment}
                        view={view}
                        padding={fullViewPadding}
                      />
                      <Divider
                        sx={{
                          marginTop: "16px",
                        }}
                      />
                    </React.Fragment>
                  );
                });
              }

              return (
                <React.Fragment key={`${item.month}-${item.year}`}>
                  {!isMonthlyView && !isDailyView && (
                    <MonthTableRow
                      key={`header-${item.month}-${item.year}`}
                      shouldShowDividends={shouldShowDividends}
                    >
                      <TableCell
                        colSpan={isLessSm ? 2 : view === "calendar" ? 5 : 6}
                        sx={{
                          paddingLeft: theme.spacing(isLessSm ? 4 : 6),
                          paddingRight: theme.spacing(isLessSm ? 4 : 6),
                        }}
                      >
                        <Box display="flex" alignItems="center" gap={3}>
                          <Typography variant="body1" lineHeight="30px">
                            {format(
                              new Date(
                                item.year!,
                                isYearlyView ? 0 : item.month!
                              ),
                              isYearlyView ? "yyyy" : "MMMM yyyy"
                            )}
                          </Typography>
                          {!shouldShowDividends && <PlusGradient />}
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <BlurredTypography
                          shouldBlur={
                            dividendsList.some((div) => div.isPrediction) &&
                            !isUserPro
                          }
                          blur={6}
                          variant="body2"
                        >
                          {toCurrency(item.grossAmount)}
                        </BlurredTypography>
                      </TableCell>
                    </MonthTableRow>
                  )}
                  {shouldShowDividends &&
                    dividendsList.map((dividend, index) => {
                      const investment =
                        investments?.[dividend.investmentIds[0]];
                      const isLast = index === dividendsList.length - 1;

                      return (
                        <TableRow
                          className={
                            isLast && !isMonthlyView ? "no-border" : ""
                          }
                          key={`${format(
                            new Date(dividend.paymentDate),
                            "dd.MM.yyyy"
                          )}-${dividend.investmentIds[0]}-${
                            dividend.grossAmount
                          }`}
                        >
                          <TableCell>
                            <Box display="flex" alignItems="center" gap={3}>
                              <SecurityImage
                                src={images?.[investment?.isin!]}
                                width={40}
                                height={40}
                                sx={{
                                  borderRadius: "3.6px",
                                }}
                              />
                              <Typography
                                variant="body2"
                                noWrap={isLessSm}
                                minWidth={isLessSm ? 0 : 200}
                              >
                                {investment?.standardisedName}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <DividendStatus
                              dividend={dividend}
                              small={isLessSm}
                            />
                          </TableCell>
                          {!isLessSm && (
                            <>
                              <TableCell
                                align={view === "calendar" ? "left" : "right"}
                              >
                                <BlurredTypography
                                  shouldBlur={
                                    dividend.isPrediction && !isUserPro
                                  }
                                  variant="body2"
                                  blur={6}
                                >
                                  {dividend.exDate &&
                                    format(
                                      new Date(dividend.exDate),
                                      "dd.MM.yyyy"
                                    )}
                                </BlurredTypography>
                              </TableCell>
                              <TableCell
                                align={view === "calendar" ? "left" : "right"}
                              >
                                <BlurredTypography
                                  shouldBlur={
                                    dividend.isPrediction && !isUserPro
                                  }
                                  variant="body2"
                                  blur={6}
                                >
                                  {dividend.paymentDate &&
                                    format(
                                      new Date(dividend.paymentDate),
                                      "dd.MM.yyyy"
                                    )}
                                </BlurredTypography>
                              </TableCell>
                              {view === "calendar" ? (
                                <TableCell>
                                  <DividendFrequency dividend={dividend} />
                                </TableCell>
                              ) : (
                                <>
                                  <TableCell align="right">
                                    {dividend.numberOfLots && (
                                      <Badge>
                                        {toGermanNumberFormat(
                                          dividend.numberOfLots
                                        )}
                                        x
                                      </Badge>
                                    )}
                                  </TableCell>
                                  <TableCell align="right">
                                    <BlurredTypography
                                      shouldBlur={
                                        dividend.isPrediction && !isUserPro
                                      }
                                      variant="body2"
                                      blur={6}
                                    >
                                      {toCurrency(dividend.grossAmountPerShare)}
                                    </BlurredTypography>
                                  </TableCell>
                                </>
                              )}
                            </>
                          )}

                          <TableCell align="right">
                            <BlurredTypography
                              shouldBlur={dividend.isPrediction && !isUserPro}
                              variant="body2"
                              blur={6}
                            >
                              {toCurrency(dividend.grossAmount)}
                            </BlurredTypography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </InfiniteScroll>
    </DividendsTableContainer>
  );
};
