import React, { useMemo } from "react";
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SharedBanner } from "@features/sharedPortfolio/SharedBanner";
import { StyledCard } from "@features/dividendPlanner/components/StyledCard";
import {
  useDividendAllocation,
  usePublicDividendAllocation,
} from "@generated/apiv1/hooks";
import { DonutChart } from "@components/Chart/DonutChart";
import { LegendLayout } from "@features/analysis/components/Legend";
import { CardLayout } from "@features/analysis/components/CardLayout";
import { useChartColors, useSecurityImages } from "@hooks";
import { InvestmentTypeEnum } from "@generated/apiv1";
import { generateRandomNumber, toCurrency } from "@helpers";
import { useMyself } from "@api";
import { BlurredTypography } from "@components/BlurredTypography";
import { PlusGradient } from "@components/Plus/PlusGradient";
import { NoDataStub } from "@components";
import { usePublicOrPrivateData } from "@features/sharedPortfolio/hooks/usePublicOrPrivateData";

type Props = {
  selectedAccounts: number[];
};

export const GraphTab = ({ selectedAccounts }: Props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const colors = useChartColors("dividendsChart");
  const { data: myself, isFetched: isUserFetched } = useMyself();
  const isUserPro = myself?.isUserPro;
  const { data, isLoading, isFetched } = usePublicOrPrivateData({
    // @ts-ignore
    usePrivateMethod: useDividendAllocation,
    // @ts-ignore
    usePublicMethod: usePublicDividendAllocation,
    params: {
      accountId: selectedAccounts,
    },
  });

  const logos = useSecurityImages(
    data?.allocations.map((i) => ({
      isin: i.isin,
      hasValidIsin: true,
      type: InvestmentTypeEnum._11_STOCK,
    }))
  );

  const randomChartData = useMemo(() => {
    if (!isUserFetched || isUserPro || !data) return [];

    const total = generateRandomNumber(1000, 10000);
    let allocated = 0;

    return (
      data?.allocations?.map((item, index) => {
        const randomValue =
          index === data?.allocations.length - 1
            ? total - allocated
            : generateRandomNumber(
                0,
                (total - allocated) * 2 > total
                  ? (total - allocated) / 2
                  : total - allocated
              );
        allocated += randomValue;

        return {
          id: item.isin,
          uid: item.isin,
          icon: logos[item.isin],
          value: randomValue,
          currency: "EUR",
          hasChildren: false,
          url: `/jumpToSecurity?isin=${item.isin}`,
          y: randomValue,
          name: item.standardName,
        };
      }) || []
    ).sort((a, b) => b.value - a.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserPro, data, isUserFetched]);

  const chartData = useMemo(() => {
    if (!isUserFetched) return [];

    return (
      data?.allocations?.map((item, index) => {
        return {
          id: item.isin,
          uid: item.isin,
          icon: logos[item.isin],
          value: item.total,
          currency: "EUR",
          hasChildren: false,
          url: `/jumpToSecurity?isin=${item.isin}`,
          y: item.total,
          name: item.standardName,
        };
      }) || []
    ).sort((a, b) => b.value - a.value);
  }, [data, isUserFetched, logos]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      p={isMobile ? 4 : 6}
      gap={6}
      maxWidth={1400}
      width="100%"
      flex={1}
    >
      <SharedBanner />
      <StyledCard>
        {isUserFetched && !isUserPro && <PlusGradient variant="large" mb={6} />}
        <Typography variant="h5" fontWeight={700} letterSpacing={-0.5} mb={4}>
          {t("dividends.graphPage.title")}
        </Typography>
        <Typography variant="body1" color="textSecondary" mb={6}>
          {t("dividends.graphPage.subtitle")}
        </Typography>
        <Box minHeight="500px" width="100%">
          <CardLayout
            isLoading={!isFetched || isLoading}
            noData={isFetched && !chartData.length}
            noDataComponent={NoDataStub}
            chartData={
              isUserFetched ? (isUserPro ? chartData : randomChartData) : []
            }
            chart={<DonutChart chartColors={colors} />}
            subtitleFirstLineStyle={{
              filter: isUserPro ? "none" : "blur(6px)",
            }}
            subtitleSecondLineStyle={{
              filter: isUserPro ? "none" : "blur(6px)",
            }}
            legend={
              <LegendLayout
                showInvestmentAmount
                colors={colors}
                hideForNonPro
                renderAmount={(item) => (
                  <BlurredTypography
                    shouldBlur={!isUserPro}
                    variant="body2"
                    fontWeight={400}
                    blur={6}
                  >
                    {toCurrency(item?.value)}
                  </BlurredTypography>
                )}
              />
            }
          />
        </Box>
      </StyledCard>
    </Box>
  );
};
