import { Account, Currency, Transaction } from "@api";
import { Symbol } from "@generated/apiv1";
import { BookingValues } from "./bookingValues";
import { SplitValues } from "./splitValues";
import { InvestmentValues } from "./investmentValues";

export interface TransactionWizardContext {
  currencies: Currency[];
  symbol?: Symbol;
  sepaTransaction?: Transaction;
  depot?: Account;
  error?: ApiException;
  validationError?: string;
  bookingValues: BookingValues;
  bookingValuesToRestore?: BookingValues;
  investmentValues: InvestmentValues;
  splitValues?: SplitValues;
  sourceInvestmentValues?: InvestmentValues;
  skipSummaryStep?: boolean;
  manualInvestmentSelected?: boolean;
}

export const defaultContext = {
  currencies: [],
  symbol: undefined,
  sepaTransaction: undefined,
  depot: undefined,
  error: undefined,
  bookingValues: {
    date: new Date(),
    commission: 0,
    taxAmount: 0,
    exchangeRate: 1,
  },
  bookingValuesToRestore: undefined,
  investmentValues: {
    investmentCurrency: "EUR",
  },
  splitValues: {
    date: new Date(),
    isReverse: false,
  },
  skipSummaryStep: false,
};
