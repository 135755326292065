import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Stripe } from "@generated/user";
import { formatDateDDMMMMYYYY } from "@helpers";
import { ReactComponent as TagPlus } from "@icons/tag_plus.svg";
import { plusBenefitsShort } from "../constants";
import { SubscriptionBenefit } from "./SubscriptionBenefit";

const SubscriptionView = ({ stripeData }: { stripeData: Stripe }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const subscription = stripeData.subscriptions?.[0];
  const canceledDate = subscription?.canceled_at;
  const endDate = subscription?.current_period_end;

  return (
    <Box mt={theme.spacing(4)}>
      {canceledDate && (
        <Typography
          variant="body1"
          fontSize="18px"
          lineHeight="28px"
          fontWeight={500}
          mb={theme.spacing(8)}
        >
          {t("settingsPage.subscription.activeSubscription.canceled", {
            date: formatDateDDMMMMYYYY(canceledDate),
          })}
        </Typography>
      )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        p="24px 16px"
        borderRadius={theme.spacing(2)}
        sx={{ backgroundColor: theme.palette.background.neutral }}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={theme.spacing(2)}
          mb={theme.spacing(4)}
        >
          <Typography variant="h3" fontSize="26px" lineHeight="34px">
            Copilot
          </Typography>
          <TagPlus />
        </Box>
        {endDate && (
          <Typography variant="subtitle1" lineHeight="28px">
            {t("settingsPage.subscription.activeSubscription.activeUntil", {
              date: formatDateDDMMMMYYYY(endDate),
            })}
          </Typography>
        )}
      </Box>
      <Typography
        variant="body1"
        fontSize="18px"
        lineHeight="28px"
        fontWeight={400}
        letterSpacing={-0.45}
        m="32px 0"
      >
        {t("settingsPage.subscription.activeSubscription.description")}
      </Typography>
      <Box display="flex" flexDirection="column" gap={theme.spacing(4)}>
        {plusBenefitsShort.map((benefit) => (
          <SubscriptionBenefit text={t(benefit)} variant="medium" />
        ))}
      </Box>
      <Button
        sx={{ width: "100%", margin: "32px 0 16px" }}
        variant="contained"
        color="primary"
        href={stripeData.customerLoginUrl ?? ""}
      >
        {t("settingsPage.subscription.activeSubscription.manage")}
      </Button>
      {!canceledDate && (
        <Button
          sx={{ width: "100%", border: "none" }}
          variant="outlined"
          color="primary"
          href={stripeData.customerLoginUrl ?? ""}
        >
          {t("settingsPage.subscription.activeSubscription.cancel")}
        </Button>
      )}
    </Box>
  );
};

export default SubscriptionView;
