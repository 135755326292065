import React, { useEffect, useMemo, useRef } from "react";
import Highcharts from "highcharts/highstock";
import { renderToString } from "react-dom/server";
import HighchartsReact from "highcharts-react-official";
import { Box } from "@mui/material";
import { differenceInDays } from "date-fns";
import { toAmericanShort, toCurrency, toPercent } from "@helpers";
import {
  formatDateDDMMM,
  formatDateMMMYY,
  formatYYYY,
} from "@helpers/datetime";
import { PerformanceValuationTooltip } from "@components/Chart/Tooltips/PerformanceValuationTooltip";
import { GainLossChartPercents } from "@components/Chart/Tooltips/GainLossChartPercents";
import { WithLoadingSpinner } from "@components/LoadingSpinner/WithLoadingSpinner";
import commonHighchartsOptions from "@components/Chart/commonHighchartsOptions";
import { NoDataStub } from "@components/Chart/NoDataStub";
import { ChartTooltip } from "@components/Chart/Tooltips/ChartTooltip";
import {
  SecuritiesChartType,
  SecurityChartData,
  SecurityChartMarker,
} from "@components/Chart/SecuritiesChart/types";

const getDateFormatterForXAxis = (
  startDate: Date,
  endDate: Date,
  value: string | number
) => {
  const diff = Math.abs(differenceInDays(startDate, endDate));
  if (diff - 4 > 365 * 5) return formatYYYY(value);
  if (diff < 365 / 2) return formatDateDDMMM(value);
  return formatDateMMMYY(value);
};

const defaultChartColors = [
  "#13CC89",
  "#0D8F60",
  "#e56043",
  "#4D6BDD",
  "#FF9B40",
];

type Props = {
  chartData: Array<SecurityChartData>;
  markers?: Array<SecurityChartMarker>;
  chartType: SecuritiesChartType;
  intervalType: IntervalType;
  isLoading: boolean;
  disableNavigator?: boolean;
  tooltipOptions?: Highcharts.TooltipOptions;
  displayArea?: boolean;
  currency?: string;
  isComparison?: boolean;
  showPercentage?: boolean;
};

export const SecuritiesChart = ({
  chartData,
  chartType,
  currency = "EUR",
  intervalType,
  isLoading,
  disableNavigator,
  tooltipOptions = {},
  displayArea = false,
  markers = [],
  showPercentage = false,
}: Props) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const timestamps = chartData.flatMap((securityChartData) =>
    securityChartData.data.flatMap((item) => item[0])
  );
  const minDate = Math.min(...timestamps);
  const maxDate = Math.max(...timestamps);
  const hasChartData = Boolean(chartData?.some((item) => item.data.length));

  useEffect(() => {
    if (chartComponentRef.current) {
      chartComponentRef.current.chart.xAxis[0].setExtremes(
        minDate,
        maxDate,
        true,
        false
      );
    }
  }, [minDate, maxDate]);

  let lineColor = chartData[0]?.color;
  let areaColor: string[] | Highcharts.ColorType | undefined;
  let areaOpacity = 0;

  if (chartData[0]?.data[0]) {
    const startX = chartData[0].data[0][1];
    const endX = chartData[0].data[chartData[0].data.length - 1][1];
    lineColor =
      (startX < endX
        ? defaultChartColors[0]
        : startX > endX
        ? defaultChartColors[2]
        : chartData[0].color) || defaultChartColors[0];
    areaColor = lineColor;
    areaOpacity = 0;
    if (chartData.length === 1) {
      chartData[0].color = lineColor;
      areaOpacity = 0.1;
    }
  }

  // @ts-ignore
  const options: Highcharts.Options = useMemo(
    () => ({
      chart: {
        type: "line",
        height: 480,
        spacingRight: disableNavigator ? 0 : 20,
        ...commonHighchartsOptions.chart,
      },
      credits: {
        enabled: false,
      },
      title: { text: "" },
      colors: defaultChartColors,
      tooltip: {
        split: true,
        borderRadius: 4,
        borderColor: "#FFF",
        backgroundColor: "#FFF",
        shape: "square",
        stickOnContact: true,
        valueSuffix: "%",
        useHTML: true,
        shadow: false,
        padding: 0,
        formatter: function () {
          if (this.point.series.type === "flags") {
            return renderToString(
              <ChartTooltip>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    color: "#000",
                  }}
                >
                  {/*@ts-ignore*/}
                  {this.point.text}
                </div>
              </ChartTooltip>
            );
          } else {
            return PerformanceValuationTooltip({
              formatter: (value) =>
                chartType === "valuation" ? (
                  showPercentage ? (
                    toPercent(((value as number) ?? 0) / 100)
                  ) : (
                    toCurrency(value as number, currency)
                  )
                ) : (
                  <GainLossChartPercents value={(value as number) / 100} />
                ),
              points: this.points,
              x: this.x,
            });
          }
        },
        ...tooltipOptions,
      },
      xAxis: {
        type: "datetime",
        tickLength: 0,
        lineColor: "#EEF2F6",
        crosshair: {
          color: "#EEF2F6",
        },
        labels: {
          overflow: "justify",
          style: {
            whiteSpace: "nowrap",
          },
          formatter: function (data) {
            const selectedDates = this.chart.xAxis[0].getExtremes();
            const startDate = new Date(selectedDates.min);
            const endDate = new Date(selectedDates.max);

            return renderToString(
              <div
                style={{
                  fontFamily: "Averta,Arial",
                  fontSize: "11px",
                  fontWeight: 400,
                  color: "#737E93",
                  whiteSpace: "nowrap",
                }}
              >
                {getDateFormatterForXAxis(startDate, endDate, data.value)}
              </div>
            );
          },
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        tickAmount: 6,
        labels: {
          formatter: (data) => {
            return renderToString(
              <div
                style={{
                  fontFamily: "Averta,Arial",
                  fontSize: "11px",
                  fontWeight: 400,
                  color: "#737E93",
                }}
              >
                {chartType === "valuation"
                  ? toAmericanShort(data.value as number, 0, 3)
                  : toPercent((data.value as number) / 100, "auto", 0, 0)}
              </div>
            );
          },
        },
      },
      legend: { enabled: false },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            symbol: "circle",
            states: {
              hover: {
                radius: 4,
                lineWidth: 1,
                lineWidthPlus: 0,
              },
            },
          },
          lineWidth: 2,
        },
        area: {
          threshold: -Infinity,
          fillColor: {
            stops: [
              [
                0,
                Highcharts.color(areaColor as string)
                  .setOpacity(areaOpacity)
                  .get("rgba") as string,
              ],
              [
                1,
                Highcharts.color(areaColor as string)
                  .setOpacity(0)
                  .get("rgba") as string,
              ],
            ],
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
          },
        },
      },
      navigator: {
        enabled: !disableNavigator,
        height: 66,
        series: {
          type: "line",
          color: "#4D6BDD",
        },
        xAxis: {
          lineWidth: 1,
          type: "datetime",
          tickLength: 0,
          lineColor: "#EEF2F6",
          crosshair: {
            color: "#EEF2F6",
          },
          minorGridLineWidth: 10,
          labels: {
            overflow: "justify",
            formatter: function (data) {
              const selectedDates = this.chart.xAxis[0].getExtremes();
              const startDate = new Date(selectedDates.min);
              const endDate = new Date(selectedDates.max);

              return renderToString(
                <div
                  style={{
                    fontFamily: "Averta,Arial",
                    fontSize: "11px",
                    fontWeight: 400,
                    color: "#737E93",
                  }}
                >
                  {getDateFormatterForXAxis(startDate, endDate, data.value)}
                </div>
              );
            },
          },
        },
        outlineWidth: 0,
        maskFill: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [
              0,
              Highcharts.color("#4D6BDD").setOpacity(0).get("rgba") as string,
            ],
            [
              1,
              Highcharts.color("#4D6BDD").setOpacity(0.1).get("rgba") as string,
            ],
          ],
        },
        handles: {
          enabled: true,
          height: 70,
          width: 26,
          symbols: [
            "url(https://www.finanzfluss.de/images/chart-handler.svg)",
            "url(https://www.finanzfluss.de/images/chart-handler.svg)",
          ],
        },
      },
    }),
    [
      tooltipOptions,
      areaColor,
      areaOpacity,
      disableNavigator,
      chartType,
      currency,
      showPercentage,
    ]
  );

  useEffect(() => {
    chartComponentRef.current?.chart?.reflow();
  }, [options]);

  if (!hasChartData && !isLoading)
    return (
      <Box
        height="480px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <NoDataStub />
      </Box>
    );

  return (
    <WithLoadingSpinner isLoading={isLoading}>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...options,
          series: [
            {
              ...chartData[0],
              id: "dataseries",
              type: displayArea ? "area" : "line",
            },
            {
              type: "flags",
              accessibility: {
                exposeAsGroupOnly: true,
                description: "Flagged events.",
              },
              data: markers,
              onSeries: "dataseries",
              shape: "circlepin",
              width: 16,
              height: 16,
            },
            ...chartData.slice(1),
          ],
        }}
        ref={chartComponentRef}
      />
    </WithLoadingSpinner>
  );
};
