/* tslint:disable */
/* eslint-disable */
/**
 * Finanzfluss API
 * An API for the <a href=\"https://www.finanzfluss.de/\">Finanzfluss</a> backend. Work in progress.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@finanzfluss.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { LoginData } from '../model';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Login a user with email and password.   The resulting LoginData object contains a JWT access token for accessing this API and the <a href=\"https://wealthapi.eu/en/api-documentation/\">API of our partner WealthAPI</a>.  When the user has OTP MFA enabled, then the endpoint will respond with a \'OTP Required\' response. In this case, resend the request with the optional otpCode or otpBackupCode fields.  When the user has not yet confirmed their email address, then the endpoint will respond with a \'Email not confirmed\' response.
         * @summary Login a user with email and password & retrieve an access token
         * @param {string} email The email of the user to login with
         * @param {string} password The password of the user to login with
         * @param {string} [otpCode] A valid OTP code that the user retrieved from a configured OTP client
         * @param {string} [otpBackupCode] A valid OTP backup code that the user saved when registering the otp device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (email: string, password: string, otpCode?: string, otpBackupCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('login', 'email', email)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('login', 'password', password)
            const localVarPath = `/user/api/v1/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }

            if (otpCode !== undefined) {
                localVarQueryParameter['otpCode'] = otpCode;
            }

            if (otpBackupCode !== undefined) {
                localVarQueryParameter['otpBackupCode'] = otpBackupCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sign-in a user with Google. When the user does not exist yet, create a new user  The resulting LoginData object contains a JWT access token for accessing this API and the <a href=\"https://wealthapi.eu/en/api-documentation/\">API of our partner WealthAPI</a>.  When the user has OTP MFA enabled, then the endpoint will respond with a \'OTP Required\' response. In this case, resend the request with the optional otpCode or otpBackupCode fields.  When the user has not yet confirmed their email address, then the endpoint will respond with a \'Email not confirmed\' response.
         * @summary Sign in or sign up a user with Google
         * @param {string} idToken  An OpenID Connect JWT token that identifies the user.  See https://cloud.google.com/docs/authentication/token-types#id for details.
         * @param {string} [otpCode] A valid OTP code that the user retrieved from a configured OTP client
         * @param {string} [otpBackupCode] A valid OTP backup code that the user saved when registering the otp device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithGoogle: async (idToken: string, otpCode?: string, otpBackupCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idToken' is not null or undefined
            assertParamExists('loginWithGoogle', 'idToken', idToken)
            const localVarPath = `/user/api/v1/auth/loginWithGoogle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (idToken !== undefined) {
                localVarQueryParameter['idToken'] = idToken;
            }

            if (otpCode !== undefined) {
                localVarQueryParameter['otpCode'] = otpCode;
            }

            if (otpBackupCode !== undefined) {
                localVarQueryParameter['otpBackupCode'] = otpBackupCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use a JWT refresh token to retrieve a new JWT access token.
         * @summary Refresh the access token with a valid refresh token
         * @param {string} refreshToken A valid not expired refresh token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (refreshToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshToken' is not null or undefined
            assertParamExists('refreshToken', 'refreshToken', refreshToken)
            const localVarPath = `/user/api/v1/auth/refreshToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (refreshToken !== undefined) {
                localVarQueryParameter['refresh_token'] = refreshToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * Login a user with email and password.   The resulting LoginData object contains a JWT access token for accessing this API and the <a href=\"https://wealthapi.eu/en/api-documentation/\">API of our partner WealthAPI</a>.  When the user has OTP MFA enabled, then the endpoint will respond with a \'OTP Required\' response. In this case, resend the request with the optional otpCode or otpBackupCode fields.  When the user has not yet confirmed their email address, then the endpoint will respond with a \'Email not confirmed\' response.
         * @summary Login a user with email and password & retrieve an access token
         * @param {string} email The email of the user to login with
         * @param {string} password The password of the user to login with
         * @param {string} [otpCode] A valid OTP code that the user retrieved from a configured OTP client
         * @param {string} [otpBackupCode] A valid OTP backup code that the user saved when registering the otp device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(email: string, password: string, otpCode?: string, otpBackupCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(email, password, otpCode, otpBackupCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.login']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sign-in a user with Google. When the user does not exist yet, create a new user  The resulting LoginData object contains a JWT access token for accessing this API and the <a href=\"https://wealthapi.eu/en/api-documentation/\">API of our partner WealthAPI</a>.  When the user has OTP MFA enabled, then the endpoint will respond with a \'OTP Required\' response. In this case, resend the request with the optional otpCode or otpBackupCode fields.  When the user has not yet confirmed their email address, then the endpoint will respond with a \'Email not confirmed\' response.
         * @summary Sign in or sign up a user with Google
         * @param {string} idToken  An OpenID Connect JWT token that identifies the user.  See https://cloud.google.com/docs/authentication/token-types#id for details.
         * @param {string} [otpCode] A valid OTP code that the user retrieved from a configured OTP client
         * @param {string} [otpBackupCode] A valid OTP backup code that the user saved when registering the otp device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginWithGoogle(idToken: string, otpCode?: string, otpBackupCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginWithGoogle(idToken, otpCode, otpBackupCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.loginWithGoogle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Use a JWT refresh token to retrieve a new JWT access token.
         * @summary Refresh the access token with a valid refresh token
         * @param {string} refreshToken A valid not expired refresh token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(refreshToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(refreshToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.refreshToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * Login a user with email and password.   The resulting LoginData object contains a JWT access token for accessing this API and the <a href=\"https://wealthapi.eu/en/api-documentation/\">API of our partner WealthAPI</a>.  When the user has OTP MFA enabled, then the endpoint will respond with a \'OTP Required\' response. In this case, resend the request with the optional otpCode or otpBackupCode fields.  When the user has not yet confirmed their email address, then the endpoint will respond with a \'Email not confirmed\' response.
         * @summary Login a user with email and password & retrieve an access token
         * @param {AuthApiLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(requestParameters: AuthApiLoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<LoginData> {
            return localVarFp.login(requestParameters.email, requestParameters.password, requestParameters.otpCode, requestParameters.otpBackupCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Sign-in a user with Google. When the user does not exist yet, create a new user  The resulting LoginData object contains a JWT access token for accessing this API and the <a href=\"https://wealthapi.eu/en/api-documentation/\">API of our partner WealthAPI</a>.  When the user has OTP MFA enabled, then the endpoint will respond with a \'OTP Required\' response. In this case, resend the request with the optional otpCode or otpBackupCode fields.  When the user has not yet confirmed their email address, then the endpoint will respond with a \'Email not confirmed\' response.
         * @summary Sign in or sign up a user with Google
         * @param {AuthApiLoginWithGoogleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithGoogle(requestParameters: AuthApiLoginWithGoogleRequest, options?: RawAxiosRequestConfig): AxiosPromise<LoginData> {
            return localVarFp.loginWithGoogle(requestParameters.idToken, requestParameters.otpCode, requestParameters.otpBackupCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Use a JWT refresh token to retrieve a new JWT access token.
         * @summary Refresh the access token with a valid refresh token
         * @param {AuthApiRefreshTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(requestParameters: AuthApiRefreshTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<LoginData> {
            return localVarFp.refreshToken(requestParameters.refreshToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for login operation in AuthApi.
 * @export
 * @interface AuthApiLoginRequest
 */
export interface AuthApiLoginRequest {
    /**
     * The email of the user to login with
     * @type {string}
     * @memberof AuthApiLogin
     */
    readonly email: string

    /**
     * The password of the user to login with
     * @type {string}
     * @memberof AuthApiLogin
     */
    readonly password: string

    /**
     * A valid OTP code that the user retrieved from a configured OTP client
     * @type {string}
     * @memberof AuthApiLogin
     */
    readonly otpCode?: string

    /**
     * A valid OTP backup code that the user saved when registering the otp device
     * @type {string}
     * @memberof AuthApiLogin
     */
    readonly otpBackupCode?: string
}

/**
 * Request parameters for loginWithGoogle operation in AuthApi.
 * @export
 * @interface AuthApiLoginWithGoogleRequest
 */
export interface AuthApiLoginWithGoogleRequest {
    /**
     *  An OpenID Connect JWT token that identifies the user.  See https://cloud.google.com/docs/authentication/token-types#id for details.
     * @type {string}
     * @memberof AuthApiLoginWithGoogle
     */
    readonly idToken: string

    /**
     * A valid OTP code that the user retrieved from a configured OTP client
     * @type {string}
     * @memberof AuthApiLoginWithGoogle
     */
    readonly otpCode?: string

    /**
     * A valid OTP backup code that the user saved when registering the otp device
     * @type {string}
     * @memberof AuthApiLoginWithGoogle
     */
    readonly otpBackupCode?: string
}

/**
 * Request parameters for refreshToken operation in AuthApi.
 * @export
 * @interface AuthApiRefreshTokenRequest
 */
export interface AuthApiRefreshTokenRequest {
    /**
     * A valid not expired refresh token
     * @type {string}
     * @memberof AuthApiRefreshToken
     */
    readonly refreshToken: string
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * Login a user with email and password.   The resulting LoginData object contains a JWT access token for accessing this API and the <a href=\"https://wealthapi.eu/en/api-documentation/\">API of our partner WealthAPI</a>.  When the user has OTP MFA enabled, then the endpoint will respond with a \'OTP Required\' response. In this case, resend the request with the optional otpCode or otpBackupCode fields.  When the user has not yet confirmed their email address, then the endpoint will respond with a \'Email not confirmed\' response.
     * @summary Login a user with email and password & retrieve an access token
     * @param {AuthApiLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(requestParameters: AuthApiLoginRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).login(requestParameters.email, requestParameters.password, requestParameters.otpCode, requestParameters.otpBackupCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sign-in a user with Google. When the user does not exist yet, create a new user  The resulting LoginData object contains a JWT access token for accessing this API and the <a href=\"https://wealthapi.eu/en/api-documentation/\">API of our partner WealthAPI</a>.  When the user has OTP MFA enabled, then the endpoint will respond with a \'OTP Required\' response. In this case, resend the request with the optional otpCode or otpBackupCode fields.  When the user has not yet confirmed their email address, then the endpoint will respond with a \'Email not confirmed\' response.
     * @summary Sign in or sign up a user with Google
     * @param {AuthApiLoginWithGoogleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginWithGoogle(requestParameters: AuthApiLoginWithGoogleRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginWithGoogle(requestParameters.idToken, requestParameters.otpCode, requestParameters.otpBackupCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use a JWT refresh token to retrieve a new JWT access token.
     * @summary Refresh the access token with a valid refresh token
     * @param {AuthApiRefreshTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refreshToken(requestParameters: AuthApiRefreshTokenRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).refreshToken(requestParameters.refreshToken, options).then((request) => request(this.axios, this.basePath));
    }
}

