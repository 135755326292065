import React, { useState } from "react";
import {
  CaptionLabelProps,
  DateRange,
  DayPicker,
  Matcher,
  useNavigation,
} from "react-day-picker";
import styled from "@emotion/styled";
import { de, enUS } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import "react-day-picker/dist/style.css";
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { format, setYear, startOfMonth } from "date-fns";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",

  "& .rdp": {
    "--rdp-cell-size": "38px;",
    "--rdp-caption-font-size": "18px;",
    "--rdp-accent-color": theme.palette.primary.main,
    "--rdp-background-color": theme.palette.background.subtle,
    "--rdp-accent-color-dark": theme.palette.primary.dark,
    "--rdp-background-color-dark": theme.palette.primary.dark,
    "--rdp-outline": "2px solid var(--rdp-accent-color);",
    "--rdp-outline-selected": "3px solid var(--rdp-accent-color);",
  },

  "& .rdp-nav_button": {
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    "&:hover:not([disabled]):not(.rdp-day_selected)": {
      backgroundColor: theme.palette.background.subtle,
    },
  },

  "& .rdp-head_cell": {
    fontSize: "12px",
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
}));

const DateContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "4px",

  "& .MuiInputBase-root::before, & .MuiInputBase-root::after": {
    display: "none",
  },

  "& .MuiInputBase-input": {
    padding: "5px 32px 5px 8px !important",
  },

  "& .MuiButtonBase-root": {
    height: "100%",
  },
}));

const CaptionLabel = ({ displayMonth }: CaptionLabelProps) => {
  const { goToMonth } = useNavigation();
  const { i18n } = useTranslation();

  const handleYearChange = (e: SelectChangeEvent<number>) => {
    const newMonth = setYear(
      startOfMonth(displayMonth),
      Number(e.target.value)
    );
    goToMonth(newMonth);
  };

  const maxYear = new Date().getFullYear();

  return (
    <DateContainer display="flex" alignItems="center">
      <Typography variant="body1">
        {format(displayMonth, "MMMM", {
          locale: i18n.language === "de" ? de : enUS,
        })}
      </Typography>
      <Select
        variant="standard"
        id="demo-simple-select-standard"
        value={displayMonth.getFullYear()}
        onChange={handleYearChange}
        MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
        sx={{ "& .MuiSelect-icon": { marginTop: 0 } }}
      >
        {Array.from({ length: maxYear - 1900 + 1 }, (_, i) => (
          <MenuItem key={i} value={maxYear - i}>
            {maxYear - i}
          </MenuItem>
        ))}
      </Select>
    </DateContainer>
  );
};

export interface DateRangePickerProps {
  className?: string;
  date?: Date;
  onChange: (from?: Date, to?: Date) => void;
  defaultFrom?: Date;
  defaultTo?: Date;
  defaultMonthFrom?: Date;
  to?: Date;
  from?: Date;
  disabledDays?: Matcher | Matcher[] | undefined;
}

export const DateRangePicker = ({
  date = new Date(),
  to,
  from,
  defaultFrom,
  defaultTo,
  defaultMonthFrom,
  onChange,
  className,
  disabledDays,
}: DateRangePickerProps) => {
  const { i18n } = useTranslation();
  const [initialMonthFrom] = useState(defaultMonthFrom ?? defaultFrom);
  const [innerFrom, setInnerFrom] = useState<Date | undefined>(
    from || defaultFrom
  );
  const [innerTo, setInnerTo] = useState<Date | undefined>(to || defaultTo);

  const selectedDays: DateRange = {
    from: from || innerFrom,
    to: to || innerTo,
  };

  const handleDayClick = (range: DateRange | undefined) => {
    setInnerFrom(range?.from);
    if (range?.to) {
      onChange(range?.from, range?.to);
    }
    setInnerTo(range?.to);
  };

  return (
    <Container className={className}>
      <DayPicker
        locale={i18n.language === "de" ? de : enUS}
        onSelect={handleDayClick}
        mode="range"
        showOutsideDays
        selected={selectedDays}
        weekStartsOn={1}
        numberOfMonths={2}
        defaultMonth={initialMonthFrom}
        components={{
          CaptionLabel,
          IconLeft: KeyboardArrowLeftIcon,
          IconRight: KeyboardArrowRightIcon,
        }}
        disabled={disabledDays}
      />
    </Container>
  );
};
