import React, { useEffect, useMemo, useRef, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import addSankeyModule from "highcharts/modules/sankey";
import { renderToString } from "react-dom/server";
import { Box, styled } from "@mui/material";
import i18next from "i18next";
import { toCurrency } from "@helpers";
import { ChartTooltip } from "@components/Chart/Tooltips/ChartTooltip";
import { CashflowChartLegend } from "@components/Chart/CashflowCharts/CashflowChartLegend";
import { useChartColors } from "@hooks";
import { ComponentsTheme } from "@theming/types";
import { DonutChartData } from "./types";

addSankeyModule(Highcharts);

// Highcharts.Point.prototype.defaultPointMouseOut =
//   Highcharts.Point.prototype.onMouseOut;
// Highcharts.seriesTypes.sankey.prototype.pointClass.prototype.defaultSetState =
//   Highcharts.seriesTypes.sankey.prototype.pointClass.prototype.setState;

// Highcharts.Point.prototype.onMouseOut = function () {
//   if (this.series.chart.getSelectedPoints().indexOf(this) < 0) {
//     this.defaultPointMouseOut();
//   }
// };

export const SankeyChartContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(10),

  [theme.breakpoints.down(950)]: {
    flexDirection: "column",
  },
}));

const getColors = (
  chartData: DonutChartData[],
  colors: ComponentsTheme["charts"]["cashflowChart"]["categories"]
): Record<
  string,
  {
    link: string;
    node: string;
  }
> => {
  return chartData.reduce((acc, chartItem) => {
    return {
      ...acc,
      [chartItem.name]: {
        link: colors?.[chartItem.name]?.light || "#DBDBDD",
        node: colors?.[chartItem.name]?.dark || "#15284b",
      },
      ...(chartItem.children
        ? chartItem.children.reduce(
            (acc, childItem) => ({
              ...acc,
              [childItem.name === chartItem.name
                ? `${childItem.name}-1`
                : childItem.name]: {
                link: colors?.[chartItem.name]?.light || "#DBDBDD",
                node: colors?.[chartItem.name]?.dark || "#15284b",
              },
            }),
            {}
          )
        : []),
    };
  }, {});
};

const formatChartData = (
  chartData: DonutChartData[],
  colors: ComponentsTheme["charts"]["cashflowChart"]["categories"]
) => {
  const mappedColors = getColors(chartData, colors);

  const incomeNode = chartData.find(
    (chartItem) => chartItem.name === "Einnahmen"
  );

  if (chartData[0].id === "no-spending") {
    return [
      [
        "noSpending",
        mappedColors?.["Keine Ausgaben"]?.link,
        "noSpending",
        "noSpending-1",
        50,
      ],
    ];
  }

  return chartData.reduce(
    function mapData(acc, chartItem): (string | number)[][] {
      const isSecondLevelItem = Boolean(chartItem.children?.length);
      if (!chartItem.children || chartItem.name === "Einnahmen") return acc;

      if (chartItem.name === "Nicht klassifiziert")
        return [
          ...acc,
          [
            chartItem.id,
            mappedColors[chartItem.name]?.link,
            "Einnahmen-1",
            chartItem.name,
            Math.abs(chartItem.value),
          ],
        ];

      return [
        ...acc,
        ...(isSecondLevelItem
          ? [
              [
                chartItem.id,
                mappedColors[chartItem.name]?.link,
                "Einnahmen-1",
                chartItem.name,
                Math.abs(chartItem.value),
              ],
            ]
          : []),
        ...chartItem.children.map((childItem) => [
          childItem.id === chartItem.id ? `${childItem.id}-1` : childItem.id,
          mappedColors[
            childItem.name === chartItem.name
              ? `${childItem.name}-1`
              : childItem.name
          ]?.link,
          chartItem.name,
          childItem.name === chartItem.name
            ? `${childItem.name}-1`
            : childItem.name,
          Math.abs(childItem.value),
        ]),
        ...chartItem.children.reduce(mapData, [] as (string | number)[][]),
      ];
    },
    [
      ...(incomeNode
        ? [
            [
              incomeNode?.id,
              colors?.["Einnahmen"]?.light,
              "Einnahmen",
              "Einnahmen-1",
              incomeNode?.value,
            ],
          ]
        : []),
    ] as (string | number)[][]
  );
};

const formatNodes = (
  chartData: DonutChartData[],
  colors: ComponentsTheme["charts"]["cashflowChart"]["categories"]
) => {
  const mappedColors = getColors(chartData, colors);

  if (chartData[0].id === "no-spending") {
    return [
      {
        id: "noSpending",
        color: mappedColors?.["Keine Ausgaben"]?.node,
      },
      {
        id: "noSpending-1",
        color: mappedColors?.["Keine Ausgaben"]?.node,
      },
    ];
  }

  return chartData.reduce(
    function mapData(acc, chartItem): { id: string; color: string }[] {
      if (chartItem.name === "Nicht klassifiziert")
        return [
          ...acc,
          {
            id: chartItem.name,
            color: mappedColors[chartItem.name]?.node,
          },
        ];

      return [
        ...acc,
        {
          id: chartItem.name,
          color: mappedColors[chartItem.name]?.node,
        },
        ...(chartItem.children
          ? chartItem.children.reduce((acc, childItem) => {
              return [
                ...acc,
                {
                  id:
                    childItem.name === chartItem.name
                      ? `${childItem.name}-1`
                      : childItem.name,
                  color:
                    mappedColors[
                      childItem.name === chartItem.name
                        ? `${childItem.name}-1`
                        : childItem.name
                    ]?.node,
                },
              ];
            }, [] as { id: string; color: string }[])
          : []),
      ];
    },
    [
      {
        id: "Einnahmen-1",
        color: "#44536f",
      },
    ] as { id: string; color: string }[]
  );
};

const options: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  title: {
    text: "",
  },
  xAxis: {
    offset: 0,
  },
  tooltip: {
    headerFormat: "",
    useHTML: true,
    borderRadius: 4,
    borderColor: "transparent",
    backgroundColor: "transparent",
    shadow: false,
    style: {
      boxShadow: "none",
      fontFamily: "var(--ff-font-family-base)",
      fontWeight: "400",
      fontSize: "12px",
    },
    // @ts-ignore
    shape: "square",
    stickOnContact: true,
    // @ts-ignore
    nodeFormatter: function () {
      if (
        // @ts-ignore
        this.name === "Einnahmen-1" ||
        // @ts-ignore
        this.name === "noSpending-1"
      )
        return "";

      // @ts-ignore
      if (this.name === "noSpending") {
        return renderToString(
          <ChartTooltip>
            <>
              {i18next.t("noExpenses")} {toCurrency(0)}
            </>
          </ChartTooltip>
        );
      }

      return renderToString(
        <ChartTooltip>
          {/*@ts-ignore*/}
          {this.name.replace("-1", "")}: {toCurrency(this.sum)}
        </ChartTooltip>
      );
    },
    pointFormatter: function () {
      // @ts-ignore
      if (this.fromNode.name === "noSpending") {
        return renderToString(
          <ChartTooltip>
            <>
              {i18next.t("noExpenses")} {toCurrency(0)}
            </>
          </ChartTooltip>
        );
      }
      // @ts-ignore
      if (this.fromNode.name === "Einnahmen")
        return renderToString(
          <ChartTooltip>
            <>
              {/* @ts-ignore */}
              {i18next.t("revenue")} {toCurrency(this.weight)}
            </>
          </ChartTooltip>
        );

      return renderToString(
        <ChartTooltip>
          <>
            {/* @ts-ignore */}
            {this.toNode.name.replace("-1", "")}: {toCurrency(this.weight)}
          </>
        </ChartTooltip>
      );
    },
  },
  plotOptions: {
    sankey: {
      nodePadding: 20,
      minLinkWidth: 2,
      // @ts-ignore
      borderRadius: 0,
    },
  },
  series: {
    // @ts-ignore
    type: "sankey",
    keys: ["id", "color", "from", "to", "weight"],
    linkOpacity: 1,
    sankey: {
      nodePadding: 100,
      minLinkWidth: 10,
    },
    node: {
      style: {
        borderRadius: 0,
      },
    },
    dataLabels: {
      allowOverlap: false,
      align: "right",
      shape: "callout",
      color: "#15284b",
      x: -25,
      padding: 0,
      style: {
        textOutline: 0,
        fontFamily: "var(--ff-font-family-base)",
        fontSize: "12px",
        fontWeight: 400,
      },
      useHTML: true,
      nodeFormatter: function () {
        if (
          // @ts-ignore
          this.point.name === "Einnahmen-1" ||
          // @ts-ignore
          this.point.name === "noSpending-1"
        )
          return "";

        // @ts-ignore
        if (this.point.name === "noSpending") {
          return renderToString(
            <span style={{ marginLeft: "25px" }}>
              {`${i18next.t("noExpenses")} ${toCurrency(0)}`}
            </span>
          );
        }

        return renderToString(
          <span
            style={{
              marginLeft:
                // @ts-ignore
                this.point.name === "Einnahmen" ? "25px" : "0px",
            }}
          >
            {/*@ts-ignore*/}
            {this.key.replace("-1", "")}: {toCurrency(this.point.sum)}
          </span>
        );
      },
    },
  },
};

type Props = {
  chartData: DonutChartData[];
  onLegendClick?: (id: string | null) => void;
  displayLegend?: boolean;
  isLoading?: boolean;
  uniqueKey?: string;
};

export const SankeyChart = ({
  chartData,
  onLegendClick,
  displayLegend = true,
  isLoading,
  uniqueKey,
}: Props) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const colors = useChartColors("cashflowChart").categories;

  const formattedChartData = useMemo(
    () => formatChartData(chartData, colors!),
    [chartData, colors]
  );
  const formattedNodes = useMemo(
    () => formatNodes(chartData, colors!),
    [chartData, colors]
  );
  const chartHeight =
    chartData.reduce(
      (acc, chartItem) => acc + (chartItem.children?.length || 0) - 1,
      0
    ) *
      47 -
    30;

  const chartOptions = useMemo(
    () => ({
      ...options,
      chart: {
        height: chartHeight > 400 ? chartHeight : 400,
      },
      series: {
        ...options.series,
        data: formattedChartData,
        nodes: formattedNodes,
      },
    }),
    [formattedNodes, formattedChartData, chartHeight]
  );

  useEffect(() => {
    chartComponentRef.current?.chart?.reflow();
  }, [chartOptions]);

  const handleLegendClick = (id: string) => {
    if (activeItem === id) {
      setActiveItem(null);
      onLegendClick?.(null);
    } else {
      setActiveItem(id);
      onLegendClick?.(id);
    }
  };

  return (
    <SankeyChartContainer>
      <CashflowChartLegend
        data={chartData}
        displayLegend={displayLegend}
        onLegendClick={handleLegendClick}
        activeLegend={activeItem || null}
      />
      <Box flex="1 1 auto">
        <Box height={chartData[0].id === "no-spending" ? "400px" : undefined}>
          <HighchartsReact
            ref={chartComponentRef}
            highcharts={Highcharts}
            options={chartOptions}
            key={uniqueKey}
          />
        </Box>
      </Box>
    </SankeyChartContainer>
  );
};
