import React from "react";
import Highcharts from "highcharts";
import { renderToString } from "react-dom/server";
import HighchartsReact from "highcharts-react-official";
import { getDateFormatter, toAmericanShort, toCurrency } from "@helpers";
import { SingleAccountChartTooltip } from "@components/Chart/Tooltips/SingleAccountChartTooltip";
import { WithLoadingSpinner } from "@components/LoadingSpinner/WithLoadingSpinner";
import commonHighchartsOptions from "@components/Chart/commonHighchartsOptions";

const options: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  chart: { type: "line", ...commonHighchartsOptions.chart },
  title: { text: "" },
  colors: ["#0D8F60"],
  tooltip: {
    split: true,
    ...commonHighchartsOptions.tooltip,
    padding: 0,
    formatter: function () {
      return SingleAccountChartTooltip({
        formatter: (value) => toCurrency(value as number),
        points: this.points,
        x: this.x,
      });
    },
  },
  xAxis: {
    type: "datetime",
    tickLength: 0,
    lineColor: "#EEF2F6",
    crosshair: {
      color: "#EEF2F6",
    },
  },
  yAxis: {
    title: {
      text: "",
    },
    tickAmount: 6,
    labels: {
      formatter: (data) => {
        return renderToString(
          <div
            style={{
              fontFamily: "Averta,Arial",
              fontSize: "11px",
              fontWeight: 400,
              color: "#737E93",
            }}
          >
            {toAmericanShort(data.value as number)}
          </div>
        );
      },
    },
  },
  legend: { enabled: false },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
        symbol: "circle",
        states: {
          hover: {
            radius: 4,
            lineWidth: 1,
            lineWidthPlus: 0,
          },
        },
      },
      lineWidth: 2,
    },
  },
};

type Props = {
  chartData: number[][];
  isLoading?: boolean;
  intervalType: IntervalType;
  onClick?: (val: number, date: Date) => void;
};

export const SingleAccountChart = ({
  chartData,
  isLoading,
  intervalType,
  onClick,
}: Props) => {
  return (
    <WithLoadingSpinner isLoading={Boolean(isLoading)}>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...options,
          series: [{ data: chartData }],
          xAxis: {
            ...options.xAxis,
            labels: {
              overflow: "justify",
              formatter: (data: { value: string | number }) => {
                return renderToString(
                  <div
                    style={{
                      fontFamily: "Averta,Arial",
                      fontSize: "11px",
                      fontWeight: 400,
                      color: "#737E93",
                    }}
                  >
                    {getDateFormatter(intervalType, data.value)}
                  </div>
                );
              },
            },
          },
          chart: {
            ...options.chart,
            events: {
              click: function () {
                onClick &&
                  // @ts-ignore
                  onClick(this.hoverPoint.y, new Date(this.hoverPoint.x));
              },
            },
          },
        }}
      />
    </WithLoadingSpinner>
  );
};
