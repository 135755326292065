import React from "react";
import { renderToString } from "react-dom/server";
import Highcharts from "highcharts";
import { ChartTooltip } from "@components/Chart/Tooltips/ChartTooltip";
import { formatDateDDMMYY } from "@helpers/datetime";
import { splitFormatter } from "@components/Chart/Tooltips/chartSplitFormatter";

type Props = {
  formatter: (value?: string | number | null) => React.ReactNode;
  x?: string | number;
  points?: Highcharts.TooltipFormatterContextObject[];
};

export const SingleAccountChartTooltip = ({ x, points, formatter }: Props) => {
  return [
    renderToString(
      <ChartTooltip>
        <div style={{ fontSize: "10px", fontWeight: 500, color: "#737E93" }}>
          {formatDateDDMMYY(x)}
        </div>
      </ChartTooltip>
    ),
    splitFormatter(formatter, points?.[0]),
    splitFormatter(formatter, points?.[1]),
    splitFormatter(formatter, points?.[2]),
  ];
};
