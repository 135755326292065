import { uniqBy } from "lodash";
import { RangeValue } from "@components/Chart/ComparisonChart/types";
import { SecurityMetricValue } from "../components/SecurityMetrics";
import { SECURITY_KEY, useSettings } from "./useSettings";

export const useFundamentalsSettings = (
  { defaultMetrics = [] }: { defaultMetrics: string[] } = { defaultMetrics: [] }
) => {
  const query = useSettings<SecurityMetricValue>(SECURITY_KEY);
  const savedMetrics: SecurityMetricValue[] =
    query.settings ||
    defaultMetrics?.map((mainMetricKey) => ({ mainMetricKey }));

  const handleShow = (mainMetricKey: string) => {
    const newMetrics = uniqBy(
      [...savedMetrics, { mainMetricKey }],
      "mainMetricKey"
    );
    query.save(newMetrics);
  };

  const handleHide = (mainMetricKey: string) => {
    const newMetrics = savedMetrics.filter(
      (metric) => metric.mainMetricKey !== mainMetricKey
    );
    query.save(newMetrics);
  };

  const handleChange = (
    mainMetricKey: string,
    val: {
      range?: RangeValue;
      secondMetricKey?: string;
    }
  ) => {
    const newMetrics = savedMetrics.map((metric) =>
      metric.mainMetricKey === mainMetricKey
        ? { mainMetricKey, ...val }
        : metric
    );
    query.save(newMetrics);
  };

  return {
    ...query,
    savedMetrics,
    showMetric: handleShow,
    hideMetric: handleHide,
    changeMetric: handleChange,
  };
};
