import React from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import { TypographyWithInfoIcon } from "@components";
import { toCurrency, toPercent } from "@helpers";
import { PlusGradient } from "@components/Plus/PlusGradient";
import { BlurredTypography } from "@components/BlurredTypography";
import { StyledCard } from "@features/dividendPlanner/components/StyledCard";
import { useDividendsContext } from "@features/dividendPlanner/DividendsProvider";

const Year = () => {
  return <span>({new Date().getFullYear()})</span>;
};

type Props = {
  i18key: string;
  value1?: number;
  value2?: number;
  isValue1Loading?: boolean;
  isValue2Loading?: boolean;
  displayGainLoss?: boolean;
  isForPlusOnly?: boolean;
  isPercentage?: boolean;
};

export const DividendCardContainer = ({
  i18key,
  value1,
  value2,
  displayGainLoss = false,
  isForPlusOnly,
  isValue1Loading,
  isValue2Loading,
  isPercentage,
}: Props) => {
  const { t } = useTranslation();
  const { isUserPro, isUserDataFetched } = useDividendsContext();

  const formatValue = (value?: number) => {
    return value ? (isPercentage ? toPercent(value) : toCurrency(value)) : "-";
  };

  const value1Tooltip = i18next.exists(
    `dividends.overviewPage.cards.${i18key}.value1Tooltip`
  )
    ? t(`dividends.overviewPage.cards.${i18key}.value1Tooltip`)
    : undefined;

  const value2Tooltip = i18next.exists(
    `dividends.overviewPage.cards.${i18key}.value2Tooltip`
  )
    ? t(`dividends.overviewPage.cards.${i18key}.value2Tooltip`)
    : undefined;

  return (
    <StyledCard>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        mb={3}
      >
        <Typography variant="h5" fontWeight={700} letterSpacing={-0.5}>
          {t(`dividends.overviewPage.cards.${i18key}.title`)}
        </Typography>
        {isForPlusOnly && !isUserPro && isUserDataFetched && <PlusGradient />}
      </Box>
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={6}>
          <TypographyWithInfoIcon
            variant="body1"
            fontWeight={400}
            color="textSecondary"
            hideIcon={!value1Tooltip}
            tooltipText={value1Tooltip}
            iconSize={16}
            iconProps={{
              lineHeight: "18px",
            }}
          >
            {t(`dividends.overviewPage.cards.${i18key}.value1`)}
          </TypographyWithInfoIcon>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end">
            {isValue1Loading ? (
              <Skeleton width={100} />
            ) : (
              <BlurredTypography
                value={value1}
                blur={6}
                showBlackOnPositive={!displayGainLoss}
                shouldBlur={isForPlusOnly && !isUserPro}
              >
                {formatValue(value1)}
              </BlurredTypography>
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TypographyWithInfoIcon
            variant="body1"
            fontWeight={400}
            color="textSecondary"
            hideIcon={!value2Tooltip}
            tooltipText={value2Tooltip}
            iconSize={16}
            iconProps={{
              lineHeight: "18px",
            }}
            tooltipProps={{
              tooltipWidth: i18key === "portfolio" ? "330px" : "300px",
            }}
          >
            <Trans
              i18nKey={`dividends.overviewPage.cards.${i18key}.value2`}
              components={{
                yearText: <Year />,
              }}
            />
          </TypographyWithInfoIcon>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end">
            {isValue2Loading ? (
              <Skeleton width={100} />
            ) : (
              <BlurredTypography
                value={value2}
                blur={6}
                showBlackOnPositive={!displayGainLoss}
                shouldBlur={isForPlusOnly && !isUserPro}
              >
                {formatValue(value2)}
              </BlurredTypography>
            )}
          </Box>
        </Grid>
      </Grid>
    </StyledCard>
  );
};
