/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateOrUpdateInvestmentParamsList } from '../model';
// @ts-ignore
import { Symbol } from '../model';
// @ts-ignore
import { SymbolList } from '../model';
// @ts-ignore
import { SymbolListV2 } from '../model';
/**
 * SymbolsApi - axios parameter creator
 * @export
 */
export const SymbolsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assign a preferred quote to the given create or update investment params. Returns the input data                      structure, but with the attributes tickerSymbol, quoteProvider and quoteCurrency assigned. Quotes                      are looked up using the given ISIN or WKN. If you are unsure if handling an ISIN or WKN (e.g. because                      a user imported data with an unclear mapping) then assign the field as ISIN. This operation will                      perform a syntax check and thereby separate ISINs from WKNs by format.
         * @param {CreateOrUpdateInvestmentParamsList} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignPreferredQuote: async (body: CreateOrUpdateInvestmentParamsList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('assignPreferredQuote', 'body', body)
            const localVarPath = `/v1/symbols`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search securities by ISIN or WKN
         * @param {string} isinOrWkn Search symbols by the given ISIN or WKN.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIsinOrWkn: async (isinOrWkn: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isinOrWkn' is not null or undefined
            assertParamExists('findByIsinOrWkn', 'isinOrWkn', isinOrWkn)
            const localVarPath = `/v1/symbols/{isinOrWkn}`
                .replace(`{${"isinOrWkn"}}`, encodeURIComponent(String(isinOrWkn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a symbol by quote provider and ticker
         * @param {GetByQuoteProviderAndTickerQuoteProviderEnum} quoteProvider The quote provider.
         * @param {string} id The ticker symbol.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByQuoteProviderAndTicker: async (quoteProvider: GetByQuoteProviderAndTickerQuoteProviderEnum, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quoteProvider' is not null or undefined
            assertParamExists('getByQuoteProviderAndTicker', 'quoteProvider', quoteProvider)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getByQuoteProviderAndTicker', 'id', id)
            const localVarPath = `/v1/symbols/{quoteProvider}/{id}`
                .replace(`{${"quoteProvider"}}`, encodeURIComponent(String(quoteProvider)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search securities by the given name (wildcard search)
         * @param {string} [search] Search symbols by the given Name (wildcard search).
         * @param {SearchSymbolTypeEnum} [type] Filter by the given investment type.
         * @param {boolean} [forceExactMatch] Force an exact match.
         * @param {number} [perAssetType] The maximum number of result hits per asset type; default is 255
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSymbol: async (search?: string, type?: SearchSymbolTypeEnum, forceExactMatch?: boolean, perAssetType?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/symbols`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (forceExactMatch !== undefined) {
                localVarQueryParameter['forceExactMatch'] = forceExactMatch;
            }

            if (perAssetType !== undefined) {
                localVarQueryParameter['perAssetType'] = perAssetType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search securities by the given name (wildcard search)
         * @param {string} [search] Search symbols by the given Name (wildcard search). Use only one of the following                              parameters: search, isinOrWkn
         * @param {string} [isinOrWkn] Search symbols by the given ISIN or WKN. Use only one of the following parameters:                              search, isinOrWkn
         * @param {Array<SearchSymbolV2TypeEnum>} [type] Filter by the given investment type.
         * @param {number} [perAssetType] The maximum number of result hits per asset type; default is 25
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSymbolV2: async (search?: string, isinOrWkn?: string, type?: Array<SearchSymbolV2TypeEnum>, perAssetType?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/symbols`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (isinOrWkn !== undefined) {
                localVarQueryParameter['isinOrWkn'] = isinOrWkn;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (perAssetType !== undefined) {
                localVarQueryParameter['perAssetType'] = perAssetType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SymbolsApi - functional programming interface
 * @export
 */
export const SymbolsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SymbolsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Assign a preferred quote to the given create or update investment params. Returns the input data                      structure, but with the attributes tickerSymbol, quoteProvider and quoteCurrency assigned. Quotes                      are looked up using the given ISIN or WKN. If you are unsure if handling an ISIN or WKN (e.g. because                      a user imported data with an unclear mapping) then assign the field as ISIN. This operation will                      perform a syntax check and thereby separate ISINs from WKNs by format.
         * @param {CreateOrUpdateInvestmentParamsList} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignPreferredQuote(body: CreateOrUpdateInvestmentParamsList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrUpdateInvestmentParamsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignPreferredQuote(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SymbolsApi.assignPreferredQuote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Search securities by ISIN or WKN
         * @param {string} isinOrWkn Search symbols by the given ISIN or WKN.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByIsinOrWkn(isinOrWkn: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SymbolList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByIsinOrWkn(isinOrWkn, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SymbolsApi.findByIsinOrWkn']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a symbol by quote provider and ticker
         * @param {GetByQuoteProviderAndTickerQuoteProviderEnum} quoteProvider The quote provider.
         * @param {string} id The ticker symbol.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByQuoteProviderAndTicker(quoteProvider: GetByQuoteProviderAndTickerQuoteProviderEnum, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Symbol>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByQuoteProviderAndTicker(quoteProvider, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SymbolsApi.getByQuoteProviderAndTicker']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Search securities by the given name (wildcard search)
         * @param {string} [search] Search symbols by the given Name (wildcard search).
         * @param {SearchSymbolTypeEnum} [type] Filter by the given investment type.
         * @param {boolean} [forceExactMatch] Force an exact match.
         * @param {number} [perAssetType] The maximum number of result hits per asset type; default is 255
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchSymbol(search?: string, type?: SearchSymbolTypeEnum, forceExactMatch?: boolean, perAssetType?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SymbolList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchSymbol(search, type, forceExactMatch, perAssetType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SymbolsApi.searchSymbol']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Search securities by the given name (wildcard search)
         * @param {string} [search] Search symbols by the given Name (wildcard search). Use only one of the following                              parameters: search, isinOrWkn
         * @param {string} [isinOrWkn] Search symbols by the given ISIN or WKN. Use only one of the following parameters:                              search, isinOrWkn
         * @param {Array<SearchSymbolV2TypeEnum>} [type] Filter by the given investment type.
         * @param {number} [perAssetType] The maximum number of result hits per asset type; default is 25
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchSymbolV2(search?: string, isinOrWkn?: string, type?: Array<SearchSymbolV2TypeEnum>, perAssetType?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SymbolListV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchSymbolV2(search, isinOrWkn, type, perAssetType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SymbolsApi.searchSymbolV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SymbolsApi - factory interface
 * @export
 */
export const SymbolsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SymbolsApiFp(configuration)
    return {
        /**
         * 
         * @summary Assign a preferred quote to the given create or update investment params. Returns the input data                      structure, but with the attributes tickerSymbol, quoteProvider and quoteCurrency assigned. Quotes                      are looked up using the given ISIN or WKN. If you are unsure if handling an ISIN or WKN (e.g. because                      a user imported data with an unclear mapping) then assign the field as ISIN. This operation will                      perform a syntax check and thereby separate ISINs from WKNs by format.
         * @param {SymbolsApiAssignPreferredQuoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignPreferredQuote(requestParameters: SymbolsApiAssignPreferredQuoteRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateOrUpdateInvestmentParamsList> {
            return localVarFp.assignPreferredQuote(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search securities by ISIN or WKN
         * @param {SymbolsApiFindByIsinOrWknRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIsinOrWkn(requestParameters: SymbolsApiFindByIsinOrWknRequest, options?: RawAxiosRequestConfig): AxiosPromise<SymbolList> {
            return localVarFp.findByIsinOrWkn(requestParameters.isinOrWkn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a symbol by quote provider and ticker
         * @param {SymbolsApiGetByQuoteProviderAndTickerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByQuoteProviderAndTicker(requestParameters: SymbolsApiGetByQuoteProviderAndTickerRequest, options?: RawAxiosRequestConfig): AxiosPromise<Symbol> {
            return localVarFp.getByQuoteProviderAndTicker(requestParameters.quoteProvider, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search securities by the given name (wildcard search)
         * @param {SymbolsApiSearchSymbolRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSymbol(requestParameters: SymbolsApiSearchSymbolRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<SymbolList> {
            return localVarFp.searchSymbol(requestParameters.search, requestParameters.type, requestParameters.forceExactMatch, requestParameters.perAssetType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search securities by the given name (wildcard search)
         * @param {SymbolsApiSearchSymbolV2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSymbolV2(requestParameters: SymbolsApiSearchSymbolV2Request = {}, options?: RawAxiosRequestConfig): AxiosPromise<SymbolListV2> {
            return localVarFp.searchSymbolV2(requestParameters.search, requestParameters.isinOrWkn, requestParameters.type, requestParameters.perAssetType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for assignPreferredQuote operation in SymbolsApi.
 * @export
 * @interface SymbolsApiAssignPreferredQuoteRequest
 */
export interface SymbolsApiAssignPreferredQuoteRequest {
    /**
     * The create or update parameters
     * @type {CreateOrUpdateInvestmentParamsList}
     * @memberof SymbolsApiAssignPreferredQuote
     */
    readonly body: CreateOrUpdateInvestmentParamsList
}

/**
 * Request parameters for findByIsinOrWkn operation in SymbolsApi.
 * @export
 * @interface SymbolsApiFindByIsinOrWknRequest
 */
export interface SymbolsApiFindByIsinOrWknRequest {
    /**
     * Search symbols by the given ISIN or WKN.
     * @type {string}
     * @memberof SymbolsApiFindByIsinOrWkn
     */
    readonly isinOrWkn: string
}

/**
 * Request parameters for getByQuoteProviderAndTicker operation in SymbolsApi.
 * @export
 * @interface SymbolsApiGetByQuoteProviderAndTickerRequest
 */
export interface SymbolsApiGetByQuoteProviderAndTickerRequest {
    /**
     * The quote provider.
     * @type {'tte' | 'ing' | 'none' | 'quandl' | 'rentablo' | 'crypto'}
     * @memberof SymbolsApiGetByQuoteProviderAndTicker
     */
    readonly quoteProvider: GetByQuoteProviderAndTickerQuoteProviderEnum

    /**
     * The ticker symbol.
     * @type {string}
     * @memberof SymbolsApiGetByQuoteProviderAndTicker
     */
    readonly id: string
}

/**
 * Request parameters for searchSymbol operation in SymbolsApi.
 * @export
 * @interface SymbolsApiSearchSymbolRequest
 */
export interface SymbolsApiSearchSymbolRequest {
    /**
     * Search symbols by the given Name (wildcard search).
     * @type {string}
     * @memberof SymbolsApiSearchSymbol
     */
    readonly search?: string

    /**
     * Filter by the given investment type.
     * @type {'11_stock' | '21_fund' | '31_bond' | '41_cash' | '51_certos' | '61_pmetals' | '71_massets' | '81_fcurr' | '22_etf' | '91_managed'}
     * @memberof SymbolsApiSearchSymbol
     */
    readonly type?: SearchSymbolTypeEnum

    /**
     * Force an exact match.
     * @type {boolean}
     * @memberof SymbolsApiSearchSymbol
     */
    readonly forceExactMatch?: boolean

    /**
     * The maximum number of result hits per asset type; default is 255
     * @type {number}
     * @memberof SymbolsApiSearchSymbol
     */
    readonly perAssetType?: number
}

/**
 * Request parameters for searchSymbolV2 operation in SymbolsApi.
 * @export
 * @interface SymbolsApiSearchSymbolV2Request
 */
export interface SymbolsApiSearchSymbolV2Request {
    /**
     * Search symbols by the given Name (wildcard search). Use only one of the following                              parameters: search, isinOrWkn
     * @type {string}
     * @memberof SymbolsApiSearchSymbolV2
     */
    readonly search?: string

    /**
     * Search symbols by the given ISIN or WKN. Use only one of the following parameters:                              search, isinOrWkn
     * @type {string}
     * @memberof SymbolsApiSearchSymbolV2
     */
    readonly isinOrWkn?: string

    /**
     * Filter by the given investment type.
     * @type {Array<'11_stock' | '21_fund' | '31_bond' | '41_cash' | '51_certos' | '61_pmetals' | '71_massets' | '81_fcurr' | '22_etf' | '91_managed'>}
     * @memberof SymbolsApiSearchSymbolV2
     */
    readonly type?: Array<SearchSymbolV2TypeEnum>

    /**
     * The maximum number of result hits per asset type; default is 25
     * @type {number}
     * @memberof SymbolsApiSearchSymbolV2
     */
    readonly perAssetType?: number
}

/**
 * SymbolsApi - object-oriented interface
 * @export
 * @class SymbolsApi
 * @extends {BaseAPI}
 */
export class SymbolsApi extends BaseAPI {
    /**
     * 
     * @summary Assign a preferred quote to the given create or update investment params. Returns the input data                      structure, but with the attributes tickerSymbol, quoteProvider and quoteCurrency assigned. Quotes                      are looked up using the given ISIN or WKN. If you are unsure if handling an ISIN or WKN (e.g. because                      a user imported data with an unclear mapping) then assign the field as ISIN. This operation will                      perform a syntax check and thereby separate ISINs from WKNs by format.
     * @param {SymbolsApiAssignPreferredQuoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SymbolsApi
     */
    public assignPreferredQuote(requestParameters: SymbolsApiAssignPreferredQuoteRequest, options?: RawAxiosRequestConfig) {
        return SymbolsApiFp(this.configuration).assignPreferredQuote(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search securities by ISIN or WKN
     * @param {SymbolsApiFindByIsinOrWknRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SymbolsApi
     */
    public findByIsinOrWkn(requestParameters: SymbolsApiFindByIsinOrWknRequest, options?: RawAxiosRequestConfig) {
        return SymbolsApiFp(this.configuration).findByIsinOrWkn(requestParameters.isinOrWkn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a symbol by quote provider and ticker
     * @param {SymbolsApiGetByQuoteProviderAndTickerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SymbolsApi
     */
    public getByQuoteProviderAndTicker(requestParameters: SymbolsApiGetByQuoteProviderAndTickerRequest, options?: RawAxiosRequestConfig) {
        return SymbolsApiFp(this.configuration).getByQuoteProviderAndTicker(requestParameters.quoteProvider, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search securities by the given name (wildcard search)
     * @param {SymbolsApiSearchSymbolRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SymbolsApi
     */
    public searchSymbol(requestParameters: SymbolsApiSearchSymbolRequest = {}, options?: RawAxiosRequestConfig) {
        return SymbolsApiFp(this.configuration).searchSymbol(requestParameters.search, requestParameters.type, requestParameters.forceExactMatch, requestParameters.perAssetType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search securities by the given name (wildcard search)
     * @param {SymbolsApiSearchSymbolV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SymbolsApi
     */
    public searchSymbolV2(requestParameters: SymbolsApiSearchSymbolV2Request = {}, options?: RawAxiosRequestConfig) {
        return SymbolsApiFp(this.configuration).searchSymbolV2(requestParameters.search, requestParameters.isinOrWkn, requestParameters.type, requestParameters.perAssetType, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetByQuoteProviderAndTickerQuoteProviderEnum = {
    TTE: 'tte',
    ING: 'ing',
    NONE: 'none',
    QUANDL: 'quandl',
    RENTABLO: 'rentablo',
    CRYPTO: 'crypto'
} as const;
export type GetByQuoteProviderAndTickerQuoteProviderEnum = typeof GetByQuoteProviderAndTickerQuoteProviderEnum[keyof typeof GetByQuoteProviderAndTickerQuoteProviderEnum];
/**
 * @export
 */
export const SearchSymbolTypeEnum = {
    _11_STOCK: '11_stock',
    _21_FUND: '21_fund',
    _31_BOND: '31_bond',
    _41_CASH: '41_cash',
    _51_CERTOS: '51_certos',
    _61_PMETALS: '61_pmetals',
    _71_MASSETS: '71_massets',
    _81_FCURR: '81_fcurr',
    _22_ETF: '22_etf',
    _91_MANAGED: '91_managed'
} as const;
export type SearchSymbolTypeEnum = typeof SearchSymbolTypeEnum[keyof typeof SearchSymbolTypeEnum];
/**
 * @export
 */
export const SearchSymbolV2TypeEnum = {
    _11_STOCK: '11_stock',
    _21_FUND: '21_fund',
    _31_BOND: '31_bond',
    _41_CASH: '41_cash',
    _51_CERTOS: '51_certos',
    _61_PMETALS: '61_pmetals',
    _71_MASSETS: '71_massets',
    _81_FCURR: '81_fcurr',
    _22_ETF: '22_etf',
    _91_MANAGED: '91_managed'
} as const;
export type SearchSymbolV2TypeEnum = typeof SearchSymbolV2TypeEnum[keyof typeof SearchSymbolV2TypeEnum];
