import React, { useEffect } from "react";
import { useMachine } from "@xstate/react";
import { Box, Divider, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { editTransactionWizardSM } from "@features/transactionWizard/stateMachine/editTransactionWizardSM";
import { useStateHelpers } from "@features/transactionWizard/hooks/useStateHelpers";
import { BookingValues } from "@features/transactionWizard/stateMachine/types/bookingValues";
import { SomethingWrong } from "@features/transactionWizard/steps/SomethingWrong";
import { useInvestmentChartData } from "@hooks";
import { PriceAndDateStep } from "@features/transactionWizard/steps/PriceAndDateStep";
import { RangeSelector } from "@components/common/ranges/RangeSelector";
import { SingleAccountChart } from "@components/Chart/SingleAccountChart";
import { NoDataStub } from "@components/Chart/NoDataStub";
import { intervalToTimeRange } from "@helpers";
import { LoadingPlaceHolder, Modal } from "@components";
import { GET_REALIZED_GAINS, TRANSACTIONS } from "@api/cacheKeys";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  bookingId: number;
  investmentId?: number;
  tickerSymbol?: string;
  invalidateBookings?: boolean;
};

export const EditBookingModal = ({
  isOpen,
  onClose,
  bookingId,
  investmentId,
  tickerSymbol,
  invalidateBookings,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [currentState, sendEvent] = useMachine(editTransactionWizardSM, {
    context: {
      skipSummaryStep: true,
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const { isLoadingStateForModals } = useStateHelpers(currentState);

  const [selectedRange, setSelectedRange] =
    React.useState<IntervalType>("oneYear");
  const [range, setRange] = React.useState<RangeType>(
    intervalToTimeRange("oneYear")
  );

  const { data: chartData = [], isLoading } = useInvestmentChartData({
    investmentId,
    currentInterval: selectedRange,
    startDate: range.startDate,
    endDate: range.endDate,
    tickerSymbol,
  });

  const onCustomRangeSelect = (from: Date, to: Date) => {
    setSelectedRange("custom");
    setRange({ startDate: from, endDate: to });
  };

  useEffect(() => {
    if (selectedRange === "custom") return;
    setRange(intervalToTimeRange(selectedRange));
  }, [selectedRange]);

  const hasChartData = Boolean(chartData.length);

  useEffect(() => {
    if (currentState.matches("persisted")) {
      onClose();
      enqueueSnackbar(
        `${t("transactionWizard.persistedStep.title")}: ${
          currentState.context.investmentValues.name
        }`,
        { autoHideDuration: 3000, variant: "success" }
      );
      queryClient.invalidateQueries({
        queryKey: [TRANSACTIONS],
      });

      if (invalidateBookings) {
        queryClient.invalidateQueries({
          queryKey: [CACHE_KEYS.BOOKINGS_LIST_AND_SEARCH_ALL_BOOKINGS],
        });
        queryClient.invalidateQueries({
          queryKey: [GET_REALIZED_GAINS],
        });
      }
    }
  }, [
    currentState,
    onClose,
    enqueueSnackbar,
    t,
    queryClient,
    invalidateBookings,
  ]);

  const isSaving =
    currentState.matches("persist") || currentState.matches("validateBooking");

  return (
    <Modal
      disablePortal={false}
      isOpen={isOpen}
      onClose={onClose}
      title={t("singleAccount.editBooking")}
    >
      <Box>
        {isLoadingStateForModals() && <LoadingPlaceHolder />}

        {currentState.matches("idle") &&
          sendEvent({ type: "INIT_FROM_BOOKING", id: bookingId }) && (
            <LoadingPlaceHolder />
          )}

        {(currentState.matches("priceAndDate") ||
          isSaving ||
          currentState.matches("persisted")) && (
          <PriceAndDateStep
            handleNext={(bookingValues: BookingValues) =>
              sendEvent({
                type: "VALIDATE_BOOKING",
                bookingValues: bookingValues,
              })
            }
            isLoading={isSaving}
            bookingValues={currentState.context.bookingValues}
            bookingValuesToRestore={currentState.context.bookingValuesToRestore}
            investmentValues={currentState.context.investmentValues}
            validationError={currentState.context.validationError}
            chart={
              <>
                <Divider
                  sx={{
                    margin: theme.spacing(8, 0),
                    borderColor: theme.palette.border.secondary,
                  }}
                />
                <Box mb={5} display="flex" justifyContent="end" width="100%">
                  <RangeSelector
                    range={selectedRange === "custom" ? range : undefined}
                    intervalType={selectedRange}
                    onSelect={setSelectedRange}
                    onCustomSelect={onCustomRangeSelect}
                    width="100%"
                  />
                </Box>

                {hasChartData ? (
                  <SingleAccountChart
                    chartData={chartData}
                    isLoading={isLoading}
                    intervalType={selectedRange}
                  />
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                  >
                    <NoDataStub />
                  </Box>
                )}
              </>
            }
            isEditTransaction
            disableOpenPicker
            datePickerDesktopVariant
          />
        )}

        {currentState.matches("somethingWrong") && (
          <SomethingWrong message={currentState.context.error?.message} />
        )}
      </Box>
    </Modal>
  );
};
